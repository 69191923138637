<template>
  <div :id="id">
    <div class="row turn-manager-row">
      <div class="col-md-12">
        <loading :active="loading" color="#F37E00" :is-full-page="false" />
        <div class="card">
          <div class="body pl-2 pr-2">
            <div class="row small-actions">
              <div class="col-sm-6 small-action" style="z-index: 20">
                <action-circle-button
                  v-if="canTakeBreak && !userHasPendingBreak"
                  id="btnBreak"
                  :disabled="
                    userHasPendingBreakToStart ||
                    (activeBreak && activeBreak.hasAuthorizationPending)
                  "
                  icon="fal fa-pause"
                  size="small"
                  :title="$t('takeABreak')"
                  @on-click="onTakeABreak()"
                />

                <action-circle-button
                  v-if="
                    isTrackTransfer &&
                    isSpecialField1Enabled &&
                    canTrackTransfers
                  "
                  id="btnTransferTracking"
                  class="ml-2"
                  icon="fal fa-file"
                  size="small"
                  :title="$t('transTracking')"
                  @on-click="onTransferTracking()"
                />

                <action-circle-button
                  v-if="canTakeBreak && userHasPendingBreak"
                  id="btnBreakNotification"
                  icon="fal fa-walking"
                  outline="red"
                  size="small"
                  @on-click="
                    showBreakNotificationModal = !showBreakNotificationModal
                  "
                />

                <action-circle-button
                  v-if="!canTakeTurn"
                  class="ml-2"
                  icon="fal fa-chart-pie"
                  size="small"
                  :title="$t('statistics')"
                  @on-click="showHideMetricsModal()"
                />
              </div>
              <div class="col-sm-6 small-action text-right" style="z-index: 20">
                <action-circle-button
                  v-if="canCallToMSTeams"
                  id="btnCallMSTeams"
                  class="ml-2"
                  icon="fad fa-user-friends"
                  outline="purple"
                  size="small"
                  :title="$t('callWithMSTeams')"
                  @on-click="callWithMSTeams()"
                />
                <action-circle-button
                  v-if="canRequestDocuments"
                  id="btnRequestDocuments"
                  class="ml-2"
                  :disabled="requestDocumentsDisabled"
                  icon="fal fa-cloud-upload"
                  outline="red"
                  size="small"
                  :title="$t('requestDocuments')"
                  @on-click="onRequestDocuments()"
                />

                <action-circle-button
                  v-if="canShowDocuments"
                  id="btnShowDocuments"
                  class="ml-2"
                  icon="fas fa-folder-download"
                  outline="blue"
                  size="small"
                  :title="$t('showDocuments')"
                  @on-click="showDocumentsModal = true"
                />

                <action-circle-button
                  v-if="canRecall"
                  id="btnReCall"
                  class="ml-2"
                  icon="fas fa-microphone"
                  size="small"
                  :title="$t('reCall')"
                  :disabled="isRecallDisabled"
                  @on-click="onRecall()"
                />
                <action-circle-button
                  v-if="canInstantCall"
                  id="btnInstantCall"
                  class="ml-2"
                  icon="fas fa-volume"
                  size="small"
                  :title="$t('instantCall')"
                  @on-click="onInstantCall()"
                />
                <action-circle-button
                  v-if="canEditTurn"
                  id="btnEdit"
                  class="ml-2"
                  icon="fas fa-pencil"
                  size="small"
                  :title="$t('editTurnInfo')"
                  @on-click="showEditTurnModal = true"
                />
                <action-circle-button
                  v-if="isVideoCall"
                  id="btnVideoCall"
                  class="ml-2"
                  icon="far fa-video"
                  size="small"
                  :title="$t('videoCall')"
                  @on-click="onVideoCall()"
                />
                <action-circle-button
                  v-if="isVideoCall"
                  id="btnResentVideoCall"
                  class="ml-2"
                  icon="far fa-share"
                  size="small"
                  :title="$t('resendVideoCall')"
                  @on-click="onResendVideoCall()"
                />
              </div>
            </div>
            <div class="row title mt-3">
              <div v-if="!activeTurn" class="col-sm-12 mb-3">
                <h4>{{ $t('rowCall') }}</h4>
              </div>
              <div v-else id="active-turn-header" class="col-sm-12 mb-3">
                <h1 id="hTurnNumber">
                  {{ activeTurn.serviceQueueStartingLetter || ''
                  }}{{ activeTurn.turnNumber }}
                </h1>
                <h2 id="hClientName">
                  {{ activeTurn.clientName }}
                </h2>
                <base-input-display
                  v-if="activeTurn.attenderByPhone && activeTurn.clientCel"
                  id="hClientCel"
                  v-model="activeTurn.clientCel"
                  class="red"
                  mask-type="Phone"
                />
              </div>
            </div>
            <div
              v-if="activeTurn"
              id="active-turn-header-metadata"
              class="row mx-1"
            >
              <div
                v-if="activeTurn.clientEmail"
                class="col-sm-12 queue-called mb-2"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ $t('email') }}: {{ activeTurn.clientEmail }}
                  </span>
                </div>
              </div>
              <div
                class="col-sm-12 queue-called"
                :class="{
                  'col-md-6': configuration.specialFields.SpecialField1Enabled,
                }"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    Fila Llamada: {{ activeTurn.serviceQueueDescription }}
                  </span>
                </div>
              </div>
              <div
                v-if="
                  configuration.specialFields.specialField1Enabled &&
                  configuration.specialFields.specialField1ShowMT
                "
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ configuration.specialFields.specialField1Name }}:
                    {{ activeTurn.specialField1 || '-' }}
                  </span>
                </div>
              </div>
              <div
                v-if="
                  configuration.specialFields.specialField2Enabled &&
                  configuration.specialFields.specialField2ShowMT
                "
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ configuration.specialFields.specialField2Name }}:
                    {{ activeTurn.specialField2 || '-' }}
                  </span>
                </div>
              </div>

              <div
                v-if="
                  configuration.specialFields.specialField3Enabled &&
                  configuration.specialFields.specialField3ShowMT
                "
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ configuration.specialFields.specialField3Name }}:
                    {{ activeTurn.miscDataDescription || '-' }}
                  </span>
                </div>
              </div>

              <div
                v-if="
                  configuration.specialFields.specialField4Enabled &&
                  configuration.specialFields.specialField4ShowMT
                "
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ configuration.specialFields.specialField4Name }}:
                    {{ activeTurn.specialField4 || '-' }}
                  </span>
                </div>
              </div>
              <div
                v-if="
                  configuration.specialFields.specialField5Enabled &&
                  configuration.specialFields.specialField5ShowMT
                "
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ configuration.specialFields.specialField5Name }}:
                    {{ activeTurn.specialField5 || '-' }}
                  </span>
                </div>
              </div>

              <div
                v-if="
                  configuration.specialFields.specialField6Enabled &&
                  configuration.specialFields.specialField6ShowMT
                "
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ configuration.specialFields.specialField6Name }}:
                    {{ activeTurn.specialField6 || '-' }}
                  </span>
                </div>
              </div>

              <div
                v-if="
                  configuration.specialFields.specialField7Enabled &&
                  configuration.specialFields.specialField7ShowMT
                "
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span v-if="activeTurn.specialField7" class="m-0">
                    {{ configuration.specialFields.specialField7Name }}:
                    {{ activeTurn.specialField7 | date }}
                  </span>
                  <span v-else class="m-0">
                    {{ configuration.specialFields.specialField7Name }}:
                    {{ '-' }}
                  </span>
                </div>
              </div>
              <div
                v-if="activeTurn.languageToAttendCode"
                class="col-sm-12 col-md-6 special-field"
              >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ $t('language') }}:
                    {{ $t(activeTurn.languageToAttendCode) }}
                  </span>
                </div>
              </div>
              <div
                  v-if="activeTurn.appointmentCoordinationDate"
                  class="col-sm-12 col-md-6 special-field"
                >
                <div class="special-field-container">
                  <span class="m-0">
                    {{ $t('coordinatedDate') }}:
                    {{ activeTurn.appointmentCoordinationDate | date }}
                  </span>
                </div>
              </div>
            </div>
            <validation-observer
              ref="turn-manager-form-ref"
              class="col-sm-12 p-0 m-0"
              novalidate
            >
              <div class="row mt-2">
                <div class="col-md-6 col-sm-12 pr-2">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      :clearable="false"
                      :disabled="
                        serviceQueueSelectionFixed ||
                        handlerServiceQueues.length == 1 ||
                        stationFixedServiceQueue ||
                        isWaitingForPassive
                      "
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :label="$t('advanceRow')"
                      :options="handlerServiceQueues"
                      :return-object="true"
                      :value="selectedTurnManagerHandlerServiceQueue"
                      @input="onTurnCallServiceQueueChanged($event)"
                    />
                  </validation-provider>
                </div>
                <div v-if="stations.length" class="col-md-6 col-sm-12 pl-2">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      :clearable="false"
                      :disabled="
                        stationSelectionFixed ||
                        stations.length == 1 ||
                        isWaitingForPassive
                      "
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :label="$t('advanceStation')"
                      :options="stations"
                      :return-object="true"
                      :value="selectedTurnManagerStation"
                      @input="onTurnCallStationChanged($event)"
                    />
                  </validation-provider>
                </div>
              </div>
              <div v-if="canFinalize" class="row">
                <div
                  v-if="showServices && configuration.hasConfiguredServices"
                  class="col-sm-12"
                >
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      :allow-select-all="false"
                      :clearable="false"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :group-when-all-selected="false"
                      :is-multiple="canSelectMultipleServiceTypes"
                      :label="$t('service')"
                      :options="availableServiceTypes"
                      :return-object="true"
                      :value="selectedServiceTypes"
                      @input="onServiceTypeChanged($event)"
                    />
                  </validation-provider>
                </div>
                <div v-if="showStampService" class="col-sm-12 d-flex">
                  <div class="mr-2">
                    <action-circle-button
                      id="btnShowFinishServiceTypeModal"
                      icon="fas fa-stamp"
                      size="small"
                      @on-click="showFinishServiceTypeModal = true"
                    />
                  </div>
                  <div class="special-field-container font-weight-bold">
                    <span>
                      {{ this.selectedServiceTypeName || '-' }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="showComment" class="row">
                <div class="col-sm-12 ml-0 mr-0 mt-2">
                  <validation-provider
                    ref="comment-val-prov-ref"
                    v-slot="{ errors }"
                    :rules="commentRules"
                  >
                    <base-input
                      v-model="comment"
                      :error="errors[0]"
                      :error-msg="commentErrorMessage"
                      :label="$t('comment')"
                      :max-length="commentRules.max"
                      :textarea="true"
                    />
                  </validation-provider>
                </div>
              </div>
            </validation-observer>
            <div class="row information" />
          </div>
          <hr />
          <div class="footer pl-4 pr-4" :class="{ active: !canTakeTurn }">
            <div class="row actions align-items-center">
              <div
                v-if="showPaidStamps"
                class="col-3 col-sm-3 col-md-3 action-button"
              >
                <img
                  class="cursor-pointer img-fluid"
                  :src="require('@/assets/colecturia-logo.png')"
                  @click="openAppointmentStampsModal"
                />
              </div>
              <div v-if="canTakeTurn" class="col-sm-12 action-button">
                <action-circle-button
                  id="btnTakeTurn"
                  icon="fal fa-plus"
                  size="large"
                  :title="$t('takeTurn')"
                  @on-click="takeNextTurn"
                  :disabled="disableCommunicationTakeTurn"
                />
              </div>
              <div v-if="isWaitingForPassive" class="col-md-12 text-center">
                <button
                  class="btn btn-outline-primary"
                  @click="finalizeWaitingRoom()"
                >
                  {{ $t('endPassiveSearch') }}
                </button>
              </div>
              <div v-if="canFinalizeTurnSearch" class="col-md-12 text-center">
                <button
                  class="btn btn-outline-primary"
                  @click="finalizeWaitingRoom()"
                >
                  {{ $t('endTurnSearch') }}
                </button>
              </div>
              <div
                v-if="canSetNotPresent"
                class="col-3 col-sm-3 col-md-3 action-button"
              >
                <action-circle-button
                  id="btnNotPresent"
                  icon="fal fa-ban"
                  size="medium"
                  :title="$t('notPresent')"
                  :disabled="isNotPresentDisabled"
                  @on-click="onSetTurnNotPresent"
                />
              </div>
              <div
                v-if="canMoveToAnotherQueue"
                class="col-3 col-sm-3 col-md-3 action-button"
              >
                <action-circle-button
                  id="btnMoveToAnotherQueue"
                  icon="fas fa-arrows"
                  size="medium"
                  :title="$t('moveToAnotherRow')"
                  :disabled="isMoveToAnotherQueueDisabled"
                  @on-click="onMoveToAnotherQueue"
                />
              </div>
              <div
                v-if="canReassign"
                class="col-3 col-sm-3 col-md-3 action-button"
              >
                <action-circle-button
                  id="btnReassignTurn"
                  icon="fas fa-redo"
                  size="medium"
                  :title="$t('reassignToAnotherRow')"
                  @on-click="onReassignTurn()"
                />
              </div>
              <div v-if="canDrop" class="col-3 col-sm-3 col-md-3 action-button">
                <action-circle-button
                  id="btnDrop"
                  :icon="dropIcon"
                  size="medium"
                  :title="dropTitle"
                  @on-click="onDrop()"
                />
              </div>
              <div
                v-if="canFinalize && canFinalizeWaitingRoom"
                class="col-3 col-sm-3 col-md-3 action-button"
              >
                <action-circle-button
                  id="btnFinalize"
                  icon="fal fa-check"
                  size="medium"
                  :title="$t('finalizeTurn')"
                  :disabled="isFinalizeDisabled"
                  @on-click="onFinalizeTurn()"
                />
              </div>
            </div>
          </div>
          <div v-if="showCommentHistory" class="row">
            <div class="col-sm-12 mb-3 text-center">
              <hr />
              <h4>{{ $t('commentsHistory') }}</h4>
              <p v-if="!activeTurn.comments.length" class="pt-2">
                {{ activeTurn.comment }}
              </p>
              <div class="ml-2 mr-2">
                <comment-history
                  v-if="activeTurn.comments.length"
                  v-model="activeTurn.comments"
                ></comment-history>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canTakeTurn" class="row">
      <div class="col-md-12 mb-0 mt-3 metrics-container">
        <ManagerGauges ref="turn-metrics" />
      </div>
    </div>
    <custom-modal
      id="turn-transfer-modal"
      no-close-on-back-drop
      size="md"
      :value="showTurnTransferModal"
      @input="onTransferTurnModal"
    >
      <slot name="header">
        <header
          id="turn-transfer-modal___BV_modal_header_"
          class="modal-header"
        >
          <h5 id="turn-transfer-modal___BV_modal_title_" class="modal-title">
            {{ $t('transferTurn') }}
          </h5>
          <button
            aria-label="Close"
            class="close"
            type="button"
            @click="onTransferTurnModal(false)"
          >
            ×
          </button>
        </header>
      </slot>

      <TransferTurnForm
        :attenders="attenders"
        :item="transferTurnModel"
        :show-service-types="configuration.individualServiceCounterEnabled"
        @close="onTransferTurnModal(false)"
        @transfer-turn="onTransferTurn()"
      />
    </custom-modal>
    <custom-modal
      id="popupMetrics"
      size="lg"
      :title="$t('statistics')"
      :value="showMetricsModal"
      @input="showHideMetricsModal()"
    >
      <div class="row">
        <div class="col-sm-12 ml-2">
          <ManagerGauges ref="turn-metrics-popup" />
        </div>
      </div>
    </custom-modal>
    <custom-modal v-model="showEditTurnModal" :title="$t('editTurn')">
      <TurnForm
        v-if="activeTurn"
        :attending="true"
        :waiting-room-id="
          activeWaitingRoom ? activeWaitingRoom.waitingRoomId : null
        "
        :refresh-active-turn="true"
        :turn-id="activeTurn.turnId"
        @submitted="setActiveTurnNewInfo($event)"
      />
    </custom-modal>
    <custom-modal
      v-model="showReassignTurnModal"
      size="md"
      :title="$t('reassignToAnotherRow')"
    >
      <ReassignTurnForm
        v-if="activeTurn"
        :comment-rules="commentRules"
        :value="activeTurn"
        @close="onReassignTurnFormClose()"
      />
    </custom-modal>
    <custom-modal v-model="showDocumentsModal" size="md">
      <AppointmentDocuments
        v-if="showDocumentsModal && activeTurn"
        :appointment-id="activeTurn.clientAppointmentId"
        :no-action="activeTurn.finalized"
        :turno-id="activeTurn.turnId"
        @close="showDocumentsModal = false"
      />
    </custom-modal>
    <custom-modal
      id="stamp-service-modal"
      v-model="showFinishServiceTypeModal"
      size="md"
      :title="$t('selectService')"
    >
      <validation-observer
        ref="turn-manager-service-form-ref"
        class="col-sm-12 m-0 p-0"
        novalidate
      >
        <validation-provider v-slot="{ errors }" rules="required">
          <filter-select
            :clearable="false"
            :error="errors[0]"
            :error-msg="$t('mustSelectOne')"
            fieldtext="name"
            fieldvalue="id"
            :label="$t('service')"
            :options="availableServiceTypes"
            :value="selectedServiceTypeId"
            @input="selectedServiceTypeId = $event"
          />
        </validation-provider>
      </validation-observer>

      <div class="float-right">
        <button
          class="btn btn-primary mr-2"
          type="button"
          @click="showFinishServiceTypeModal = false"
        >
          <i class="fal fa-times-circle" /> {{ $t('cancel') }}
        </button>
        <button
          class="btn btn-primary"
          type="button"
          @click="onFinishServiceType"
        >
          <i class="fal fa-check" /> {{ $t('confirm') }}
        </button>
      </div>
    </custom-modal>
    <custom-modal
      id="popupBreakNotification"
      size="md"
      :title="$t('notification')"
      :value="showBreakNotificationModal"
      @input="showBreakNotificationModal = !showBreakNotificationModal"
    >
      <div class="row">
        <div class="col-sm-12 text-center">
          <label v-html="breakNotificationMessageHtml" />
        </div>
      </div>
    </custom-modal>
    <break-form ref="break-form" />
    <transfer-tracker
      v-if="activeTurn"
      ref="transfer-tracker"
      :extrafield="
        activeTurn && activeTurn.specialField1 ? activeTurn.specialField1 : ''
      "
    />

    <custom-modal
      id="appointment-stamps-modal"
      ref="appointment-stamps-ref"
      size="lg"
    >
      <appointment-stamps
        v-if="activeTurn"
        :appointment-id="activeTurn.clientAppointmentId"
        @close="$refs['appointment-stamps-ref'].hide()"
      />
    </custom-modal>

    <custom-modal
      id="appointment-stamps-finalize-modal"
      ref="appointment-stamps-finalize-ref"
      size="lg"
    >
      <appointment-stamps
        v-if="activeTurn"
        :appointment-id="activeTurn.clientAppointmentId"
        :stamps-info="appointmentStampsInfo"
        :show-action="true"
        :text-action="$t('endTurn')"
        @close="$refs['appointment-stamps-finalize-ref'].hide()"
        @on-action="finalizeFromStampsModal"
      />
    </custom-modal>

    <custom-modal
      id="appointment-stamps-askdocumentid-modal"
      ref="appointment-stamps-askdocumentid-ref"
      size="md"
    >
      <appointment-ask-for-document-id
        v-if="activeTurn"
        :stamps-validation-info="appointmentStampsValidationInfo"
        @close="$refs['appointment-stamps-askdocumentid-ref'].hide()"
        @on-action="finalizeTurn"
      />
    </custom-modal>
  </div>
</template>

<script>
import { modulesName } from '@/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import IdentityPermission from '@/constants/IdentityPermission';

import ActionCircleButton from '@/components/ActionCircleButton';
import ManagerGauges from './ManagerGauges';
import TransferTurnForm from '@/components/turns/TransferTurnForm.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import BaseInputDisplay from '@/components/BaseInputDisplay';
import BaseInput from '@/components/BaseInput.vue';
import TurnForm from './TurnForm';
import ReassignTurnForm from './ReassignTurnForm';
import BreakForm from './BreakForm';
import CommentHistory from './CommentHistory.vue';
import TransferTracker from '@/views/transferTracker/Search.vue';

import AudioCalling from '@/assets/sounds/TheCalling.mp3';
import WaitingRoomStatus from '@/constants/status/WaitingRoomStatus';
import { isRepresentativeTypeActive as _isRepresentativeTypeActive } from '@/services/UserService';
import {
  callNextTurn as _callNextTurn,
  recallTurn as _recallTurn,
  finalizeTurn as _finalizeTurn,
  finishServiceType as _finishServiceType,
  resendVideoCallLink as _resendVideoCallLink,
  instantCallTurn as _instantCallTurn,
  requestTurnDocuments as _requestTurnDocuments,
} from '@/services/TurnService';
import { getTypesConfiguredForServiceQueues as _getTypesConfiguredForServiceQueues } from '@/services/ServicesService';
import {
  createWaitingRoom as _createWaitingRoom,
  finalizeWaitingRoom as _finalizeWaitingRoom,
  updateWaitingRoomStatus as _updateWaitingRoomStatus,
  initCalls as _initCalls,
} from '@/services/WaitingRoomService';
import ParticipantType from '@/constants/ParticipantType';

import AppointmentDocuments from '@/components/AppointmentDocuments';
import {
  getOfficeParameters as _getOfficeParameters,
  getOfficeExtrafields as _getOfficeExtrafields,
} from '@/services/LocationService';
import SystemRoles from '@/constants/SystemRoles';
import AppointmentStamps from '@/views/appointments/components/AppointmentStamps.vue';
import AppointmentAskForDocumentId from '@/views/appointments/components/AppointmentAskForDocumentId.vue';
import { transferTurn as _transferTurn } from '@/services/EmployeeService';
import digitalCollectionMixin from '@/mixins/appointment/digitalCollectionMixin';

export default {
  name: 'TurnsManagerControl',
  components: {
    ActionCircleButton,
    ManagerGauges,
    CustomModal,
    TransferTurnForm,
    BaseInputDisplay,
    BaseInput,
    TurnForm,
    ReassignTurnForm,
    AppointmentDocuments,
    BreakForm,
    TransferTracker,
    CommentHistory,
    AppointmentStamps,
    AppointmentAskForDocumentId,
  },
  props: {
    attenders: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: () => 'turn-manager',
    },
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'currentActiveLocation',
      'currentActiveStation',
      'fullProfile',
      'profile',
    ]),
    ...mapGetters(modulesName.languageModuleName, ['getLanguage']),
    ...mapGetters(modulesName.turnManagerModuleName, [
      'userHasPendingBreak',
      'userHasPendingBreakToStart',
    ]),
    ...mapState(modulesName.userModuleName, ['activeBreakAndTurn']),
    ...mapState(modulesName.turnManagerModuleName, [
      'activeTurn',
      'activeBreak',
      'activeWaitingRoom',
      'configuration',
      'stations',
      'handlerServiceQueues',
      'defaultServiceQueue',
      'defaultStation',
      'serviceQueueSelectionFixed',
      'stationSelectionFixed',
      'metrics',
      'selectedTurnManagerHandlerServiceQueue',
      'selectedTurnManagerStation',
      'breakScheduled',
      'canUploadDocuments',
      'enableLanguageBehavior',
    ]),
    showCommentHistory() {
      if (
        this.activeWaitingRoom &&
        this.activeWaitingRoom.waitingRoomStatus <
          WaitingRoomStatus.SessionInProgress
      )
        return false;

      if (!this.canTakeTurn && this.activeTurn && this.activeTurn.comment)
        return true;

      return false;
    },
    canTrackTransfers() {
      return (
        this.fullProfile.roleId === SystemRoles.client ||
        this.fullProfile.roleId === SystemRoles.clientlist
      );
    },
    canTakeBreak() {
      return (
        this.configuration.breakModuleEnabled ||
        this.configuration.automaticPullEnabled
      );
    },
    canCallToMSTeams() {
      return (
        this.activeTurn != null &&
        !this.activeTurn.finalized &&
        this.activeTurn.hasMSTeams &&
        this.activeTurn.msTeamsCallURL
      );
    },
    canShowDocuments() {
      return (
        this.canUploadDocuments ||
        (this.activeTurn != null &&
          (this.activeTurn.hasDocuments || this.activeTurn.hasTurnDocuments))
      );
    },
    canRecall() {
      if (
        this.activeWaitingRoom != null &&
        this.activeWaitingRoom.waitingRoomStatus ==
          WaitingRoomStatus.SessionCompleted
      )
        return false;

      return this.isAttendingTurn && !this.activeTurn.instantRecordService;
    },
    canInstantCall() {
      return (
        (this.activeTurn == null || !this.isAttendingTurn) &&
        this.configuration.instantRecordLine
      );
    },
    canRequestDocuments() {
      return (
        this.canUploadDocuments &&
        this.activeTurn != null &&
        !this.activeTurn.finalized
      );
    },
    canEditTurn() {
      return (
        this.activeTurn != null &&
        this.configuration.editCurrentCustomerEnabled &&
        !this.activeTurn.finalized
      );
    },
    isVideoCall() {
      return (
        this.activeTurn != null &&
        this.activeTurn.isVideoCall &&
        this.isAttendingTurn
      );
    },
    canTakeTurn() {
      return (
        (this.activeTurn == null || !this.isAttendingTurn) &&
        this.noActiveWaitingRoom
      );
    },
    disableCommunicationTakeTurn() {
      if (
        this.waitingRoomEnabled &&
        !!this.configuration.representativeCompanionType
      )
        return !this.isRepresentativeTypeActive;
      return false;
    },
    canReassign() {
      return (
        this.activeTurn != null &&
        !this.activeTurn.finalized &&
        this.configuration.reassignTurnEnabled
      );
    },
    canSetNotPresent() {
      if (
        this.activeWaitingRoom != null &&
        this.activeWaitingRoom.waitingRoomStatus ==
          WaitingRoomStatus.SessionCompleted
      )
        return false;

      return (
        this.isAttendingTurn &&
        !this.activeTurn.instantRecordService &&
        this.activeTurn.attendingTime >=
          this.configuration.notPresentMinimumTime
      );
    },
    canMoveToAnotherQueue() {
      if (
        this.activeWaitingRoom != null &&
        this.activeWaitingRoom.waitingRoomStatus <
          WaitingRoomStatus.SessionCompleted
      )
        return false;

      return this.isAttendingTurn && !this.activeTurn.havePaidStamps;
    },
    canDrop() {
      if (this.activeWaitingRoom == null) return false;

      return (
        this.activeTurn != null &&
        !this.activeTurn.finalized &&
        this.activeWaitingRoom != null &&
        this.activeWaitingRoom.waitingRoomStatus ==
          WaitingRoomStatus.SessionInProgress
      );
    },
    canFinalizeWaitingRoom() {
      if (
        this.activeWaitingRoom != null &&
        this.activeWaitingRoom.waitingRoomStatus <
          WaitingRoomStatus.SessionCompleted
      )
        return false;
      else return true;
    },
    canFinalize() {
      return (
        this.activeTurn != null &&
        !this.activeTurn.finalized &&
        this.activeTurn.attendingTime >= this.configuration.timeFinishService
      );
    },
    showComment() {
      return (
        this.activeTurn != null &&
        !this.activeTurn.finalized &&
        (this.activeTurn.attendingTime >=
          this.configuration.timeFinishService ||
          (this.activeWaitingRoom != null &&
            this.activeWaitingRoom.waitingRoomStatus >=
              WaitingRoomStatus.WaitingCitizen))
      );
    },
    isCommentRequired() {
      const hasServiceTypeCommentRequired = this.selectedServiceTypesProxy.some(
        (s) => s.isCommentRequired,
      );

      return (
        this.activeTurn &&
        (this.activeTurn.serviceQueueCommentRequired ||
          hasServiceTypeCommentRequired)
      );
    },
    showTurnTransferModal() {
      return !!this.transferTurnModel;
    },
    mainActionButtonSize() {
      return this.activeTurn == null || this.activeTurn.finalized
        ? 'large'
        : 'medium';
    },
    commentRules() {
      return {
        min: 5,
        max: 1024,
        required: this.isCommentRequired,
      };
    },
    commentErrorMessage() {
      const rules = this.commentRules;
      const currentComment = this.comment || '';

      if (rules.required && !currentComment) {
        return this.$t('commentRequired');
      } else if (currentComment.length < rules.min) {
        return this.$t('commentMinLength').replace('{0}', rules.min);
      } else if (currentComment.length >= rules.max) {
        return this.$t('commentMaxLength').replace('{0}', rules.max);
      }

      return null;
    },
    canSelectMultipleServiceTypes() {
      return this.activeTurn != null && this.activeTurn.havePaidStamps
        ? false
        : true;
    },
    selectedServiceTypes: {
      get() {
        const isMultiple = this.canSelectMultipleServiceTypes;

        if (!isMultiple) {
          if (!this.selectedServiceTypesProxy.length) return null;

          return this.selectedServiceTypesProxy[0];
        }

        return this.selectedServiceTypesProxy;
      },
      set(val) {
        if (Array.isArray(val)) this.selectedServiceTypesProxy = val;
        else if (val != null) {
          this.selectedServiceTypesProxy = [val];
        } else {
          this.selectedServiceTypesProxy = [];
        }
      },
    },
    selectedServiceTypeName() {
      if (this.activeTurn && this.activeTurn.serviceTypes.length) {
        const singleService = this.activeTurn.serviceTypes.find(
          (s) => !s.isServiceFinished,
        );
        return singleService ? singleService.name : null;
      } else {
        return this.selectedServiceTypes.length
          ? this.selectedServiceTypes[0].name
          : null;
      }
    },
    isAttendingTurn() {
      return this.activeTurn != null && !this.activeTurn.finalized;
    },
    breakNotificationMessage() {
      if (this.activeBreak)
        return this.$t('breakNotification').replace(
          '{0}',
          this.activeBreak.breakDescription,
        );

      return '';
    },
    breakNotificationMessageHtml() {
      if (this.activeBreak)
        return this.$t('breakNotificationHtml').replace(
          '{0}',
          this.activeBreak.breakDescription,
        );

      return '';
    },
    showStampService() {
      return (
        !this.showServices &&
        this.configuration.individualServiceCounterEnabled &&
        !!this.selectedServiceTypeName
      );
    },
    showServices() {
      const hasServices =
        this.activeTurn != null &&
        this.activeTurn.serviceTypes.some((s) => !s.isServiceFinished);
      const showService = !hasServices && !this.configuration.noShowService;

      if (
        showService ||
        (hasServices && !this.configuration.individualServiceCounterEnabled)
      )
        return !this.configuration.noShowService;

      return false;
    },
    showPaidStamps() {
      return (
        this.activeTurn != null &&
        !this.activeTurn.finalized &&
        this.activeTurn.havePaidStamps
      );
    },
    waitingRoomEnabled() {
      return this.configuration.communicationModuleEnabled;
    },
    noActiveWaitingRoom() {
      return (
        !this.activeWaitingRoom ||
        this.activeWaitingRoom.waitingRoomStatus ==
          WaitingRoomStatus.SessionFinished ||
        this.activeWaitingRoom.waitingRoomStatus ==
          WaitingRoomStatus.SessionCancelled
      );
    },
    isRecallDisabled() {
      if (
        !this.waitingRoomEnabled ||
        this.configuration.communicationMaxRecallCount == null
      )
        return false;

      return (
        this.activeTurn.extraCallCount ==
        this.configuration.communicationMaxRecallCount
      );
    },
    isNotPresentDisabled() {
      if (
        !this.waitingRoomEnabled ||
        this.configuration.communicationMaxRecallCount == null
      )
        return false;

      return (
        this.activeTurn.extraCallCount <
        this.configuration.communicationMaxRecallCount
      );
    },
    isMoveToAnotherQueueDisabled() {
      if (!this.waitingRoomEnabled) return false;

      const result =
        this.activeWaitingRoom?.waitingRoomStatus <
        WaitingRoomStatus.SessionInProgress;

      return result;
    },
    isFinalizeDisabled() {
      if (!this.waitingRoomEnabled) return false;

      const result =
        this.activeWaitingRoom?.waitingRoomStatus <
        WaitingRoomStatus.SessionInProgress;

      return result;
    },
    dropIcon() {
      return this.activeTurn.isCall
        ? 'fas fa-phone-slash'
        : 'fas fa-comment-slash';
    },
    dropTitle() {
      return this.activeTurn.isCall
        ? this.$t('finishCall')
        : this.$t('finishChat');
    },
    isWaitingForPassive() {
      return (
        this.waitingRoomEnabled &&
        this.activeWaitingRoom &&
        this.activeWaitingRoom.waitingRoomStatus <=
          WaitingRoomStatus.WaitingPassive
      );
    },
    canFinalizeTurnSearch() {
      return (
        this.waitingRoomEnabled &&
        this.activeWaitingRoom &&
        !this.loading &&
        this.activeWaitingRoom.waitingRoomStatus ==
          WaitingRoomStatus.WaitingCitizen &&
        (!this.activeTurn || !!this.activeTurn.finalized)
      );
    },
    stationFixedServiceQueue() {
      const permission =
        this.fullProfile.roleId === SystemRoles.clientuser ||
        this.fullProfile.roleId === SystemRoles.clientlist;
      const askStation = this.configuration.askStation;
      const fixedServiceQueue =
        this.selectedTurnManagerStation?.fixedServiceQueue;

      return permission && askStation && fixedServiceQueue;
    },
  },
  mixins: [digitalCollectionMixin],
  data() {
    return {
      transferTurnModel: null,
      loading: false,
      availableServiceTypes: [],
      comment: null,
      showMetricsModal: false,
      showEditTurnModal: false,
      showReassignTurnModal: false,
      showBreakNotificationModal: false,
      attendingTimeInterval: null,

      automaticIdleTime: 0,
      automaticIdleTimeInterval: null,
      showFinishServiceTypeModal: false,
      selectedServiceTypeId: null,
      showDocumentsModal: false,

      // Dont link this value to a model, link the selectedServiceTypes property instead
      // this property has all the selected services.
      selectedServiceTypesProxy: [],
      audio: null,
      isTrackTransfer: false,
      isSpecialField1Enabled: false,

      appointmentStampsValidationInfo: null,
      appointmentStampsInfo: null,
      askDocumentIdWhenCancelingStamp: false,

      requestDocumentsDisabled: false,

      initCallsUnsubscribe: null,
      callNextTurnUnsubscribe: null,
      onWaitingRoomCallDeclinedUnsubscribe: null,
      onWaitingRoomCallAcceptedUnsubscribe: null,

      cancelRecallExecution: false,

      isRepresentativeTypeActive: false,
    };
  },
  async mounted() {
    this.initAttendingTimer();

    await this.initAutomaticTurnHandler();

    if (this.activeTurn != null && !this.activeTurn.finalized) {
      await this.loadServiceTypes();
    }

    this.audio = new Audio(AudioCalling);
    this.volume = 1;

    await this.onStationChanged(this.currentActiveStation);

    await _getOfficeParameters(
      this.currentActiveLocation.locationConfigurationId,
    ).then(({ data }) => {
      this.isTrackTransfer = data.transTracking;
    });

    await _getOfficeExtrafields(
      this.currentActiveLocation.locationConfigurationId,
    ).then(({ data }) => {
      this.isSpecialField1Enabled = data.specialField1Enabled;
    });

    this.callNextTurnUnsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === `${modulesName.turnManagerModuleName}/callNextTurn`) {
        if (this.isWaitingForPassive) {
          this.callNextTurn();
        }
      }
    });
    this.initCallsUnsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === `${modulesName.turnManagerModuleName}/initCalls`) {
        this.initCalls();
      }
    });
    this.finalizeWaitingRoomUnsubscribe = this.$store.subscribeAction(
      (action) => {
        if (
          action.type ===
          `${modulesName.turnManagerModuleName}/finalizeWaitingRoom`
        ) {
          this.finalizeWaitingRoom();
        }
      },
    );

    this.onWaitingRoomCallAcceptedUnsubscribe = this.$store.subscribeAction(
      (action) => {
        if (
          action.type ===
          `${modulesName.turnManagerModuleName}/onWaitingRoomCallAccepted`
        ) {
          this.onCallAccepted();
        }
      },
    );
    this.onWaitingRoomCallDeclinedUnsubscribe = this.$store.subscribeAction(
      (action) => {
        if (
          action.type ===
          `${modulesName.turnManagerModuleName}/onWaitingRoomCallDeclined`
        ) {
          this.onCallDeclined();
        }
      },
    );

    if (this.waitingRoomEnabled) {
      await _isRepresentativeTypeActive({
        userId: this.profile?.id,
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
      }).then(({ data }) => {
        this.isRepresentativeTypeActive = data;
      });
    }
  },
  watch: {
    'activeTurn.finalized': {
      handler(newValue, oldValue) {
        if (newValue != oldValue && oldValue == false && newValue) {
          this.initAutomaticTurnHandler(true);
        }
      },
    },
    'activeTurn.serviceQueueId': {
      handler(newValue, oldValue) {
        if (newValue != oldValue && newValue) {
          this.loadServiceTypes();
        }
      },
    },
    'activeBreakAndTurn.activeBreak': {
      handler(newValue, oldValue) {
        if (newValue != oldValue && newValue) {
          this.setActiveBreak(newValue);
        }
      },
    },
    activeTurn: {
      handler: function (newValue, oldValue) {
        this.initAttendingTimer();

        if (newValue != oldValue && oldValue != null && newValue == null) {
          this.initAutomaticTurnHandler(true);
        }

        this.loadServiceTypes();
      },
    },
    userHasPendingBreak: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue && newValue) {
          this.ShowToast('', this.breakNotificationMessage, 'warning');
        }
      },
    },
    currentActiveStation: {
      immediate: true,
      async handler(value) {
        await this.onStationChanged(value);
      },
    },
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, [
      'setSelectedTurnManagerHandlerServiceQueue',
      'setSelectedTurnManagerStation',
      'setActiveTurn',
      'setActiveBreak',
      'setActiveWaitingRoom',
      'searchTurns',
      'setAttendingTime',
      'refreshMetrics',
      'markActiveTurnAsFinalized',
      'checkUserBreak',
    ]),
    ...mapActions(modulesName.userModuleName, [
      'setUserActiveTurn',
      'setUserActiveWaitingRoom',
    ]),
    async onCallAccepted() {
      // TODO: Place custom call acceptance logic here
    },
    async onReassignTurnFormClose() {
      this.showReassignTurnModal = false;
    },
    async onCallDeclined() {
      this.finalizeWaitingRoom();
    },
    async playSound() {
      await this.audio.play();
    },
    showHideMetricsModal() {
      if (!this.showMetricsModal) {
        this.refreshMetrics();
      }

      this.showMetricsModal = !this.showMetricsModal;
    },
    async initAutomaticTurnHandler(autoTake = false) {
      if (
        this.userHasPermissions(IdentityPermission.turns.manager.automatic.call)
      ) {
        const noActiveTurnOrFinalized =
          this.activeTurn == null || this.activeTurn.finalized;
        const noActiveWaitingRoom = this.activeWaitingRoom == null;

        if (noActiveTurnOrFinalized && noActiveWaitingRoom) {
          if (this.configuration.automaticPullEnabled && autoTake) {
            await this.takeNextTurn();
            return;
          }

          if (this.configuration.automaticPushEnabled) {
            await this.initAutomaticPushIdleInterval();
          }
        }
      }
    },
    async initAutomaticPushIdleInterval() {
      clearInterval(this.automaticIdleTimeInterval);
      this.automaticIdleTime = 0;

      this.automaticIdleTimeInterval = setInterval(async () => {
        this.automaticIdleTime += 1;

        if (this.automaticIdleTime > 0 && this.automaticIdleTime % 60 == 0) {
          const noActiveTurnOrFinalized =
            this.activeTurn == null || this.activeTurn.finalized;
          const noActiveWaitingRoom = this.activeWaitingRoom == null;

          if (noActiveTurnOrFinalized && noActiveWaitingRoom) {
            await this.takeNextTurn();
          }
        }
      }, 1000);
    },
    async takeNextTurn() {
      if (!(await this.$refs['turn-manager-form-ref'].validate())) {
        this.initAutomaticTurnHandler();
        return false;
      }

      this.loading = true;

      if (await this.checkUserBreak({ turnFinished: false })) {
        this.loading = false;
        return;
      }

      this.comment = null;

      if (
        this.waitingRoomEnabled &&
        !!this.configuration.representativeCompanionType
      ) {
        const waitingRoomPayload = {
          locationConfigurationId: Number(
            this.currentActiveLocation.locationConfigurationId,
          ),
          serviceQueueId: this.selectedTurnManagerHandlerServiceQueue.id,
          automaticCall:
            this.selectedTurnManagerHandlerServiceQueue &&
            this.selectedTurnManagerHandlerServiceQueue.id == -2,
          participantUserId: this.profile.id,
        };

        await _createWaitingRoom(waitingRoomPayload)
          .then(async ({ data }) => {
            this.$nextTick(async () => {
              this.setActiveWaitingRoom(data);

              var userActiveWaitingRoom = {
                waitingRoomId: data.waitingRoomId,
                locationConfigurationId: data.locationConfigurationId,
              };

              this.setUserActiveWaitingRoom(userActiveWaitingRoom);
            });
          })
          .catch((error) => {
            this.ShowInformationToast(error.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await this.callNextTurn();
        if (this.waitingRoomEnabled && this.activeTurn.isCall) {
          this.initCalls();
        }
      }

      return true;
    },
    // There is a subscription linked to this method.
    async initCalls() {
      this.loading = true;
      await _initCalls(this.activeWaitingRoom?.waitingRoomId).finally(() => {
        this.loading = false;
      });
    },
    // There is a subscription linked to this method.
    async callNextTurn() {
      var payload = {
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        stationId: this.selectedTurnManagerStation
          ? this.selectedTurnManagerStation.id
          : 0,
        serviceQueueId: this.selectedTurnManagerHandlerServiceQueue.id,
        automaticCall:
          this.selectedTurnManagerHandlerServiceQueue &&
          this.selectedTurnManagerHandlerServiceQueue.id == -2,
        waitingRoomId: this.activeWaitingRoom?.waitingRoomId,
        addCommunication:
          this.waitingRoomEnabled &&
          !this.configuration.representativeCompanionType,
      };

      this.loading = true;
      await _callNextTurn(payload)
        .then(async ({ data }) => {
          await this.validateAppointmentStamps(data.turn.clientAppointmentId);

          this.$nextTick(async () => {
            this.setActiveTurn(data.turn);
            if (data.waitingRoom) {
              this.setActiveWaitingRoom(data.waitingRoom);

              var userActiveWaitingRoom = {
                waitingRoomId: data.waitingRoom.waitingRoomId,
                locationConfigurationId:
                  data.waitingRoom.locationConfigurationId,
              };

              this.setUserActiveWaitingRoom(userActiveWaitingRoom);
            }

            this.refreshMetrics(
              this.currentActiveLocation.locationConfigurationId,
            );

            this.playSound();
            await this.searchTurns();
          });
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
          this.initAutomaticTurnHandler();
          this.finalizeWaitingRoom();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onTurnCallServiceQueueChanged(value) {
      await this.initAutomaticTurnHandler();
      this.setSelectedTurnManagerHandlerServiceQueue(value);

      if (this.activeTurn && this.activeTurn.serviceTypes.length == 0
        && this.availableServiceTypes.length === 1
        && this.selectedServiceTypesProxy.length == 0) {
          this.selectedServiceTypes = this.availableServiceTypes[0];
      } else if (this.activeTurn && this.activeTurn.serviceTypes.length == 1
        && this.availableServiceTypes.length === 1
        && this.selectedServiceTypesProxy.length == 0) {
          this.selectedServiceTypes = this.availableServiceTypes[0];
      }
    },
    async loadServiceTypes() {
      if (!this.configuration.hasConfiguredServices || !this.activeTurn) return;

      const payload = {
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
      };

      if (
        !this.configuration.individualServiceCounterEnabled ||
        this.showServices
      ) {
        payload.serviceQueueIds = [this.activeTurn.serviceQueueId];
      }

      this.loading = true;

      await _getTypesConfiguredForServiceQueues(payload)
        .then(({ data }) => {
          this.availableServiceTypes = data;

          this.autoSelectUnfinishedServiceTypes();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onTurnCallStationChanged(value) {
      await this.initAutomaticTurnHandler();

      this.setSelectedTurnManagerStation(value);
    },
    async onServiceTypeChanged(value) {
      this.selectedServiceTypes = value;
    },
    async onMoveToAnotherQueue() {
      if (
        this.isCommentRequired &&
        !(await this.$refs['comment-val-prov-ref'].validate()).valid
      ) {
        this.transferTurnModel = null;
        return;
      }

      const transferTurnModel = {
        ...this.activeTurn,
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        comment: this.comment,
        isCommentRequired: this.isCommentRequired,
      };

      this.transferTurnModel = transferTurnModel;
    },
    onReassignTurn() {
      this.showReassignTurnModal = true;
    },
    async onRecall() {
      if (!this.cancelRecallExecution) {
        const payload = {
          locationConfigurationId: Number(
            this.currentActiveLocation.locationConfigurationId,
          ),
          turnId: this.activeTurn.turnId,
        };

        this.loading = true;

        // Prevent the spam of the turn recall execution for chat and calls
        if (this.activeTurn.isCall || this.activeTurn.isChat) {
          this.cancelRecallExecution = true;

          // 30 secs is the actual timeout for the call
          setTimeout(() => {
            this.cancelRecallExecution = false;
          }, 30000);
        }

        await _recallTurn(payload)
          .then(({ data }) => {
            this.setActiveTurn(data);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onTakeABreak() {
      this.$refs['break-form'].show();
    },
    onTransferTracking() {
      this.$refs['transfer-tracker'].show();
    },
    onCloseTurnModal(event) {
      if (event) return;

      this.transferTurnModel = null;
    },
    async onVideoCall() {
      window.open(this.activeTurn.videoCallURL, '_newtab');
    },
    async onResendVideoCall() {
      await _resendVideoCallLink(Number(this.activeTurn.turnId)).then(() =>
        this.ShowToast(this.$t('success'), this.$t('linkResent'), 'success'),
      );
    },
    async onTransferTurn() {
      this.transferTurnModel = null;
      this.markActiveTurnAsFinalized();
      this.setUserActiveTurn(null);

      this.comment = null;

      await this.finalizeWaitingRoom();

      await this.searchTurns();

      await this.checkUserBreak({ turnFinished: true });
    },
    async onTransferTurnModal(event) {
      if (event) return;

      this.transferTurnModel = null;
    },
    async onSetTurnNotPresent() {
      const payload = {
        locationConfigurationId: this.activeTurn.locationConfigurationId,
        userId: this.profile.id,
        serviceTypeIds: this.showStampService
          ? []
          : this.selectedServiceTypesProxy.map((x) => x.id),
        turnId: this.activeTurn.turnId,
        noShow: true,
        comment:
          this.$t('markedNotPresentBy').replace('{0}', this.profile.name) +
          (this.comment ? ' - ' + this.comment : ''),
      };

      this.loading = true;
      await _finalizeTurn(payload)
        .then(async () => {
          this.ShowToast(
            this.$t('success'),
            this.$t('turnMarkedAsNotPresentSuccessfully'),
            'success',
          );

          this.loading = false;

          this.markActiveTurnAsFinalized();
          this.setUserActiveTurn(null);

          await this.finalizeWaitingRoom();

          await this.searchTurns();

          await this.checkUserBreak({ turnFinished: true });
        })
        .catch((error) => {
          this.loading = false;
          this.ShowErrorToast(error.response.data.message);
        });
    },
    async onTurnFinished(event) {
      const isTurn = this.activeTurn?.turnId == event.turnId;
      const isUser = this.profile?.id == event.userId;

      if (isTurn && isUser) {
        var payload = event.videoCallFinishDate
          ? {
              videoCallFinishDate: event.videoCallFinishDate,
            }
          : {};

        await this.onFinalizeTurn(payload);
      }
    },
    async onDrop() {
      this.loading = true;

      await _updateWaitingRoomStatus({
        waitingRoomId: this.activeWaitingRoom.waitingRoomId,
        waitingRoomStatus: WaitingRoomStatus.SessionCompleted,
      })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        })
        .finally(() => (this.loading = false));
    },
    async transferTurn() {
     const payload = {
        locationConfigurationId: this.activeTurn.locationConfigurationId,
        serviceQueueId: this.activeTurn.automaticTransferQueueId,
        turnId: this.activeTurn.turnId,
        comment: this.comment
      };
      this.loading = true;

      await _transferTurn(payload)
        .then(() => {
          this.onTransferTurn();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => {
          this.loading = false;
        });
    },
    async onFinalizeTurn(payload) {
      // Form validations
      const isValid = await this.$refs['turn-manager-form-ref'].validate();
      if (!isValid) return;

      if (
        !(await this.validateAppointmentStamps(
          this.activeTurn.clientAppointmentId,
        ))
      )
        return;
      if (this.activeTurn.automaticTransferQueueId) {
        await this.transferTurn();
      } else {
        await this.finalizeTurn(payload);
      }

    },
    async finalizeFromStampsModal(payload) {
      if (this.askDocumentIdWhenCancelingStamp) {
        this.$refs['appointment-stamps-askdocumentid-ref'].show();
        return;
      } else {
        await this.finalizeTurn(payload);
      }
    },
    async finalizeTurn(payload) {
      // Form validations
      const isValid = await this.$refs['turn-manager-form-ref'].validate();
      if (!isValid) return;

      payload = {
        ...payload,
        locationConfigurationId: this.activeTurn.locationConfigurationId,
        turnId: this.activeTurn.turnId,
        userId: this.profile.id,
        comment: this.comment,
        serviceTypeIds: this.showStampService
          ? []
          : this.selectedServiceTypesProxy.map((x) => x.id),
        attendingTime: this.activeTurn.attendingTime,
        validAppointmentStamps: this.appointmentStampsInfo?.isValid,
      };

      this.loading = true;

      await _finalizeTurn(payload)
        .then(async () => {
          this.ShowToast(
            this.$t('success'),
            this.$t('turnFinalizedSuccessfully'),
            'success',
          );

          this.askDocumentIdWhenCancelingStamp = false;

          this.selectedServiceTypes = [];
          this.comment = null;

          this.loading = false;

          this.markActiveTurnAsFinalized();
          this.setUserActiveTurn(null);

          await this.finalizeWaitingRoom();

          await this.searchTurns();

          await this.checkUserBreak({ turnFinished: true });
        })
        .catch((error) => {
          this.loading = false;
          this.ShowErrorToast(error.response.data.message);
        });
    },
    // Subscription linked to this method
    async finalizeWaitingRoom() {
      if (!this.waitingRoomEnabled || !this.activeWaitingRoom) return;
      const waitingRoomId = this.activeWaitingRoom.waitingRoomId;
      this.loading = true;

      await _finalizeWaitingRoom(waitingRoomId)
        .then(async () => {
          this.$nextTick(async () => {
            const isActiveSupervisor =
              this.activeWaitingRoom?.participants?.some(
                (u) =>
                  u.participantType === ParticipantType.Active &&
                  u.userId === this.profile?.id &&
                  u.previousParticipantTypes.includes(
                    ParticipantType.Supervisor,
                  ),
              );
            if (isActiveSupervisor) {
              this.$store.dispatch(
                `${modulesName.turnManagerModuleName}/refreshTurnManager`,
                null,
                { root: true },
              );
            }
            this.setActiveWaitingRoom(null);
            this.setUserActiveWaitingRoom(null);
          });

          await this.checkUserBreak({ turnFinished: true });
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setActiveTurnNewInfo(turn) {
      this.setActiveTurn({
        ...this.activeTurn,
        clientEmail: turn.clientEmail,
        clientName: turn.clientName,
        clientCel: turn.clientCel,
        carrierId: turn.carrierId,
        miscDataId: turn.miscDataId,
        specialField1: turn.specialField1,
        specialField2: turn.specialField2,
        specialField3: turn.specialField3,
        specialField4: turn.specialField4,
        specialField5: turn.specialField5,
        specialField6: turn.specialField6,
        specialField7: turn.specialField7,
      });
      this.showEditTurnModal = false;
    },
    initAttendingTimer() {
      clearInterval(this.attendingTimeInterval);
      if (this.activeTurn != null && this.activeTurn.finalized == false) {
        this.attendingTimeInterval = setInterval(() => {
          this.setAttendingTime(this.activeTurn.attendingTime + 1);
        }, 1000);
        return;
      }
    },
    async onFinishServiceType() {
      if (!(await this.$refs['turn-manager-service-form-ref'].validate()))
        return false;

      var payload = {
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        serviceTypeId: this.activeTurn.serviceTypes.find(
          (x) => !x.isServiceFinished,
        ).id,
        newServiceTypeId: this.selectedServiceTypeId,
        turnId: this.activeTurn.turnId,
      };

      this.loading = true;

      await _finishServiceType(payload)
        .then(async ({ data }) => {
          await this.setActiveTurn(data);
          await this.searchTurns();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => {
          this.selectedServiceTypeId = null;
          this.loading = false;
          this.showFinishServiceTypeModal = false;
        });
    },
    autoSelectUnfinishedServiceTypes() {
      if (this.activeTurn && this.activeTurn.serviceTypes.length) {
        this.selectedServiceTypesProxy =
          this.activeTurn.serviceTypes.filter(
            (s) =>
              !s.isServiceFinished &&
              this.availableServiceTypes.some((ast) => ast.id == s.id),
          ) || [];
      }
    },
    async onInstantCall() {
      const payload = {
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        userId: this.profile.id,
      };

      this.loading = true;
      await _instantCallTurn(payload)
        .then(async ({ data }) => {
          await this.setActiveTurn(data);
          this.refreshMetrics(
            this.currentActiveLocation.locationConfigurationId,
          );
          this.searchTurns();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onStationChanged(stationChanged) {
      if (stationChanged.id) {
        let station = this.stations.find((x) => x.id === stationChanged.id);
        this.onTurnCallStationChanged(station);
      }

      if (stationChanged.serviceQueueId) {
        let serviceQueue = this.handlerServiceQueues.find(
          (x) => x.id === stationChanged.serviceQueueId,
        );

        this.onTurnCallServiceQueueChanged(serviceQueue);
      }
    },
    openAppointmentStampsModal() {
      this.$refs['appointment-stamps-ref'].show();
    },
    async validateAppointmentStamps(appointmentId) {
      this.appointmentStampsValidationInfo = null;
      this.appointmentStampsInfo = null;
      this.askDocumentIdWhenCancelingStamp = false;

      if (!appointmentId) return true;

      const stampsValidationResult = await this.validateAndGetStamps(
        appointmentId,
      );

      this.appointmentStampsValidationInfo = stampsValidationResult;
      this.askDocumentIdWhenCancelingStamp =
        stampsValidationResult.askDocumentIdWhenCancelingStamp;
      this.appointmentStampsInfo = stampsValidationResult.stampsData;

      let isValid = this.appointmentStampsInfo.isValid;

      if (!isValid) {
        this.$refs['appointment-stamps-finalize-ref'].show();
      } else if (this.askDocumentIdWhenCancelingStamp) {
        this.$refs['appointment-stamps-askdocumentid-ref'].show();
        return;
      }

      return isValid;
    },
    async callWithMSTeams() {
      window.location = this.activeTurn.msTeamsCallURL;
    },
    async onRequestDocuments() {
      if (!this.requestDocumentsDisabled) {
        this.requestDocumentsDisabled = true;

        setTimeout(() => {
          this.requestDocumentsDisabled = false;
        }, 30000);

        this.loading = true;

        let payload = {
          locationConfigurationId: parseInt(
            this.currentActiveLocation.locationConfigurationId,
          ),
          turnId: this.activeTurn.turnId,
        };

        await _requestTurnDocuments(payload)
          .then(() => {
            this.ShowSuccessSaveToast();
          })
          .catch((err) => {
            this.ShowErrorToast(err.response.data.message);
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.attendingTimeInterval);
    clearInterval(this.automaticIdleTimeInterval);

    if (this.callNextTurnUnsubscribe) this.callNextTurnUnsubscribe();
    if (this.initCallsUnsubscribe) this.initCallsUnsubscribe();
    if (this.finalizeWaitingRoomUnsubscribe)
      this.finalizeWaitingRoomUnsubscribe();
    if (this.onWaitingRoomCallAcceptedUnsubscribe)
      this.onWaitingRoomCallAcceptedUnsubscribe();
    if (this.onWaitingRoomCallDeclinedUnsubscribe)
      this.onWaitingRoomCallDeclinedUnsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 10px;
  border: 0;
}
.header i {
  float: right;
}

#turn-manager .container {
  margin-bottom: 2.4rem;
}

#turn-manager h4 {
  font-weight: bold;
  font-size: 20px;
}

#turn-manager .card > hr {
  margin-bottom: 0;
}

#turn-manager .small-actions {
  min-height: 65px;
}

#turn-manager .small-action {
  margin-top: 15px;
  z-index: 2;
}

#turn-manager .body .title {
  text-align: center;
}

#turn-manager .action-button {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#turn-manager .footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  min-height: 309px;
}

#turn-manager .actions {
  margin-top: 5%;
}

#turn-manager .footer.active {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  min-height: 110px;
}

#turn-manager .footer.active .actions {
  margin-top: 21px;
}

#turn-manager .action {
  margin-top: 5%;
}

// #active-turn-header-metadata > div {
//   padding-top:  0.25rem;
//   padding-bottom:  0.25rem;
// }

#active-turn-header-metadata > div span {
  font-size: 14px;
  font-weight: bolder;
  margin: 0 39px;
}

#active-turn-header {
  margin-top: -47px;
}

#hTurnNumber {
  font-size: 54px;
  margin: 0;
}

#hClientName,
::v-deep #hClientCel {
  font-size: 32px;
  margin: 0;
}

::v-deep #hClientCel.orange {
  color: $color-primary;
}

::v-deep #hClientCel > div > label {
  font-size: 32px;
}

.special-field {
  margin-top: 10px;
  padding-top: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.queue-called {
  padding-top: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.queue-called .special-field-container {
  margin: 0;
  width: 100%;
}

.special-field.queue-called > div {
  width: 100%;
}

.special-field-container {
  background: $color-app-background 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  height: 100%;
  display: table;
  text-align: center;
  width: 100%;
  padding: 5px 15px;
}

.special-field-container span {
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}

.special-field:nth-child(odd) {
  margin-top: 10px;
}

.special-field:nth-child(even) {
  margin-top: 10px;
}

.special-field-container:nth-child(odd) {
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 769px) {
  #turn-manager {
    margin-top: 15px;
  }

  .metrics-container {
    padding-right: 0;
  }

  .special-field-container {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .turn-manager-row {
    min-height: 634px;
  }

  .turn-manager-row .body {
    min-height: 304px;
  }

  #turn-manager {
    height: 100%;
  }
}
</style>
