<template>
  <div class="main-content">
    <b-row class="mb-4">
      <b-col>
        <div class="caption text-left main-content-title">
          {{ $t('turnsManager') }}
        </div>
        <hr
          class="hr-fluid"
        />
      </b-col>
    </b-row>
    <div v-if="isEmptyManager">
      <b-row class="mb-5">
        <b-col>
          <div class="caption text-center main-content-title font-medium">
            {{ $t('calledRow') }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group :label="$t('advanceToRow')">
            <select
              id="status"
              class="form-control"
              name="status"
            >
              <option value="0">
                {{ $t('select') }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group :label="$t('advanceToStation')">
            <select
              id="status"
              class="form-control"
              name="status"
            >
              <option value="0">
                {{ $t('select') }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col
          class="turns-manager-actions"
          cols="12"
          lg="4"
        >
          <b-form-group>
            <a
              class="btn"
              @click="setTurnCalled"
            >
              <img src="../../assets/plus_button.svg" />
            </a>
          </b-form-group>
        </b-col>
        <b-col
          class="turns-manager-actions"
          cols="12"
          lg="4"
        >
          <b-form-group>
            <a class="btn">
              <img src="../../assets/pause_button.svg" />
            </a>
          </b-form-group>
        </b-col>
        <b-col
          class="turns-manager-actions"
          cols="12"
          lg="4"
        >
          <b-form-group>
            <a class="btn">
              <img src="../../assets/clock_button.svg" />
            </a>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="isTurnCalled">
      <b-row class="mb-4">
        <b-col>
          <div class="turns-manager-actions medium">
            <a
              class="btn"
              style="float: left"
            >
              <img src="../../assets/pause_button.svg" />
            </a>
            <a
              class="btn"
              style="float: right"
            >
              <img src="../../assets/pencil.png" />
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4 called-turn-header">
        <b-col>
          <div class="caption text-center main-content-title font-medium jumbo">
            1002
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <div
            class="caption text-center main-content-title font-medium medium"
          >
            Reynaldo Arroyo
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <div
            class="caption text-center main-content-title font-medium medium main-color"
          >
            787-717-4275
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="text-center">
          <label class="turns-manager-called-row-header">
            {{ $t('calledRow') }}: Ciudadano
          </label>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group :label="$t('advanceToRow')">
            <select
              id="status"
              class="form-control"
              name="status"
            >
              <option value="0">
                {{ $t('automatic') }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group :label="$t('service')">
            <input
              class="form-control"
              type="text"
              :value="$t('password')"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group :label="$t('advanceToStation')">
            <select
              id="status"
              class="form-control"
              name="status"
            >
              <option value="0">
                Turnos PR
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col
          class="mb-4"
          cols="12"
          lg="6"
        >
          <b-form-group :label="$t('comment')">
            <textarea
              class="form-control"
              multiple
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="turns-manager-actions medium"
          cols="4"
          lg="2"
        >
          <b-form-group>
            <a
              class="btn"
              @click="setEmptyManager"
            >
              <img src="../../assets/plus_button.svg" />
            </a>
          </b-form-group>
        </b-col>
        <b-col
          class="turns-manager-actions medium"
          cols="4"
          lg="2"
        >
          <b-form-group>
            <a class="btn">
              <img src="../../assets/pause_button.svg" />
            </a>
          </b-form-group>
        </b-col>
        <b-col
          class="turns-manager-actions medium"
          cols="4"
          lg="2"
        >
          <b-form-group>
            <a class="btn">
              <img src="../../assets/clock_button.svg" />
            </a>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showLoader: false,
      isEmptyManager: true,
      isTurnCalled: false
    };
  },
  methods: {
    setTurnCalled () {
      this.isEmptyManager = false;
      this.isTurnCalled = true;
    },
    setEmptyManager () {
      this.isTurnCalled = false;
      this.isEmptyManager = true;
    }
  }
};
</script>
<style scoped>
</style>
