<template>
  <div
    :id="id"
    class="time-counter px-3 py-1"
    :class="{
      danger: startTimeSeconds >= dangerValidation && dangerValidation,
      success: startTimeSeconds < dangerValidation && dangerValidation,
    }"
  >
    <div class="title">
      {{ title }}
    </div>
    <div class="time">
      {{ timeTotal }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimerCounter',
  props: {
    id: {
      type: String,
      default: 'pnlTimeCounter',
    },
    title: {
      type: String,
      default: '',
    },
    startTimeSeconds: {
      type: Number,
      default: 0,
    },
    onCountdownFinish: {
      type: Function,
      default: () => {},
    },
    isCountdown: {
      type: Boolean,
      default: false,
    },
    dangerValidation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    timeTotal() {
      return this.secondsToTime(this.startTimeSeconds);
    },
  },
  methods: {
    secondsToTime(secs) {
      secs = Math.round(secs);
      var hours = Math.floor(secs / (60 * 60))
        .toString()
        .padStart(2, '0');

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60)
        .toString()
        .padStart(2, '0');

      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds).toString().padStart(2, '0');

      var obj = {
        h: hours,
        m: minutes,
        s: seconds,
      };
      return `${obj.h}:${obj.m}:${obj.s}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.time-counter {
  background-color: #f8e1a2;
  color: #7d4a08;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  right: 15px;
  top: 75px;
  position: absolute;
  z-index: 100;
}

.time-counter .title {
  font-size: 9px;
}

.time-counter .time {
  font-size: 23px;
}

.time-counter {
  &.success {
    background-color: #a2f8ab;
    color: #087d08;
  }
  &.danger {
    background-color: #f8a2a2;
    color: #7d0808;
  }
}

@media only screen and (max-width: 768px) {
  .time-counter {
    width: 100%;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 5px 0;
    border-radius: 0;
    display: block;
    position: -webkit-sticky;
    position: sticky;
  }
}
</style>
