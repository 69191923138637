<template>
  <div style="min-height: 300px">
    <b-row class="pt-4">
      <b-col cols="12" lg="4" class="pr-lg-0">
        <base-input v-model="searchName" :placeholder="$t('searchCited')" />
      </b-col>
      <b-col cols="12" lg="2" class="pl-lg-3">
        <b-button
          block
          class="text-capitalize"
          variant="primary"
          @click="onSearch()"
        >
          <i class="far fa-search" /> {{ $t('filter') }}
        </b-button>
      </b-col>
    </b-row>

    <div v-if="hasAppointment">
      <div
        class="result containter-fluid"
        v-for="appointment in appointmentFiltered"
        :key="appointment.clientAppointmentId"
      >
        <b-row>
          <b-col cols="12" lg="3" class="pr-0">
            <p class="d-flex">
              <span>{{ $t('name') }}:</span>
              <span>{{ appointment.clientFullName }}</span>
            </p>
            <p class="d-flex">
              <span>{{ $t('appointmentNumber') }}:</span>
              <span>{{ appointment.clientAppointmentId }}</span>
            </p>
          </b-col>
          <b-col cols="12" lg="4">
            <p class="d-flex">
              <span>{{ $t('appointmentTime') }}:</span>
              <span>{{ formatDate(appointment.appointmentTimeStart) }}</span>
            </p>
            <p class="d-flex">
              <span>{{ $t('service') }}:</span>
              <span>{{ appointment.serviceTypeName }}</span>
            </p>
          </b-col>
          <b-col cols="12" lg="3">
            <p class="d-flex">
              <span>{{ $t('specialist') }}:</span>
              <span>{{ appointment.specialistNameFull }}</span>
            </p>
          </b-col>
          <b-col lg="2">
            <b-button block class="text-capitalize" variant="primary" @click="onSelect({...appointment})">
              <i class="far fa-check" /> {{ $t('select') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-if="!hasAppointment && !loading">
      <div class="empty-list containter-fluid p-4 my-4">
        <b-row>
          <b-col cols="12" lg="12">
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <i class="fal fa-calendar-alt"></i>
              <span>{{ $t('noAppointmentsAvailable') }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import BaseInput from '@/components/BaseInput';

export default {
  components: {
    BaseInput,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchName: null,
    availableAppointments: [],
  }),
  computed: {
    disabledSearchButton: function () {
      return !this.searchName;
    },
    appointmentFiltered() {
      if(this.availableAppointments.length === this.values.length) {
        return this.values;
      }
      
      return this.availableAppointments;
    },
    hasAppointment() {
      return this.availableAppointments.length > 0;
    },
  },
  methods: {
    onSelect(appointmentNumber) {
      this.$emit('select', appointmentNumber);
    },
    onSearch() {
      this.availableAppointments =
        (this.searchName &&
          this.values.filter(
            (f) => f.clientFullName.toLowerCase().indexOf(this.searchName.trim().toLowerCase()) > -1,
          )) ||
        this.values;
    },
    formatDate: (value) => {
      return moment(value).format('MM/DD/yyyy hh:mm:ss A');
    },
  },
  watch: {
    values: {
      handler: function (value) {
        this.availableAppointments = value;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.result {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #edeeef;
  border-radius: 12px;
  padding: 18px !important;
  margin: 15px 0;

  p > span:first-of-type {
    font-weight: bold;
    min-width: 115px;
    justify-items: center;
  }
}

.empty-list {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  font: normal normal bold 20px/28px Lato;
  letter-spacing: 0px;
  color: #ff8105;
  opacity: 1;

  i {
    font-size: 32px;
  }
}
</style>
