<template>
  <validation-observer ref="form" novalidate>
    <div class="row">
      <loading :active="loading" :is-full-page="false" color="#F37E00" />

      <div class="col-md-6">
        <validation-provider v-slot="{ errors }" rules="required">
          <filter-select
            v-model="model.serviceQueueId"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            fieldtext="name"
            fieldvalue="id"
            :label="$t('row')"
            :options="collections.serviceQueues"
          />
        </validation-provider>
      </div>
      <div class="col-md-6" v-if="hasConfiguredServices">
        <validation-provider v-slot="{ errors }" rules="required">
          <filter-select
            v-model="model.codeServiceTypeId"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            fieldtext="name"
            fieldvalue="id"
            :label="$t('service')"
            :options="collections.services"
          />
        </validation-provider>
      </div>
      <div class="col-md-12">
        <validation-provider
          v-slot="{ errors }"
          :rules="commentRules"
        >
          <base-input
            v-model="model.comment"
            :error="errors[0]"
            :error-msg="commentErrorMessage"
            :label="$t('comment')"
            :max-length="commentRules.max"
            :textarea="true"
          />
        </validation-provider>
      </div>

      <div class="col-md-12 text-right">
        <base-filled-button
          class="mr-0"
          icon-class="far fa-save"
          :on-click="onSubmit"
          :text="$t('reassign')"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  getServiceTypes as _getServiceTypes,
  reassignTurn as _reassignTurn,
} from '@/services/TurnService';
import BaseInput from '@/components/BaseInput.vue';
import { mapActions, mapGetters } from 'vuex';
import { modulesName } from '@/store';

export default {
  name: 'ManangerTurnForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    commentRules: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      collections: {
        serviceQueues: [],
        attenders: [],
        services: [],
      },
      model: {
        serviceQueueId: null,
        codeServiceTypeId: null,
        comment: null,
        attenderId: null,
      },
      loading: false,
    };
  },
  async mounted() {
    const turnState = this.$store.state[modulesName.turnManagerModuleName];

    this.collections.serviceQueues = turnState.serviceQueues;
    this.collections.attenders = turnState.attenders;
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation', 'profile']),
    hasConfiguredServices() {
      return this.$store.state[modulesName.turnManagerModuleName].configuration.hasConfiguredServices;
    },
    commentErrorMessage() {
      const rules = this.commentRules;
      const currentComment = this.model.comment || '';

      if (rules.required && !currentComment) {
        return this.$t('commentRequired');
      } else if (currentComment.length < rules.min) {
        return this.$t('commentMinLength').replace('{0}', rules.min);
      } else if (currentComment.length >= rules.max) {
        return this.$t('commentMaxLength').replace('{0}', rules.max);
      }

      return null;
    },
  },
  watch: {
    async 'model.serviceQueueId'(value) {
      if (!value) {
        return (this.model.codeServiceTypeId = null);
      }
      await this.loadServiceTypes();
    },
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, [
      'setActiveTurn',
    ]),
    async loadServiceTypes() {
      if(!this.hasConfiguredServices)
        return;

      this.loading = true;

      await _getServiceTypes(
        this.currentActiveLocation.locationConfigurationId,
        [this.model.serviceQueueId],
      )
        .then((response) => {
          this.collections.services = response.data;
          if (
            !this.collections.services.find(
              (x) => x.id == this.model.codeServiceTypeId,
            )
          )
            this.model.codeServiceTypeId = null;
        })
        .finally(() => (this.loading = false));
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;
      this.loading = true;

      _reassignTurn({
        ...this.model,
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        turnId: this.value.turnId,
        specialField2: this.value.specialField2,
        specialField5: this.value.specialField5,
        attenderId: this.profile.id
      })
        .then((response) => {
          this.ShowSuccessSaveToast();
          this.$emit('close')
          this.setActiveTurn(response.data);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
