<template>
  <div class="container-fluid">
    <loading
      :active="loaders.turnManager.fullPage"
      color="#F37E00"
      :is-full-page="true"
    />
    <div v-if="showManager && !showWaitingRoom" class="row">
      <div class="col-md-12 my-3">
        <h3 class="font-weight: bolder;">{{ $t('turnManager') }}</h3>
      </div>
      <div class="col-sm-12">
        <div class="row">
          <div
            v-if="showMaintenance || showTurns"
            class="col-sm-12 col-lg-8 m-center mb-4"
          >
            <ManagerAdministrator
              :manager-permissions="managerPermissions"
            />
          </div>
          <div id="turn-manager-panel" class="col-sm-12 col-lg-4 m-center">
            <ManagerControl
              v-if="configuration"
              ref="managerControl"
              :attenders="collections.attenders"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="showManager && showWaitingRoom" class="row">
      <div class="col-md-12 my-3">
        <h3 class="font-weight: bolder;">{{ $t('turnWaitingRoom') }}</h3>
      </div>
      <div class="col-sm-12">
        <ManagerWaitingRoom />
      </div>
    </div>

    <AlertMessage
      v-if="!showManager && !loading"
      :message="$t('selectOfficeForTurnsManager')"
      style="margin-top: 150px"
    />
    <BigLoader v-if="loading" />
    <timer-counter
      v-if="activeTurn && !activeTurn.finalized"
      :danger-validation="attentionGoalTime"
      :start-time-seconds="activeTurn.attendingTime || 0"
      :title="$t('serviceTotalTime')"
    />
  </div>
</template>

<script>
import ManagerControl from './components/ManagerControl';
import ManagerAdministrator from './components/ManagerAdministrator';
import IdentityPermission from '@/constants/IdentityPermission';
import DisplayTurnType from '@/constants/DisplayTurnType';
import AlertMessage from '@/components/basics/alerts/AlertMessage';
import BigLoader from '@/components/BigLoader';
import TimerCounter from '@/components/TimerCounter.vue';
import ManagerWaitingRoom from './components/ManagerWaitingRoom';

import { modulesName } from '@/store';
import { mapState, mapGetters, mapActions } from 'vuex';

import { initTurnManager as _initTurnManager } from '@/services/TurnService';

import { 
  checkIfUserHasActiveCall as _checkIfUserHasActiveCall
} from '@/services/CommunicationService';

import moment from 'moment';

export default {
  name: 'TurnsManager',
  components: {
    ManagerControl,
    AlertMessage,
    BigLoader,
    TimerCounter,
    ManagerAdministrator,
    ManagerWaitingRoom,
  },
  data() {
    return {
      showManager: false,
      loading: false,
      managerPermissions: null,
      actionUnsubscribe: null,
      callCheckExecuted: false,
      collections: {
        attenders: [],
      },
    };
  },
  mounted() {
    document.addEventListener('visibilitychange', this.updateAttendingTime);

    this.actionUnsubscribe = this.$store.subscribeAction(async (action) => {
      if (
        action.type ===
        `${modulesName.turnManagerModuleName}/refreshTurnManager`
      )
        await this.initialize();
    });
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    ...mapState(modulesName.turnManagerModuleName, [
      'activeTurn',
      'loaders',
      'configuration',
    ]),
    attentionGoalTime() {
      if (!this.activeTurn) return 0;
      return this.activeTurn.attentionGoalTime * 60
    },
    showMaintenance() {
      return (
        this.userHasPermissions(
          IdentityPermission.turns.access.client.manager,
        ) || this.userHasPermissions(IdentityPermission.turns.access.record)
      );
    },
    showTurns() {
      return (
        this.userHasPermissions(IdentityPermission.turns.access.list.default) &&
        ((this.managerPermissions &&
          this.managerPermissions.turnDisplayPermission !==
            DisplayTurnType.None) ||
          this.configuration.communicationModuleEnabled)
      );
    },
    showTurnManager() {
      return this.userHasPermissions(
        IdentityPermission.turns.access.turn.manager.all,
      );
    },
    showWaitingRoom() {
      return (
        this.configuration?.communicationModuleEnabled &&
        this.configuration?.waitingRoomConfig?.waitingRoomMode == 2
      );
    },
  },
  watch: {
    currentActiveLocation: {
      immediate: true,
      async handler(value) {
        if (!value) return (this.showManager = false);

        await this.initialize();
      },
    },
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, [
      'setInit',
      'setTurnSearchFilters',
      'resetState',
      'setAttendingTime',
    ]),
    ...mapActions(modulesName.userModuleName, ['setUserActiveWaitingRoom']),
    async initialize() {
      this.loading = true;
      this.showManager = false;
      await _initTurnManager(this.currentActiveLocation.locationConfigurationId)
        .then(async ({ data }) => {
          this.setInit(data);

          this.setTurnSearchFilters({
            ...this.$store.state[modulesName.turnManagerModuleName]
              .turnListSearchFilters,
            locationConfigurationId: Number(
              this.currentActiveLocation.locationConfigurationId,
            ),
          });

          if (data.activeWaitingRoom) {
            var userActiveWaitingRoom = {
              waitingRoomId: data.activeWaitingRoom.waitingRoomId,
              locationConfigurationId:
                data.activeWaitingRoom.locationConfigurationId,
            };

            this.setUserActiveWaitingRoom(userActiveWaitingRoom);

            if(!this.callCheckExecuted)
              await _checkIfUserHasActiveCall(true);

            this.callCheckExecuted = true;

          } else {
            this.setUserActiveWaitingRoom(null);
          }

          this.managerPermissions = data.permissions;
          this.showManager = true;

          this.loading = false;
        })
        .catch(() => {});
    },
    updateAttendingTime() {
      if (!document.hidden && this.activeTurn?.date) {
        const d = new Date();
        const currentDate = moment.utc(d.toUTCString());
        const turnDate = moment.utc(this.activeTurn.date);

        var duration = moment.duration(currentDate.diff(turnDate)).asSeconds();
        this.setAttendingTime(duration);
      }
    },
  },
  beforeDestroy() {
    this.resetState();
    document.removeEventListener('visibilitychange', this.updateAttendingTime);
  },
};
</script>

<style lang="scss" scoped>
.m-center {
  margin: 0 auto auto auto;
}
</style>
