<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="p-3 card search-result"
        :class="{
          expand: expand,
          white: !value.attended && !value.haveStamp && !value.isPreferential,
          purple: !value.attended && !value.haveStamp && value.isPreferential,
          'search-result-red': value.attended && value.notPresent,
          yellow: !value.attended && value.haveStamp && stampsHandlingEnabled,
          grey: value.attended && !value.notPresent && !value.finalized,
          lightgreen: value.attended && value.finalized && !value.notPresent,
        }"
      >
        <span
          class="button-expand"
          @click="$emit('expand', !expand ? index : null)"
        >
          <i
            class="fal"
            :class="{
              'fa-chevron-circle-right': !expand,
              'fa-chevron-circle-down': expand,
            }"
          ></i>
        </span>

        <div class="row">
          <div class="col-md-6 col-xl-4">
            <div class="cell">
              <label>{{ $t('number') }}: </label>
              <span
                >{{ value.serviceQueueStartingLetter || ''
                }}{{ value.number || '-' }}</span
              >
            </div>
            <div class="cell">
              <label>{{ $t('row') }}: </label>
              <span>{{ value.serviceQueueName || '-' }}</span>
            </div>
            <div
              v-if="
                specialFieldsInput && specialFieldsInput.specialField1Enabled
              "
              class="cell"
            >
              <label>
                {{ specialFieldsInput.specialField1Name || `Misc1` }}:
              </label>
              <span>{{ value.specialField1 || '-' }}</span>
            </div>

            <div class="cell">
              <label>{{ $t('service') }}: </label>
              <span>{{ serviceTypeNames }}</span>
            </div>
          </div>
          <div class="col-md-5 col-xl-4">
            <div class="cell">
              <label>{{ $t('client') }}: </label>
              <span>{{ value.clientName || '-' }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('waitTimeInMinutes') }}: </label>
              <span
                class="minute-box"
                :class="{ green: !exceedsWaitingTime, red: exceedsWaitingTime }"
              >
                {{ value.waitMinutes }}
              </span>
            </div>
            <div
              v-if="
                specialFieldsInput && specialFieldsInput.specialField2Enabled
              "
              class="cell"
            >
              <label>
                {{ specialFieldsInput.specialField2Name || `Misc2` }}:
              </label>
              <span>{{ specialField2(value.specialField2) }}</span>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div v-if="haveFullPermission" class="cell">
              <label>{{ $t('visitTo') }}: </label>
              <span>{{ value.attendedByName || '-' }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('appointment') }}: </label>
              <span>{{ value.appointmentNumber || '-' }}</span>
            </div>
            <div
              v-if="
                specialFieldsInput && specialFieldsInput.specialField3Enabled
              "
              class="cell"
            >
              <label>
                {{ specialFieldsInput.specialField3Name || `Misc3` }}:
              </label>
              <span>{{ value.specialField3 || '-' }}</span>
            </div>
          </div>

          <div v-if="haveFullPermission" class="col-md-10">
            <div class="cell">
              <label>{{ $t('comment') }}: </label>
              <div
                :id="`comment-popover-${value.id}`"
                style="max-width: 700px"
                v-if="comments.length"
                class="cursor-pointer text-truncate comments pl-1"
              >
                {{ comments[0] }}
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </div>
        <div v-if="haveActionsPermission" class="action-options">
          <b-dropdown variant="primary" right>
            <template #button-content>
              <i class="far fa-cog" />
            </template>
            <span v-for="(action, i) in visibleActions" :key="i">
              <b-dropdown-item @click="() => action.action(value)">
                <i
                  class="mr-1"
                  :class="action.iconClass"
                  :style="{ color: action.iconColor }"
                ></i>
                {{ action.label }}
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
            </span>
          </b-dropdown>
        </div>
        <div v-if="haveActionsPermission && value.haveTurnDocuments" class="turn-icons">
          <div style="height: 35px !important"
            v-if="haveActionsPermission && value.haveTurnDocuments"
          ><span  class="chip blue"><i class="fas fa-folder folder-icon" v-b-tooltip.hover:title="$t('thereAreAttachedDocuments')"/></span>
        </div>

        </div>
        <b-popover
          :target="
            comments[0] !== '-' ? `comment-popover-${value.id}` : 'no-comment'
          "
          triggers="hover"
          placement="top"
        >
          <template #title>{{ $t('comment') }}</template>
          <div v-for="comment in comments" :key="comment">- {{ comment }}</div>
        </b-popover>
      </div>
    </div>
    <div v-if="expand" class="col-md-12 mt-1">
      <div
        class="p-2 card search-result"
        :class="{
          'expanded-white':
            !value.attended && !value.haveStamp && !value.isPreferential,
          'expanded-purple':
            !value.attended && !value.haveStamp && value.isPreferential,
          'expanded-red': value.attended && value.notPresent,
          'expanded-yellow':
            !value.attended && value.haveStamp && stampsHandlingEnabled,
          'expanded-grey':
            value.attended && !value.notPresent && !value.finalized,
          'expanded-lightgreen':
            value.attended && value.finalized && !value.notPresent,
        }"
      >
        <div class="box p-3">
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <div class="cell">
                <label>{{ $t('cellPhone') }}: </label>
                <span v-if="value.phoneNumber">{{
                  value.phoneNumber | telephone
                }}</span>
                <span v-else>-</span>
              </div>
              <div class="cell">
                <label>{{ $t('status') }}: </label>
                <span>{{ status }}</span>
              </div>
              <div class="cell">
                <label>{{ $t('attended') }}: </label>
                <span
                  >{{ $t(value.attended ? 'yes' : 'no') }}
                  {{
                    value.attended && !!value.stationName
                      ? `- ${value.stationName}`
                      : ''
                  }}</span
                >
              </div>
            </div>
            <div class="col-md-6 col-xl-4">
              <div class="cell">
                <label>{{ $t('arrivalTime') }}: </label>
                <span v-if="value.arrivalDate"
                  >{{ value.arrivalDate | date }} -
                  {{ value.arrivalDate | hour }}</span
                >
                <span v-else> - </span>
              </div>
            </div>
            <div class="col-md-6 col-xl-4">
              <div class="cell">
                <label>{{ $t('reactivated') }}: </label>
                <span>{{ $t(value.isReactivated ? 'yes' : 'no') }}</span>
              </div>
              <div class="cell">
                <label>{{ $t('cited') }}: </label>
                <span v-if="value.cited"
                  >{{ value.cited | date }} - {{ value.cited | hour }}</span
                >
                <span v-else>-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayTurnType from '@/constants/DisplayTurnType';
import { mapState, mapGetters } from 'vuex';
import { modulesName } from '@/store';
import IdentityPermission from '@/constants/IdentityPermission';
import SystemRoles from '@/constants/SystemRoles';

export default {
  name: 'SearchResult',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    specialFieldsInput: {
      type: Object,
      default: () => {},
    },
    waitingTime: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    indexToExpand: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showComments: false,
      requestDocumentsDisabled: false,
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'fullProfile',
      'currentActiveLocation'
    ]),
    ...mapState(modulesName.turnManagerModuleName, [
      'activeTurn',
      'defaultServiceQueue',
      'serviceQueueSelectionFixed',
    ]),
    ...mapState({
      managerPermissions: (state) =>
        state[modulesName.turnManagerModuleName].permissions,
      canPrint: (state) =>
        state[modulesName.turnManagerModuleName].configuration.printerEnabled,
      stampsHandlingEnabled: (state) =>
        state[modulesName.turnManagerModuleName].configuration
          .stampsHandlingEnabled,
      canUploadDocuments: (state) =>
        state[modulesName.turnManagerModuleName].canUploadDocuments,
      enableLanguageBehavior: (state) =>
        state[modulesName.turnManagerModuleName].enableLanguageBehavior,
      individualCallEnabled(state) {
        let enabled = true;

        // when it has serviceQueue by default
        if (
          this.defaultServiceQueue &&
          this.defaultServiceQueue.id > 0 &&
          this.value.serviceQueueId &&
          this.serviceQueueSelectionFixed
        ) {
          enabled = this.defaultServiceQueue.id == this.value.serviceQueueId;
        }

        // when it has verify visiting user enabled
        if (
          this.value.serviceQueueVerifyVisitingUser &&
          this.value.attenderId
        ) {
          enabled = this.value.attenderId == this.fullProfile.userId;
        }

        return (
          (state[modulesName.turnManagerModuleName].configuration
            .individualCallEnabled || this.value.serviceQueueIndividualCall) && enabled
        );
      },
    }),
    expand() {
      return this.index === this.indexToExpand;
    },
    isIndividualCallVisible() {
      let visible = false;

      // If there's an active turn, no calling another one
      if (this.activeTurn && !this.activeTurn.finalized) {
        return visible;
      }

      // Supervisors (client) can always make individual calls
      if (this.fullProfile.roleId === SystemRoles.client) {
        visible = true;
      } else {
        visible =
          this.managerPermissions.canCallTurn && this.individualCallEnabled;
      }

      return visible && !this.value.attended;
    },
    canCloseTurn() {
      let visible = false;

      // If there's an active turn, no calling another one
      if (this.activeTurn && !this.activeTurn.finalized) {
        return visible;
      }

      return !this.value.attended && this.userHasPermissions(IdentityPermission.user.turn.close);
    },
    haveFullPermission() {
      return (
        this.managerPermissions &&
        this.managerPermissions.turnDisplayPermission === DisplayTurnType.Full
      );
    },
    haveActionsPermission() {
      return (
        this.managerPermissions &&
        // For actions, both Basic and Full are allowed
        (this.managerPermissions.turnDisplayPermission ===
          DisplayTurnType.Full ||
          this.managerPermissions.turnDisplayPermission ===
            DisplayTurnType.Basic)
      );
    },
    exceedsWaitingTime() {
      return this.waitingTime && this.waitingTime < this.value.waitMinutes;
    },
    actions() {
      return [
        {
          label: this.$t('singleCall'),
          iconClass: 'far fa-volume-up',
          iconColor: '#399C21',
          visible: () => this.isIndividualCallVisible,
          action: () => this.$emit('call-individual-turn'),
        },
        {
          label: this.$t('removeTurn'),
          iconClass: 'far fa-times-circle',
          iconColor: '#D53030',
          visible: () =>
            this.managerPermissions.canRemoveTurn && !this.value.attended,
          action: () => this.$emit('remove-turn'),
        },
        {
          label: this.$t('removeAttended'),
          iconClass: 'fal fa-check-circle',
          iconColor: '#FF0000',
          visible: () =>
            this.value.attended &&
            this.userHasPermissions(
              IdentityPermission.turns.access.list.remove.attended,
            ),
          action: () => this.$emit('remove-attended'),
        },
        {
          label: this.$t('editTurn'),
          iconClass: 'far fa-edit',
          iconColor: '#5E8AC9',
          action: () => this.$emit('edit'),
          visible: () =>
            this.userHasPermissions(
              IdentityPermission.turns.access.list.edit.turn,
            ),
        },
        {
          label: this.$t('reprint'),
          iconClass: 'fas fa-print',
          iconColor: '#5E8AC9',
          visible: () =>
            this.canPrint &&
            this.userHasPermissions(
              IdentityPermission.turns.access.list.reprint,
            ),
          action: () => this.$emit('print'),
        },
        {
          label: this.$t('surveys'),
          iconClass: 'far fa-file-alt',
          iconColor: '#5E8AC9',
          action: () => this.$emit('show-survey'),
          visible: () =>
            this.userHasPermissions(
              IdentityPermission.turns.access.list.surveys,
            ),
        },
        {
          label: this.$t('document(s)'),
          iconClass: 'fal fa-folder-download',
          iconColor: '#5E8AC9',
          visible: () =>
            this.canUploadDocuments ||
            (this.value.haveDocuments &&
              this.userHasPermissions(
                IdentityPermission.turns.access.list.documents,
              )) ||
            this.value.haveTurnDocuments,
          action: () => this.$emit('show-documents'),
        },
        {
          label: this.$t('requestDocuments'),
          iconClass: 'fal fa-cloud-upload',
          iconColor: '#D53030',
          disabled: this.requestDocumentsDisabled,
          visible: () => this.canUploadDocuments,
          action: () => this.handleDocumentsRequest(),
        },
        {
          label: this.$t('closeTurn'),
          iconClass: 'fal fa-check',
          iconColor: '#399C21',
          visible: () => this.canCloseTurn,
          action: () => this.$emit('close-turn'),
        },
      ];
    },
    visibleActions() {
      const dataItem = this.value;
      return this.actions.filter((x) => {
        if (x.visible === undefined) return true;

        if (typeof x.visible === 'function') return x.visible(dataItem);
        else return x.visible;
      });
    },
    serviceTypeNames() {
      return (this.value && this.value.serviceTypeNames.join(', ')) || '-';
    },
    comments() {
      const comments = (this.value.comment &&
        this.value.comment.split('\r\n')) || ['-'];
      return comments;
    },
    status() {
      let status = 'statusUncalled';
      if (this.value.attended && this.value.notPresent)
        status = 'statusCalledNotPresent';
      else if (
        !this.value.attended &&
        this.value.haveStamp &&
        this.stampsHandlingEnabled
      )
        status = 'statusUncalledWithStamp';
      else if (
        this.value.attended &&
        !this.value.notPresent &&
        !this.value.finalized
      )
        status = 'statusCalled';
      else if (
        this.value.attended &&
        this.value.finalized &&
        !this.value.notPresent
      )
        status = 'statusAttended';
      return this.$t(status);
    },
  },
  methods: {
    specialField2(value) {
      return !!value && value.trim() != '1/1/0001' ? value : '-'
    },
    handleDocumentsRequest() {
      if (!this.requestDocumentsDisabled) {
        this.requestDocumentsDisabled = true;

        setTimeout(() => {
          this.requestDocumentsDisabled = false;
        }, 30000);

        this.$emit('request-documents');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu {
  z-index: 10;
}

.chip {
  padding: 10px 15px 5px 14px;
  border: 1px solid #d0cccc;
  border-radius: 4px;
  opacity: 1;
  cursor: default;
  &.blue {
    background-color: #F2F7FF;
    border: 1px solid #92BBF5;
  }
}

.folder-icon {
  color: #92BBF5; font-size: 23px;
}

.card.search-result {
  border: 0 !important;
  border-radius: 12px !important;
  border-left: 35px solid transparent !important;
  .action-options {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .turn-icons {
    position: absolute;
    right: 10px;
    top: 60px;
  }
  .small-turn-icon {

  }
  .button-expand {
    width: 35px;
    height: 100%;
    background: transparent;
    position: absolute;
    left: -35px;
    top: 0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: 10px 0px 20px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 18px;
    }
  }
  .cell {
    label,
    span {
      font-size: 14px !important;
      color: #1c1d21 !important;
    }
    span,
    .comments {
      font-weight: normal;
    }
    i {
      font-size: 20px;
      margin-right: 8px;
      color: #707070;
    }
  }
  .minute-box {
    padding: 7px 25px;
    border-radius: 8px;
    &.green {
      background-color: #d9f5d7;
      color: #198e0f !important;
    }
    &.red {
      background-color: #fde7e7;
      color: #d53030 !important;
    }
  }
  &.search-result-red {
    background-color: #fdf5f5;
    .button-expand {
      background-color: #fdf5f5;
      i {
        color: #fad4d4;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #fad4d4 !important;
    }
    &.expand {
      .button-expand {
        background-color: #fad4d4;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-red {
    background-color: #fdf5f5;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.yellow {
    background-color: #fff7e2;
    .button-expand {
      background-color: #fff7e2;
      i {
        color: #f8e1a2;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #f8e1a2 !important;
    }
    &.expand {
      .button-expand {
        background-color: #f8e1a2;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-yellow {
    background-color: #fff7e2;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.grey {
    background-color: #f8f8f8;
    .button-expand {
      background-color: #f8f8f8;
      i {
        color: #d0cccc;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #d0cccc !important;
    }
    &.expand {
      .button-expand {
        background-color: #d0cccc;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-grey {
    background-color: #f8f8f8;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.lightgreen {
    background-color: #f2fff2;
    .button-expand {
      background-color: #f2fff2;
      i {
        color: #2eb782;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #2eb782 !important;
    }
    &.expand {
      .button-expand {
        background-color: #2db782;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-lightgreen {
    background-color: #f2fff2;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.purple {
    background-color: #e9daf6;
    .button-expand {
      background-color: #e9daf6;
      i {
        color: #a17fc6;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #a17fc6 !important;
    }
    &.expand {
      .button-expand {
        background-color: #a17fc6;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-purple {
    background-color: #e9daf6;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.white {
    border-top: 1px solid #d0cccc !important;
    border-bottom: 1px solid #d0cccc !important;
    border-right: 1px solid #d0cccc !important;
    .button-expand {
      border-left: 1px solid #d0cccc !important;
      i {
        color: #d0cccc;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #f8e1a2 !important;
    }
    &.expand {
      .button-expand {
        background-color: #d0cccc;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-white {
    border: 1px solid #d0cccc !important;
    .box {
      border-radius: 10px;
    }
  }
}
</style>
