<template>
  <div class="row mt-3">
    <div class="col-md-7">
      <button
        class="btn btn-success btn-block"
        @click="showSearch = !showSearch"
      >
        <i class="far fa-search"></i> {{ $t('searchClient') }}
      </button>
    </div>
    <div class="col-md-5 text-md-right">
      <Scanner
        v-if="configuration.scannerActive"
        :message="$t('scanLicenseMessage')"
        :loadingMessage="$t('scanLicenseLoadingMessage')"
        @scanned="licenseScanned"
      />
      <button class="btn btn-info ml-2" @click="showAvailableAppointments()">
        <i class="fal fa-calendar-alt"></i> {{ $t('seeAvailableAppointments') }}
      </button>
    </div>
    <div class="col-md-12">
      <b-collapse id="collapse-4" v-model="showSearch" class="mt-2">
        <b-card>
          <loading
            :active="loadings.search"
            :is-full-page="false"
            color="#F37E00"
          />
          <validation-observer ref="formRef" novalidate>
            <div class="row">
              <div class="col-md-6">
                <base-input
                  v-model="model.appointmentNumber"
                  :placeholder="$t('appointmentNumber')"
                  :disabled="!!model.phoneNumber || !!model.email"
                />
              </div>

              <div class="col-md-6">
                <base-input
                  v-model="model.phoneNumber"
                  :placeholder="$t('phone')"
                  mask-type="Phone"
                  :disabled="!!model.appointmentNumber"
                />
              </div>
              <div class="col-md-8">
                <validation-provider v-slot="{ errors }" rules="email">
                  <base-input
                    v-model="model.email"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                    :placeholder="$t('email')"
                    :disabled="!!model.appointmentNumber"
                  />
                </validation-provider>
              </div>
              <div class="col-md-4">
                <button
                  class="btn btn-primary float-right"
                  @click="onSearch()"
                  :disabled="disabledSearchButton"
                >
                  <i class="far fa-search"></i> {{ $t('search') }}
                </button>
              </div>
            </div>
            <div v-if="companyIdUAGM" class="row">
              <div class="col-md-12">
                <hr />
                <div class="row" style="position: relative;">
                  <div class="col-md-8">
                    <span class="UAGM-id-letter">S</span>
                    <base-input
                      v-model="UAGMId"
                      mask-type="UAGMId"
                      :placeholder="$t('studentId')"
                    />
                  </div>
                  <div class="col-md-4">
                    <button
                      class="btn btn-primary float-right"
                      :disabled="!validateUAGMId"
                      @click="getUAGMStudentInfo()"
                    >
                      <i class="far fa-search"></i> {{ $t('search') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </validation-observer>
        </b-card>
        <hr class="mt-5" />
      </b-collapse>
    </div>
    <custom-modal
      id="turn-available-appointments-modal"
      v-model="availableAppointment.isShow"
      :title="$t('appointmentForToday')"
    >
      <loading
        :active="loadings.appointments"
        :is-full-page="false"
        color="#F37E00"
      />
      <available-appointments
        :values="availableAppointment.results"
        :loading="loadings.appointments"
        @select="availableAppointmentSelected"
      >
      </available-appointments>
    </custom-modal>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import { getProfile as _getProfile } from '@/services/TurnService';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import Scanner from '@/components/Scanner.vue';

import AvailableAppointments from '@/views/turns/components/AvailableAppointments.vue';
import { getAvailableAppointments as _getAvailableAppointments } from '@/services/AppointmentService';
import { getUAGMStudentInfo as _getUAGMStudentInfo } from '@/services/LocationService';
import { modulesName } from '@/store';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'TurnsManagerSearch',
  components: {
    BaseInput,
    CustomModal,
    AvailableAppointments,
    Scanner,
  },
  data() {
    return {
      loadings: {
        search: false,
        appointments: false,
      },
      actionUnsubscribe: null,
      model: {
        email: null,
        phoneNumber: null,
        appointmentNumber: null,
      },
      availableAppointment: {
        isShow: false,
        results: [],
      },
      showSearch: false,
      UAGMId: "",
    };
  },
  mounted() {
    this.actionUnsubscribe = this.$store.subscribeAction((action) => {
      if (
        action.type === `${modulesName.turnManagerModuleName}/cleanTopFilters`
      )
        this.clean();
    });
  },
  computed: {
    ...mapGetters('$_user', ['fullProfile', 'currentActiveLocation']),
    ...mapState(modulesName.turnManagerModuleName, ['configuration']),
    companyId() {
      const max = Math.max(...Object.keys(this.fullProfile.companies));
      return max;
    },
    locationConfigurationId() {
      return this.currentActiveLocation.locationConfigurationId;
    },
    disabledSearchButton() {
      return (
        !this.model.email &&
        !this.model.phoneNumber &&
        !this.model.appointmentNumber
      );
    },
    validateUAGMId() {
      return this.UAGMId && this.UAGMId.length === 8;
    },
    companyIdUAGM() {
      return this.companyId === 18;
    }
  },
  methods: {
    async showAvailableAppointments() {
      this.availableAppointment.isShow = true;
      this.loadings.appointments = true;

      await _getAvailableAppointments(
        this.companyId,
        this.locationConfigurationId,
      )
        .then((response) => {
          this.availableAppointment.results = response.data;
        })
        .catch(() => {
          return this.ShowToast('Error', this.$t('searchNotFound'), 'error');
        })
        .finally(() => (this.loadings.appointments = false));
    },
    async getAvailableAppointment(appointmentNumber = null) {
      this.loadings.search = true;
      await _getAvailableAppointments(
        this.companyId,
        this.locationConfigurationId,
        appointmentNumber,
      )
        .then((response) => {
          if (!response.data || !response.data.length)
            return this.ShowToast('Error', this.$t('searchNotFound'), 'error');

          this.availableAppointmentSelected(response.data[0]);
        })
        .catch(() => {
          return this.ShowToast('Error', this.$t('searchNotFound'), 'error');
        })
        .finally(() => (this.loadings.search = false));
    },
    getProfile() {
      this.loadings.search = true;
      _getProfile(this.model)
        .then((response) => {
          if (!response.data)
            return this.ShowToast('Error', this.$t('searchNotFound'), 'error');
          this.$emit('profile', response.data || {});
          this.showSearch = false;
          this.model = {
            email: null,
            phoneNumber: null,
            appointmentNumber: null,
          };
        })
        .finally(() => (this.loadings.search = false));
    },
    async onSearch() {
      if (!(await this.$refs.formRef.validate())) return;

      if (this.model.appointmentNumber) {
        this.getAvailableAppointment(Number(this.model.appointmentNumber));
      } else {
        this.getProfile();
      }
    },
    clean() {
      this.model = {
        email: null,
        phoneNumber: null,
        appointmentNumber: null,
      };
    },
    availableAppointmentSelected(appointment) {
      this.availableAppointment.isShow = false;

      this.$emit('profile', {
        firstName: appointment.clientFirstName,
        lastName: appointment.clientLastName,
        email: appointment.clientEmail,
        phoneNumber: appointment.clientPhoneNumber,
        carrierId: appointment.carrierId,
        clientAppointmentId: appointment.clientAppointmentId.toString(),
        cited: appointment.appointmentTimeStart,
        serviceId: appointment.serviceTypeId,
        queues: appointment.serviceQueueId
          ? [{ id: appointment.serviceQueueId }]
          : [],
        specialistId: appointment.specialistId,
        specialField1: appointment.specialField1,
        specialField2: appointment.specialField2,
        specialField3: appointment.specialField3,
        comment: appointment.comment,
        specialistUserId: appointment.specialistUserId,
      });
      this.showSearch = false;
      this.model = {
        email: null,
        phoneNumber: null,
        appointmentNumber: null,
      };

      this.availableAppointment.results = [];
    },
    licenseScanned(data) {
      const dcspRegex = /DCS([\s\S]*?)\n/g;
      const dacRegex = /DAC([\s\S]*?)\n/g;
      const daqRegex = /DAQ([\s\S]*?)\n/g;

      const lastName = this.extractData(dcspRegex, data);
      const name = this.extractData(dacRegex, data);
      const license = this.extractData(daqRegex, data);

      this.$emit('profile', {
        firstName: name,
        lastName: lastName,
        specialField1: license,
      });
    },
    extractData(regex, data) {
      const matches = [];
      let match;
      while ((match = regex.exec(data)) !== null) {
        matches.push(match[1]);
      }
      return matches.join(', ');
    },
    getUAGMStudentInfo() {
      this.loadings.search = true;
      const id = `S${this.UAGMId}`;
      _getUAGMStudentInfo(this.locationConfigurationId, id).then(({data}) => {
        this.$emit('profile', {
          firstName: data.name,
          lastName: `${data.firstLastName || ''} ${data.secondLastName || ''}`.trim(),
          email: data.email,
          specialField1: id,
          phoneNumber: data.phone
        });
        this.UAGMId = '';
        this.showSearch = false;

      }).catch(() => this.ShowErrorToast(this.$t('searchNotFound')))
      .finally(() => this.loadings.search = false);
    }
  },
  beforeDestroy() {
    this.actionUnsubscribe();
  },
};
</script>
<style scoped>
.UAGM-id-letter {
  z-index: 2;
  font-size: 18px;
  position: absolute;
  font-weight: bold;
  top: 6px;
  left: 18px;
}
</style>
