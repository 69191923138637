<template>
  <validation-observer
    ref="form"
    novalidate
  >
    <div class="row">
      <loading :active="loading" color="#F37E00" :is-full-page="false" />
      <div class="col-md-12">
        <base-input
          :disabled="true"
          :label="$t('number')"
          :value="model.turnNumber"
        />
      </div>
      <div class="col-md-6">
        <validation-provider
          v-slot="{ errors }"
          rules="max:50"
        >
          <base-input
            v-model="model.clientName"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('name')"
          />
        </validation-provider>
      </div>
      <div class="col-md-6">
        <validation-provider
          v-slot="{ errors }"
          rules="email|max:256"
        >
          <base-input
            v-model="model.clientEmail"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('email')"
          />
        </validation-provider>
      </div>
      <div class="col-md-12">
        <base-input
          :disabled="true"
          :label="$t('appointmentNumber')"
          :value="model.clientAppointmentId"
        />
        <hr />
      </div>
      <div class="col-md-6">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <filter-select
            v-model="model.serviceQueueId"
            :disabled="attended || attending"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            fieldtext="name"
            fieldvalue="id"
            :label="$t('row')"
            :options="collections.serviceQueues"
          />
        </validation-provider>
      </div>
      <div class="col-md-6" v-if="hasConfiguredServices">
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: !attending,
          }"
        >
          <filter-select
            :allow-select-all="false"
            :disabled="attended || attending"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            fieldtext="name"
            fieldvalue="id"
            :is-multiple="!individualServiceCounterEnabled"
            :label="$t('service')"
            :options="collections.services"
            :value="
              individualServiceCounterEnabled
                ? model.serviceTypeId
                : model.serviceTypeIds
            "
            @input="
              model[
                individualServiceCounterEnabled
                  ? 'serviceTypeId'
                  : 'serviceTypeIds'
              ] = $event
            "
          />
        </validation-provider>
      </div>

      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="max:1024">
          <base-input
            v-model="model.comment"
            :disabled="attending"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('comment')"
            :max-length="1024"
            :textarea="true"
            :show-popover-title="model.fullComments != null"
            :show-popover-content="model.fullComments != null"
          >
            <template #popoverContent>
              <div v-if="!model.comments.length" class="row px-3">
                <div class="col-md-12">
                  <b>{{ model.fullComments }}</b>
                </div>
              </div>
              <comment-history
                v-if="model.comments.length"
                v-model="model.comments"
              ></comment-history>
            </template>
          </base-input>
        </validation-provider>
        <hr />
      </div>
      <div class="col-md-6">
        <filter-select
          v-model="model.visitingId"
          :disabled="attended || attending"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('visitTo')"
          :options="collections.attenders"
        />
      </div>
      <div class="col-md-6">
        <filter-select
          v-model="model.languageToAttendId"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('languageToAttend')"
          :options="collections.languages"
        />
      </div>
      <div
        v-if="specialFieldsInput && specialFieldsInput.specialField1Enabled"
        class="col-md-6"
      >
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: specialFieldsInput.specialField1Required && !attended,
            max: 250,
          }"
        >
          <base-input
            v-model="model.specialField1"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            :label="
              specialFieldsInput.specialField1Name || `${$t('specialField')} 1`
            "
            :mask="specialFieldsInput.specialField1Mask"
            :maskType="MaskType.Custom"
          />
        </validation-provider>
      </div>
      <div
        v-if="specialFieldsInput && specialFieldsInput.specialField2Enabled"
        class="col-md-6"
      >
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: specialFieldsInput.specialField2Required && !attended,
            max: 250,
          }"
        >
          <base-input
            v-model="model.specialField2"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            :label="
              specialFieldsInput.specialField2Name || `${$t('specialField')} 2`
            "
            :mask="specialFieldsInput.specialField2Mask"
            :maskType="MaskType.Custom"
          />
        </validation-provider>
      </div>
      <div
        v-if="specialFieldsInput && specialFieldsInput.specialField3Enabled"
        class="col-md-6"
      >
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: specialFieldsInput.specialField3Required && !attended,
          }"
        >
          <filter-select
            v-model="model.specialField3"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            fieldtext="name"
            fieldvalue="id"
            :label="
              specialFieldsInput.specialField3Name || `${$t('specialField')} 3`
            "
            :options="collections.specialField3Options"
          />
        </validation-provider>
      </div>
      <div
        v-if="specialFieldsInput && specialFieldsInput.specialField4Enabled"
        class="col-md-6"
      >
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: specialFieldsInput.specialField4Required && !attended,
            max: 250,
          }"
        >
          <base-input
            v-model="model.specialField4"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            :label="
              specialFieldsInput.specialField4Name || `${$t('specialField')} 4`
            "
            :mask="specialFieldsInput.specialField4Mask"
            :maskType="MaskType.Custom"
          />
        </validation-provider>
      </div>
      <div
        v-if="specialFieldsInput && specialFieldsInput.specialField5Enabled"
        class="col-md-6"
      >
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: specialFieldsInput.specialField5Required && !attended,
            max: 250,
          }"
        >
          <base-input
            v-model="model.specialField5"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            :label="
              specialFieldsInput.specialField5Name || `${$t('specialField')} 5`
            "
            :mask="specialFieldsInput.specialField5Mask"
            :maskType="MaskType.Custom"
          />
        </validation-provider>
      </div>
      <div
        v-if="specialFieldsInput && specialFieldsInput.specialField6Enabled"
        class="col-md-6"
      >
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: specialFieldsInput.specialField6Required && !attended,
            max: 250,
          }"
        >
          <base-input
            v-model="model.specialField6"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            :label="
              specialFieldsInput.specialField6Name || `${$t('specialField')} 6`
            "
            :mask="specialFieldsInput.specialField6Mask"
            :maskType="MaskType.Custom"
          />
        </validation-provider>
      </div>
      <div
        v-if="specialFieldsInput && specialFieldsInput.specialField7Enabled"
        class="col-md-6"
      >
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: specialFieldsInput.specialField7Required && !attended,
          }"
        >
          <base-datetime-picker
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('fieldRequired')"
            format="dd/MMM/yyyy"
            :title="
              specialFieldsInput.specialField7Name || `${$t('specialField')} 7`
            "
            :value="model.specialField7"
            @change="model.specialField7 = $event"
          />
        </validation-provider>
      </div>
      <div class="col-md-6">
        <base-datetime-picker
          :disabled="true"
          :title="$t('appointmentHour')"
          type="time"
          :value="model.cited"
          @change="model.cited = $event"
        />
      </div>
      <div class="col-md-6">
        <filter-select
          v-model="model.attendedByPhoneId"
          :clearable="false"
          :disabled="attended || attending"
          fieldtext="text"
          fieldvalue="value"
          :label="$t('attendByPhone')"
          :options="collections.confirmations"
          translate
        />
      </div>
      <div class="col-md-6">
        <filter-select
          v-model="model.isBlind"
          :clearable="false"
          :disabled="attended || attending"
          fieldtext="text"
          fieldvalue="value"
          :label="$t('isBlind')"
          :options="collections.confirmations"
          translate
        />
      </div>
      <div v-if="preferentialTurn" class="col-md-6">
        <filter-select
          v-model="model.isPreferential"
          :clearable="false"
          fieldtext="text"
          fieldvalue="value"
          :label="$t('preferentialTurn')"
          :options="collections.confirmations"
          translate
        />
      </div>
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-md-6">
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: (!!model.carrierId || clientPhoneRequired) && !attended,
          }"
        >
          <base-input
            v-model="model.clientCel"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('phoneRequired')"
            :label="$t('phone')"
            mask-type="Phone"
          />
        </validation-provider>
      </div>
      <div class="col-md-6">
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: (!!model.clientCel || clientPhoneRequired) && !attended,
          }"
        >
          <filter-select
            v-model="model.carrierId"
            :disabled="attended"
            :error="errors[0]"
            :error-msg="$t('mustSelectOne')"
            fieldtext="text"
            fieldvalue="value"
            :label="$t('provider')"
            :options="collections.carriers"
          />
        </validation-provider>
      </div>
      <div class="col-md-12 text-right">
        <base-filled-button
          class="mr-0"
          icon-class="far fa-save"
          :on-click="onSubmit"
          :text="$t('save')"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import {
  getTurnToUpdate as _getTurnToUpdate,
  editTurn as _editTurn,
  getTurn as _getTurn,
} from '@/services/TurnService';
import { getTypesConfiguredForServiceQueues as _getTypesConfiguredForServiceQueues } from '@/services/ServicesService';
import BaseDatetimePicker from '@/components/BaseDatetimePicker';
import BaseInput from '@/components/BaseInput.vue';
import CommentHistory from './CommentHistory.vue';
import { mapGetters, mapActions } from 'vuex';
import { modulesName } from '@/store';
import { getLanguages as _getLanguages } from '@/services/CompanyService';
import MaskType from '@/constants/MaskType';

export default {
  name: 'ManangerTurnForm',
  components: {
    BaseInput,
    BaseDatetimePicker,
    CommentHistory,
  },
  props: {
    turnId: {
      type: Number,
      default: null,
    },
    attended: {
      type: Boolean,
      default: () => false,
    },
    attending: {
      type: Boolean,
      default: () => false,
    },
    preferentialTurn: {
      type: Boolean,
      default: false,
    },
    refreshActiveTurn: {
      type: Boolean,
      default: false,
    },
    waitingRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      collections: {
        confirmations: ConfirmationOptions,
        specialField3Options: [],
        serviceQueues: [],
        attenders: [],
        services: [],
        carriers: [],
      },
      model: {
        turnNumber: null,
        clientAppointmentId: null,
        clientEmail: null,
        clientName: null,
        serviceQueueId: null,
        serviceTypeId: null,
        serviceTypeIds: [],
        comment: null,
        carrierId: null,
        clientCel: null,
        cited: null,
        attendedByPhone: false,
        isBlind: false,
        specialField1: null,
        specialField2: null,
        specialField3: null,
        specialField4: null,
        specialField5: null,
        specialField6: null,
        specialField7: null,
        visitingId: null,
        isPreferential: false,
        languageToAttendId: null,
      },
      individualServiceCounterEnabled: false,
      clientPhoneRequired: false,
      canResetService: false,
      loading: false,
      specialFieldsInput: null,
    };
  },
  async mounted() {
    const turnState = this.$store.state[modulesName.turnManagerModuleName];

    this.collections.serviceQueues = turnState.serviceQueues;
    this.specialFieldsInput = turnState.configuration.specialFields;
    this.collections.specialField3Options = turnState.miscData;
    this.collections.carriers = turnState.carriers;
    this.collections.attenders = turnState.attenders;

    _getLanguages(this.fullProfile.companyId).then(
      ({ data }) => (this.collections.languages = data),
    );

    this.individualServiceCounterEnabled =
      turnState.configuration.individualServiceCounterEnabled;
    this.clientPhoneRequired = turnState.configuration.clientPhoneRequired;
    this.$refs.form.reset();
    await this.loadTurn();
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'currentActiveLocation',
      'fullProfile',
    ]),
    hasConfiguredServices() {
      return this.$store.state[modulesName.turnManagerModuleName].configuration
        .hasConfiguredServices;
    },
    MaskType() {
      return MaskType;
    },
  },
  watch: {
    async 'model.serviceQueueId'() {
      if (this.canResetService) {
        this.model.serviceTypeId = null;
        this.model.serviceTypeIds = [];
      }
      this.canResetService = true;
      await this.loadServiceTypes();
    },
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, ['setActiveTurn']),
    async loadTurn() {
      this.loading = true;
      await _getTurnToUpdate(this.turnId)
        .then(({ data }) => {
          this.model = {
            ...data,
            clientAppointmentId: data?.clientAppointmentId?.toString(),
            turnNumber: data?.turnNumber?.toString(),
            carrierId: data.carrierId !== 0 ? data.carrierId : null,
            specialField3: data.specialField3
              ? Number(data.specialField3)
              : null,
            serviceTypeId:
              this.individualServiceCounterEnabled &&
              data.serviceTypeIds &&
              data.serviceTypeIds.length
                ? data.serviceTypeIds[0]
                : null,
            serviceTypeIds:
              !this.individualServiceCounterEnabled &&
              data.serviceTypeIds &&
              data.serviceTypeIds.length
                ? data.serviceTypeIds
                : [],
            comment: null,
            fullComments: data.comment,
          };
        })
        .finally(() => (this.loading = false));
    },
    async loadServiceTypes() {
      if (!this.hasConfiguredServices) return;

      this.loading = true;
      const payload = {
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        serviceQueueIds: [this.model.serviceQueueId],
      };
      await _getTypesConfiguredForServiceQueues(payload)
        .then((response) => {
          this.collections.services = response.data;
          if (
            !this.collections.services.find(
              (x) => x.id == this.model.serviceTypeId,
            )
          )
            this.model.serviceTypeId = null;

          if (this.collections.services.length == 1)
            this.model.serviceTypeId = this.collections.services[0].id;
        })
        .finally(() => (this.loading = false));
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;
      this.loading = true;
      const payload = {
        ...this.model,
        waitingRoomId: this.waitingRoomId,
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        turnId: this.turnId,
        turnNumber: this.model.turnNumber
          ? Number(this.model.turnNumber)
          : null,
        miscDataId: this.model.specialField3,
        specialField3: this.model.specialField3
          ? this.model.specialField3.toString()
          : null,
        clientAppointmentId: this.model.clientAppointmentId
          ? parseInt(this.model.clientAppointmentId)
          : null,
        serviceTypeIds: this.individualServiceCounterEnabled
          ? [this.model.serviceTypeId]
          : this.model.serviceTypeIds,
      };
      await _editTurn(payload)
        .then(async () => {
          this.$emit('close');
          this.$emit('submitted', payload);
          this.ShowSuccessSaveToast();

          if (this.refreshActiveTurn) {
            await _getTurn(payload.turnId).then((resp) => {
              if (resp.data) this.setActiveTurn(resp.data);
            });
          }
        })
        .catch((error) => {
          this.ShowAlert('Error', error.response.data.message, 'error');
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
