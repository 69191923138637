<template>
  <div :id="id" class="row">
    <div class="col-sm-6 col-md-6 px-0 gauge-container">
      <loading
        :active="loaders.metrics"
        color="#F37E00"
        :is-full-page="false"
      />
      <div class="gauge-panel px-2">
        <percentage-gauge
          id="ggTurnsQuantity"
          :center-data-text="$t('notAttended')"
          :center-data-value="metrics.notAttendedClientsTotal"
          :left-data-text="$t('attendeds')"
          :left-data-value="metrics.attendedClientsTotal"
          :right-data-text="$t('total')"
          :right-data-value="metrics.turnClientsTotal"
          :title="$t('numberOfTurns')"
          :value="attendedClientsPercentage"
          :valueText="`${attendedClientsPercentage}%`"
        />
      </div>
    </div>
    <div class="col-sm-6 col-md-6 px-0 gauge-container">
      <loading
        :active="loaders.metrics"
        color="#F37E00"
        :is-full-page="false"
      />
      <div class="gauge-panel px-2">
        <percentage-gauge
          id="ggTotalTransactions"
          :center-data-text="$t('notAttendedF')"
          :center-data-value="metrics.notAttendedTurnsTotal"
          :left-data-text="$t('attendedsF')"
          :left-data-value="metrics.attendedTurnsTotal"
          :right-data-text="$t('total')"
          :right-data-value="metrics.turnsAbsoluteTotal"
          :title="$t('totalTransactions')"
          :value="attendedTurnsPercentage"
          :valueText="`${attendedTurnsPercentage}%`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { modulesName } from '@/store';
import { mapState } from 'vuex';

import PercentageGauge from '@/components/PercentageGauge';

export default {
  name: 'TurnsManagerGauges',
  components: {
    PercentageGauge,
  },
  props: {
    id: {
      type: String,
      default: () => 'turn-manager-gauges',
    },
  },
  computed: {
    ...mapState(modulesName.turnManagerModuleName, ['metrics', 'loaders']),
    attendedClientsPercentage() {
      return (
        Math.round(
          ((this.metrics.attendedClientsTotal * 100) /
            (this.metrics.turnClientsTotal > 0
              ? this.metrics.turnClientsTotal
              : 1)) *
            10,
        ) / 10
      );
    },
    attendedTurnsPercentage() {
      if (this.metrics.turnsTotal == 0 || this.metrics.turnsAbsoluteTotal == 0)
        return 0;

      return (
        Math.round(
          ((this.metrics.attendedTurnsTotal * 100) /
            (this.metrics.turnsAbsoluteTotal > 0
              ? this.metrics.turnsAbsoluteTotal
              : 1)) *
            10,
        ) / 10
      );
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#turn-manager-gauges {
  width: 100%;
  margin-left: 0px;
}

#turn-manager-gauges .card {
  max-width: 197px;
  max-height: 123px;
}

.gauge-navigation {
  height: 100%;
  max-height: 293px;
  width: 59px;
  color: $color-primary;
  font-size: 32px;
}

.gauge-panel {
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
  min-height: 293px;
}

.gauge-panel > div {
  margin-left: auto;
  margin-right: auto;
}

::v-deep .gauge-container >.header >h4 {
  font-size: 14px !important;
}

@media only screen and (max-width: 769px) {
  #turn-manager-gauges {
    margin-top: 20px;
  }

  #turn-manager-gauges .card {
    margin-left: 0;
    margin-right: 10px !important;
  }

  .gauge-panel {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .gauge-container {
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
