<template>
  <b-card 
    border-variant="lightest" 
    class="py-0 listview card-body-search"    
  >
    <b-row>
      <b-col class="col-12 col-sm-12 col-md-4 col-lg-4">
        <div class="cell">
          <label>{{ $t('dateTime') }}: </label>
          <span>{{ parseDateTime(value.createdDate) }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('locationName') }}: </label>
          <span>{{ value.locationName || '-' }}</span>
        </div>
      </b-col>
      <b-col class="col-12 col-sm-12 col-md-4 col-lg-4">
        <div class="cell">
          <label>{{ $t('name') }}: </label>
          <span>{{ value.clientName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('service') }}: </label>
          <span>{{ value.servicioDesc || '-' }}</span>
        </div>
      </b-col>
      <b-col class="col-12 col-sm-12 col-md-4 col-lg-4">
        <div class="cell">
          <label>{{ $t('userName') }}: </label>
          <span>{{ value.calledBy || '-' }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="cell">
          <label>{{ $t('comment') }}: </label>
          <span>{{ value.comment || '-' }}</span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'TransferTrackerSearchResult',
  components: {
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    parseDateTime(value) {
      return this.$moment(
          value
        ).format('MM/DD/yyyy hh:mm A');
    }
  },
};
</script>

<style lang="scss" scoped>
.listview .cell {
  clear: left;
}

.listview .cell label {
  float: left;
  font-weight: bold;
}

.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}

.card-body-search .card-body {
  padding: 0.35rem !important;
}
</style>