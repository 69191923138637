<template>
  <custom-modal
    size="md"
    :value="value"
    :hideHeaderClose="true"
    :no-close-on-back-drop="true"
    :title="$t('lookForSpecialist')"
  >
  <loading :active="searching" color="#F37E00" :is-full-page="false" />
    <div class="row">
      <div class="col-md-12">
        <filter-select
          v-model="specialtyId"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('specialty')"
          :options="specialties"
        />
      </div>
      <div class="col-md-12">
        <b-button :disabled="!specialtyId" block variant="outline-primary" @click="onSearch()">
          {{ $t('lookForSpecialist') }}
        </b-button>
      </div>
      <div class="col-md-12 mt-2">
        <b-button variant="danger" block @click="$emit('input', false)">
          {{ $t('cancel') }}
        </b-button>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import { mapGetters, mapState } from 'vuex';
import { modulesName } from '@/store';
import { getSpecialField2 as _getSpecialField2 } from '@/services/CompanyService';

import { COMMUNICATION } from '@/config';

export default {
  name: 'ManagerSearchSpecialistModal',
  components: {
    CustomModal,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      specialties: [],
      searching: false,
      specialtyId: null,
      approvalSpecialistTimeoutTimer: null,
    };
  },
  async mounted() {
    _getSpecialField2(this.fullProfile.companyId).then(({ data }) => {
      this.specialties = data;
    });
  },
  computed: {
    ...mapState(modulesName.turnManagerModuleName, [
      'activeWaitingRoom'
    ]),
    ...mapState(modulesName.appModuleName, ['hubLoaded']),
    ...mapGetters(modulesName.userModuleName, ['fullProfile']),
  },
  methods: {
    userNotFound() {
      clearTimeout(this.approvalSupervisorTimeoutTimer);
      this.searching = false;
      this.ShowInformationToast(this.$t('specialistNotFound'));
    },
    onSearch() {
      this.searching = true;
      const userId = this.fullProfile?.userId;
      this.$turnHub.$emit(
        'requestSpecialistWaitingRoom',
        userId,
        this.activeWaitingRoom.waitingRoomId,
        this.specialtyId,
      );

      this.approvalSupervisorTimeoutTimer = setTimeout(() => {
        this.userNotFound();
      }, COMMUNICATION.SPECILISTEUSER_ALERT_TIMEOUT);
    },
    subscribeToEvent(connected) {
      if (!connected) {
        if (this.$turnHub) {
          this.$turnHub.$off('specialistFound');
          this.$turnHub.$off('specialistNotFound');
        }
        return;
      }

        this.$turnHub.$off('specialistFound');
        this.$turnHub.$on(
          'specialistFound',
          () => {
            clearTimeout(this.approvalSupervisorTimeoutTimer);
            this.ShowInformationToast(this.$t('specialistFoundTurnTransferred'));
            this.$store.dispatch(
                `${modulesName.turnManagerModuleName}/refreshTurnManager`,
                null,
                { root: true },
              );
          },
        );

        this.$turnHub.$off('specialistNotFound');
        this.$turnHub.$on('specialistNotFound', () => {
          this.userNotFound();
        });
    },
  },
  watch: {
    hubLoaded: {
      immediate: true,
      handler(value) {
        this.subscribeToEvent(value);
      },
    }
  },
  destroyed() {
    this.$turnHub.$off('specialistFound');
    this.$turnHub.$off('specialistNotFound');
  },
};
</script>

<style lang="scss" scoped></style>
