<template>
  <div class="comment-container">
    <div v-for="(comment, index) in value" :key="index" class="row px-3">
      <div class="col-md-12 p-0 comment-label">
        <span>{{ comment.userName }} - {{ $moment(comment.date).format('MM/DD/yyyy hh:mm:ss A') }}</span>
      </div>
      <div class="col-md-12 p-0 comment-content">
        <p>{{ comment.content }}</p>
      </div>
      <hr class="splitter" v-if="index != value.length - 1" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CommentHistory',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
  .comment-label span { 
    color: gray; 
    font-style: italic; 
    font-size: x-small;
  }
  .comment-container {
    max-height: 500px; 
    overflow: hidden auto;
  }
  .comment-content p {
    text-align: justify!important;
    margin: 5px 0px;
  }

  .splitter {
    border: 1px solid lightgray;
    width: 100%;
    margin: 10px 0;
  }
</style>
