<template>
  <div>
    <div
      ref="actionDropdown"
      class="c-actionDropdown"
    >
      <ul
        v-if="isVisible"
        class="c-dropdown"
        @click="hideDropdown"
      >
        <li><i class="fas fa-pencil-alt" /><span>Editar</span></li>
        <li><i class="fas fa-undo-alt" /><span>reimprimir</span></li>
        <li><i class="fas fa-poll" /><span>Encuestas</span></li>
        <li @click="openModal">
          <i class="fas fa-trash-alt" /><span>Borrar</span>
        </li>
      </ul>
      <div @click="toggleVisibility">
        <slot>...</slot>
      </div>
    </div>
    <base-confirm-modal
      :is-visible="isModalVisible"
      title="¿Está seguro que desea eliminar el turno?"
      @close="isModalVisible = false"
    />
  </div>
</template>

<script>
export default {
  name: 'ActionDropdown',
  data () {
    return {
      isVisible: false,
      isModalVisible: false
    };
  },
  methods: {
    showDropdown () {
      this.isVisible = true;
    },
    hideDropdown () {
      this.isVisible = false;
    },
    toggleVisibility () {
      this.isVisible = !this.isVisible;
    },
    openModal () {
      this.isModalVisible = true;
    }
  },
  beforeCreate () {
    // Hides dropdown when any part that's not the dropdown is clicked
    document.addEventListener('click', (e) => {
      let nextParent = e.target.parentElement;
      let descendantFound = false;
      while (nextParent !== null) {
        if (nextParent.classList.contains('c-actionDropdown') && nextParent === this.$refs.actionDropdown) {
          descendantFound = true;
          break;
        }
        nextParent = nextParent.parentElement;
      }
      if (!descendantFound) {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style scoped lang="scss">

.c-actionDropdown{
  display: inline-flex;
  position: relative;
  ul.c-dropdown{
    width: 127px;
    list-style: none;
    position: absolute;
    bottom:100%;
    left: calc(-127px / 2 + 100% / 2);
    margin: 0 0 10px 0;
    border: 1px solid $color-label-default;
    border-radius: 5px;
    padding: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background: $color-white;
    &:after{
      content: '';
      position: absolute;
      border-top: 10px solid $color-primary;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid transparent;
      z-index: 51;
      width: 10px;
      height: 10px;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: auto;
    }
    li{
      cursor: pointer;
      padding:8px 15px;
      font-family: 'lato',sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      transition: background-color 0.3s;

      &:hover{
        background: $color-primary;

        i,span{
          color:white;
        }

      }

      i{
        font-size: 14px;
        color: $color-primary;
      }

      span{
        margin-left: 10px;
      }
    }

  }
}

</style>
