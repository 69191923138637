<template>
  <b-form-checkbox v-model="isChecked" switch>
    {{ label }}
  </b-form-checkbox>
</template>
<script>
export default {
  name: 'BaseSwitch',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isChecked: this.value || false,
    };
  },
  watch: {
    value(newValue) {
      this.isChecked = newValue;
    },
    isChecked(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>
<style lang="scss">
.custom-control.custom-switch * {
  cursor: pointer;
}
</style>
