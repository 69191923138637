<template>
  <div class="d-inline">
    <button class="btn btn-info" @click="openPopup">
      <i :class="icon"></i>
    </button>
    <transition name="fade">
      <div v-if="showPopup" class="popup text-center" @click="focusInput">
        <p>
          {{ loading ? loadingMessage : message }}
        </p>
        <textarea
          ref="licenseInput"
          type="text"
          class="hidden"
          @input="handleInputThrottled"
        />
        <loading
          class="loading-sm"
          :active="loading"
          :is-full-page="false"
          color="#F37E00"
        />
        <button v-if="!loading" class="btn btn-sm btn-info" @click="closePopup">
          {{ $t('close') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import throttle from 'lodash/throttle';

export default {
  props: {
    icon: {
      type: String,
      default: () => 'fal fa-scanner',
    },
    message: {
      type: String,
      default: () => 'Please, scan the code.',
    },
    loadingMessage: {
      type: String,
      default: () => 'Scanning...',
    },
  },
  data() {
    return {
      showPopup: false,
      loading: false,
    };
  },
  methods: {
    openPopup() {
      this.showPopup = true;
      this.loading = false;

      this.$nextTick(() => {
        this.focusInput();
      });
    },
    closePopup() {
      this.showPopup = false;
      this.loading = false;
    },
    focusInput() {
      this.$refs.licenseInput.focus();
    },
    blurInput() {
      this.$refs.licenseInput.blur();
    },
    handleInputThrottled: throttle(function (event) {
      this.loading = true;
      // Throttle the handleInput method using lodash.throttle.
      this.handleInput(event);
    }, 3000),
    handleInput(event) {
      const scanned = event.target.value;
      if (scanned.length > 2) {
        this.$emit('scanned', scanned);
        this.blurInput();
        this.showPopup = false;
      }
    },
  },
};
</script>

<style>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.hidden {
  position: absolute;
  left: -9999px;
}
.loading-sm svg {
  scale: 0.5;
}

/* Estilo de la transición */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
