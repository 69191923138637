<template>
  <div>
    <loading
      :active="loading"
      color="#F37E00"
      :is-full-page="false"
    />
    <validation-observer ref="newListObserver">
      <div
        v-if="!showNewList && !showStartNumber"
        class="col-12 card p-4"
        style="border: white; display: block"
      >
        <div
          class="row mb-5 list-btn"
          @click="showNewListView"
        >
          <span
            class="fa-stack icon"
            style="vertical-align: top"
          >
            <i class="fal fa-circle fa-stack-2x" />
            <i class="far fa-file-alt fa-stack-1x" />
          </span>
          <h4 class="col align-self-center">
            {{ $t('startAList') }}
          </h4>
        </div>
        <div
          class="row mb-5 list-btn"
          @click="showStartNumberView"
        >
          <span
            class="fa-stack icon"
            style="vertical-align: top"
          >
            <i class="fal fa-circle fa-stack-2x" />
            <i class="fal fa-pencil fa-stack-1x" />
          </span>
          <h4 class="col align-self-center">
            {{ $t('startNumberOrRowContinuation') }}
          </h4>
        </div>
      </div>
      <div v-if="showNewList">
        <div class="col-12">
          <div class="row">
            <div class="col-12 my-4 py-4 rounded list-question">
              <h4 class="text-center list-question">
                {{ $t('unattendedTurnsQuestion') }}
              </h4>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-8">
              {{ $t('keepOnlyUnattended') }}
            </div>
            <div class="col-4">
              <base-filled-button
                class="mr-0"
                :on-click="() => createNewList(true, 0)"
                :text="$t('ok')"
              />
            </div>
          </div>
          <hr />
          <div class="row my-4">
            <div class="col-8">
              {{ $t('moveAllUnattendedTurns') }}
            </div>
            <div class="col-4">
              <base-filled-button
                class="mr-0"
                :on-click="() => createNewList(true, null, true)"
                :text="$t('ok')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <filter-select
                  v-model="newList.serviceQueueId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('row')"
                  :options="serviceQueues"
                />
              </validation-provider>
            </div>
          </div>
          <hr />
          <div class="row my-4">
            <div class="col-8">
              {{ $t('clearTheEntireList') }}
            </div>
            <div class="col-4">
              <base-filled-button
                class="mr-0"
                :on-click="() => createNewList(false, 0)"
                :text="$t('ok')"
              />
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <base-filled-button
                bg-color="#707070"
                class="btn-cancel float-right mt-4 mr-4"
                :on-click="showMainView"
                :text="$t('cancel')"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="showStartNumber">
        <div class="col-10">
          <div class="row">
            <div class="col-12 my-4 py-4 rounded list-question">
              <h4 class="text-center list-question">
                {{ $t('startNumberOrRowContinuation') }}
              </h4>
            </div>
          </div>
          <div class="row mb-4 font-weight-bold justify-content-center">
            <div class="col-4">
              {{ $t('row') }}
            </div>
            <div class="col-6">
              {{ $t('startOfTheList') }}
            </div>
          </div>
          <div
            v-for="serviceQueue in serviceQueues"
            :key="serviceQueue.id"
            class="row mb-3 justify-content-center"
          >
            <div class="col-4">
              {{ serviceQueue.name }}
            </div>
            <div class="col-6">
              <validation-provider v-slot="{ errors }">
                <base-input
                  v-model="serviceQueueStartNumber[serviceQueue.id]"
                  :error="errors[0]"
                  :error-msg="''"
                  fieldvalue="value"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-4">
              <base-filled-button
                bg-color="#707070"
                class="btn-cancel float-right"
                :on-click="showMainView"
                :text="$t('cancel')"
              />
            </div>
            <div class="col-4">
              <base-filled-button
                class="float-right"
                :on-click="onUpdateStartNumber"
                :text="$t('confirm')"
              />
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import {
  validateNewListConfiguration,
  createNewList as _createNewList,
} from '@/services/TurnService';
import { modulesName } from '@/store';
import { mapGetters, mapState, mapActions } from 'vuex';

import localState from '@/mixins/localState';
import StorageKeys from '@/constants/StorageKeys';

export default {
  name: 'TurnsManagerListEditor',
  components: {
    BaseInput,
  },
  mixins: [localState],
  data() {
    return {
      loading: false,
      newList: {
        locationConfigurationId: null,
        keepUnattended: false,
        serviceQueueId: null,
      },
      serviceQueueStartNumber: {},
      showNewList: false,
      showStartNumber: false,
    };
  },
  computed: {
    ...mapGetters('$_user', ['currentActiveLocation', 'profile']),
    ...mapState(modulesName.turnManagerModuleName, [
      'serviceQueues',
    ]),
  },
  async mounted() {
    this.newList.locationConfigurationId = parseInt(
      this.currentActiveLocation.locationConfigurationId,
    );

    // Loading saved queue start numbers, if any
    const queueStartNumbers = this.loadLocalData(StorageKeys.turnStartNumber);
    if(queueStartNumbers) {
      this.serviceQueueStartNumber = queueStartNumbers;
    }
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, ['searchTurns', 'refreshMetrics', 'setActiveTurn', 'setActiveWaitingRoom']),

    async createNewList(keepUnattended, serviceQueueId, validate = false) {

      if (validate) {
        if (!(await this.$refs.newListObserver.validate())) return;
      }

      this.newList.keepUnattended = keepUnattended;
      if (serviceQueueId) {
        this.newList.serviceQueueId = serviceQueueId;
      }

      this.loading = true;

      await _createNewList(this.newList)
        .then(async (resp) => {
          if (resp.data.created) {

            this.refreshMetrics(
              Number(this.currentActiveLocation.locationConfigurationId),
            );

            this.ShowSuccessSaveToast();
            this.showMainView();
            this.searchTurns();
            this.setActiveTurn(null);
            this.setActiveWaitingRoom(null);
          } else {
            this.ShowErrorToast(this.$t('errorCreatingList'));
          }
        })
        .catch((err) => this.ShowErrorToast(err.response.data.message))
        .finally(() => this.loading = false);
    },
    async onUpdateStartNumber() {
      // This information has to be used when calling a client turn
      this.saveLocalData(StorageKeys.turnStartNumber, this.serviceQueueStartNumber);

      this.ShowSuccessSaveToast();
      this.showMainView();
    },

    async showNewListView() {
      this.loading = true;
      await validateNewListConfiguration(
        this.newList.locationConfigurationId,
      ).then((resp) => {
        if (resp.data.autoClearList) {
          this.ShowErrorToast(resp.data.errorMessage);
        } else if (resp.data.hasPendingTurns) {
          this.showNewList = true;
          this.showStartNumber = false;
        } else {
          this.createNewList(false);
        }
        this.loading = false;
      });
    },
    showStartNumberView() {
      this.showNewList = false;
      this.showStartNumber = true;
    },
    showMainView() {
      this.showNewList = false;
      this.showStartNumber = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-stack.icon {
  color: $color-primary;
  font-size: 2em;
}

.list-btn,
.list-btn:visited {
  text-decoration: none;
}

.list-btn:hover {
  color: $color-font-secondary;
  cursor: pointer;
}

div.list-question {
  background-color: $color-app-background;
}
h4.list-question {
  font-weight: 700;
}
</style>
