<template>
  <ul class="c-pagination">
    <li 
      :class="`backward ${currentPage == 1 ? 'disabled' : ''}`"
      @click="OnPageChange(currentPage - 1)"
    >
      <i class="fas fa-chevron-left" />
    </li>
    <li>
      <ul class="c-pages">
        <li 
          v-for="(page, index) in totalPages" 
          :key="index"
          :class="currentPage == index+1 ? 'active' : ''"
          @click="OnPageChange(index+1)"
        >
          {{ index+1 }}
        </li>
      </ul>
    </li>
    <li 
      :class="`forward ${currentPage == totalPages ? 'disabled' : ''}`"
      @click="OnPageChange(currentPage + 1)"
    >
      <i class="fas fa-chevron-right" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Pagination',
  data() {
    return {
    }
  },
  props: {
    rows: {
      type: Number,
      required: true,
      default: () => {}
    },
    perPage: {
      type: Number,
      required: true,
      default: () => {}
    },
    currentPage: {
      type: Number,
      required: true,
      default: 4
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.rows / this.perPage)
    },
  },
  methods: {
    OnPageChange(page){
      if(page == 0 | page > this.totalPages) return;
    }
  },
};
</script>

<style scoped lang="scss">

ul.c-pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-family: 'lato-medium',sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;

  li{
    cursor: pointer;

    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-font-primary;
    &.backward, &.forward{
      border-radius: 4px;
      background-color: $color-app-background;
      border: 1px solid $color-label-default;
      width: 40px;
      height: 40px;
      transition: color 0.3s, background-color 0.3s;
      

      &:hover{
        background-color: $color-primary;
        color: $color-white;
      }

      &.disabled{
        background-color: $color-white;
        border: 1px solid $color-label-default;
        color: #c2c2c2;
        cursor: not-allowed;
        &:hover{
          background-color: $color-white;
          border: 1px solid $color-label-default;
          color: #c2c2c2;
        }
      }

    }
    ul.c-pages{
      border: 1px solid $color-label-default;
      border-radius: 4px;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      background-color: $color-app-background;

      li{
        width: 40px;
        height: 40px;
        transition: color 0.3s, background-color 0.3s;

        &:hover{
          background-color: $color-primary;
          color: $color-white;
        }

        &.active{
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
}
</style>
