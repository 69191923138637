<template>
  <div class="row">
    <template v-if="ShowCommunications">
      <loading :active="loading" color="#F37E00" :is-full-page="false" />
      <div
        v-if="externalChatVisible"
        class="col-md-12 comm-section conversation-control chat"
      >
        <div class="card shadow p-3">
          <div class="header">{{ $t('externalChat') }}</div>
          <div class="col-md-12 d-flex conversation">
            <Chat
              ref="externalChat"
              :room="externalChatRoom"
              class="manager-chat ext"
              v-if="externalChatRoom"
              :disabled="isExternalChatDisabled"
              :hide-user-entries="hideUserExternalChatEntries"
              :main-user-name="mainUserNameExternalChat"
              :language="activeTurn.languageToAttendCode"
              show-presaved-messages
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 comm-section" v-if="isCall">
        <div class="card shadow p-3 conversation-control">
          <div class="header">{{ $t('controlsConversation') }}</div>
          <div class="col-md-12 d-flex text-center text-capitalize mt-2 px-0">
            <span
              class="chip m-1"
              :disabled="ConversationControlReadOnly"
              @click="putCallOnHold"
            >
              <i class="far fa-pause-circle mr-1"></i>
              <span v-if="isHoldCall"> {{ $t('unhold') }}</span>
              <span v-else>
                {{ $t('hold') }}
              </span>
            </span>
            <span class="chip m-1" style="display: none">
              <i class="fas fa-redo-alt mr-1"></i> {{ $t('reCall') }}
            </span>
            <span
              class="chip m-1"
              style="display: none"
              :disabled="ConversationControlReadOnly"
            >
              <i class="fas fa-share-square mr-1"></i>{{ $t('transfer') }}
            </span>
            <span
              class="chip m-1"
              style="display: none"
              :disabled="ConversationControlReadOnly"
            >
              <i class="fas fa-phone-slash mr-1"></i>{{ $t('drop') }}
            </span>
            <span
              class="chip m-1"
              style="display: none"
              :disabled="ConversationControlReadOnly"
            >
              <i class="fas fa-arrow-to-top mr-1"></i> {{ $t('giveControl') }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2 comm-section">
        <div class="card shadow p-3 participant-status">
          <div class="header">{{ $t('participantStatus') }}</div>
          <div
            class="col-md-12 d-flex flex-wrap justify-content-start text-center text-capitalize mt-2"
          >
            <span
              v-b-tooltip
              :title="activeParticipanteName"
              class="chip"
              :class="{ yellow: !isActiveInCall, green: isActiveInCall }"
              :disabled="!EnabledActive"
              :waiting="!EnabledActive"
              @click="reCallActiveParticipant"
            >
              {{ $t(useRepresentative ? 'active' : 'agent') }}
              <span v-if="IsActiveSupervisor" class="text-primary">(s)</span>
            </span>
            <span
              v-if="secondPassiveActive && useRepresentative"
              v-b-tooltip
              :title="secondPassiveActive.fullName"
              class="chip green"
            >
              {{ $t('passive') }}
              <span class="text-primary">(A)</span>
            </span>
            <span
              v-if="useRepresentative"
              v-b-tooltip
              :title="passiveParticipanteName"
              class="chip"
              :class="{ yellow: !isPassiveInCall, green: isPassiveInCall }"
              :disabled="!EnabledPassive"
              :waiting="WaitingPassive"
              @click="reCallPassiveParticipant"
            >
              {{ $t('passive') }}
            </span>
            <span
              class="chip green"
              :disabled="!EnabledCitizen"
              :waiting="WaitingCitizen"
            >
              {{ $t('citizen') }}
            </span>

            <custom-dropdown
              id="cdSupervisor"
              :showOptions="SupervisorExists && IsParticipantActive"
            >
              <span
                v-b-tooltip
                :title="supervisorParticipanteName"
                id="chipSupervisor"
                class="chip"
                :class="{ yellow: !SupervisorExists, green: SupervisorExists }"
                :style="{
                  'pointer-events': EnabledSupervisor ? 'auto' : 'none',
                }"
                :disabled="!EnabledSupervisor"
                :waiting="waitingSupervisor"
                @click="handleSearchSupervisor"
              >
                <i class="far fa-retweet-alt mr-1"></i>{{ $t('supervisor')
                }}<i
                  class="far fa-caret-down ml-1"
                  v-if="SupervisorExists && IsParticipantActive"
                ></i>
              </span>
              <template #options>
                <li>
                  <a type="button" @click="giveAccessSupervisor"
                    ><i class="fas fa-ellipsis-v"></i>&nbsp;&nbsp;Dar control</a
                  >
                </li>
              </template>
            </custom-dropdown>

            <span
              v-b-tooltip
              :title="monitorParticipanteName"
              id="chipMonitor"
              class="chip"
              :class="{ yellow: !MonitorExists, green: MonitorExists }"
              :style="{
                'pointer-events': EnabledMonitor ? 'auto' : 'none',
              }"
              :disabled="!EnabledMonitor"
              :waiting="waitingMonitor"
              @click="handleSearchMonitor"
            >
              <i class="far fa-retweet-alt mr-1"></i>{{ $t('monitor') }}
            </span>

            <span
              v-if="waitingSpecialist || IsParticipantActive"
              class="chip yellow"
              :waiting="waitingSpecialist"
              :disabled="!EnabledMonitor"
              @click="openSearchSpecialistModal()"
            >
              <template v-if="waitingSpecialist">
                {{ $t('lookingForSpecialist') }}
              </template>
              <template v-else>
                <i class="far fa-retweet-alt mr-1"></i
                >{{ $t('lookForSpecialist') }}
              </template>
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="internalChatVisible"
        class="col-md-12 comm-section conversation-control chat"
      >
        <div class="card shadow p-3">
          <div class="header">{{ $t('internalChat') }}</div>
          <div class="col-md-12 d-flex conversation">
            <Chat
              :room="internalChatRoom"
              class="manager-chat int"
              show-mention
              :hide-mentions="hideInternalChatMentions"
              v-if="internalChatRoom"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="col-md-12">
        <div class="alert alert-primary mt-3" role="alert">
          <h4 class="alert-heading">{{ $t('attention') }}!</h4>
          <p>
            {{ $t('notInActiveWaitingRoom') }}
          </p>
          <hr />
          <p class="mb-0">
            {{ $t('startWaitingRoomPrompt') }}
          </p>
        </div>
      </div>
    </template>
    <ManagerSearchSpecialistModal
      v-if="showSearchSpecialistModal"
      v-model="showSearchSpecialistModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { modulesName } from '@/store';
import { COMMUNICATION } from '@/config';
import WaitingRoomStatus from '@/constants/status/WaitingRoomStatus';
import WaitingRoomEntry from '@/constants/status/WaitingRoomEntry';
import ParticipantType from '@/constants/ParticipantType';
import UserActionRequestType from '@/constants/UserActionRequestType';
import ManagerSearchSpecialistModal from './ManagerSearchSpecialistModal.vue';

import {
  getWaitingRoom as _getWaitingRoom,
  updateWaitingRoomStatus as _updateWaitingRoomStatus,
  callParticipantToJoinWaitingRoom as _callParticipantToJoinWaitingRoom,
} from '@/services/WaitingRoomService';

import Chat from '@/components/chat/components/Chat.vue';
import CustomDropdown from '@/components/CustomDropdown.vue';
import RocketChatService from '@/services/RocketChatService';
import {
  getCallStatus as _getCallStatus,
  putCallOnHold as _putCallOnHold,
  checkIfUserHasActiveCall as _checkIfUserHasActiveCall,
} from '@/services/CommunicationService';

import SystemRoles from '@/constants/SystemRoles';

export default {
  name: 'ManagerCommunications',
  components: {
    Chat,
    CustomDropdown,
    ManagerSearchSpecialistModal,
  },
  data() {
    return {
      requestPassiveUserWaitingRoomIntervalId: null,
      rocketChat: RocketChatService,
      internalChatRoom: null,
      externalChatRoom: null,
      holdCall: false,
      waitingSupervisor: false,
      waitingSpecialist: false,
      waitingMonitor: false,
      showDropdownOptions: false,
      showSearchSpecialistModal: false,
      approvalSupervisorTimeoutTimer: null, // Variable to store the timer representing approval timeout supervisor
      approvalMonitorTimeoutTimer: null, // Variable to store the timer representing approval timeout monitor,
      loading: false,
      isPassiveInCall: true,
      isActiveInCall: true,
    };
  },
  props: {
    participantType: {
      type: Number,
      required: true,
      default: () => null,
    },
  },
  computed: {
    ...mapState(modulesName.turnManagerModuleName, [
      'activeWaitingRoom',
      'activeTurn',
      'configuration',
    ]),
    ...mapState(modulesName.appModuleName, ['hubLoaded']),
    ...mapGetters(modulesName.userModuleName, ['profile']),
    useRepresentative() {
      return !!this.configuration.representativeCompanionType;
    },
    ShowCommunications() {
      return (
        this.activeWaitingRoom &&
        this.activeWaitingRoom.waitingRoomStatus !==
          WaitingRoomStatus.SessionFinished &&
        this.activeWaitingRoom.waitingRoomStatus !==
          WaitingRoomStatus.SessionCancelled
      );
    },
    EnabledActive() {
      return this.activeWaitingRoom?.participants?.some(
        (u) => u.participantType === ParticipantType.Active,
      );
    },
    activeParticipanteName() {
      if (!this.EnabledActive) return '';
      return (
        (!this.isActiveInCall ? this.$t('reCallTo') : '') +
        this.activeWaitingRoom?.participants?.find(
          (u) => u.participantType === ParticipantType.Active,
        ).fullName
      );
    },
    EnabledPassive() {
      return this.activeWaitingRoom?.participants?.some(
        (u) => u.participantType === ParticipantType.Passive,
      );
    },
    passiveParticipanteName() {
      if (!this.EnabledPassive) return '';
      return (
        (!this.isPassiveInCall ? this.$t('reCallTo') : '') +
        this.activeWaitingRoom?.participants?.find(
          (u) => u.participantType === ParticipantType.Passive,
        ).fullName
      );
    },
    EnabledCitizen() {
      if (!this.activeWaitingRoom) return false;

      return (
        this.activeWaitingRoom.isCitizenActive &&
        (this.activeWaitingRoom.participants?.some(
          (u) => u.participantType === ParticipantType.Citizen,
        ) ||
          this.activeWaitingRoom.waitingRoomStatus >=
            WaitingRoomStatus.SessionInProgress)
      );
    },
    EnabledSupervisor() {
      const sessionInProgress =
        this.activeWaitingRoom.waitingRoomStatus >=
        WaitingRoomStatus.WaitingCitizen;

      return sessionInProgress;
    },
    SupervisorExists() {
      return this.activeWaitingRoom.participants?.some(
        (u) => u.participantType === ParticipantType.Supervisor,
      );
    },
    supervisorParticipanteName() {
      if (!this.SupervisorExists) return '';
      return this.activeWaitingRoom?.participants?.find(
        (u) => u.participantType === ParticipantType.Supervisor,
      ).fullName;
    },
    EnabledMonitor() {
      const sessionInProgress =
        this.activeWaitingRoom.waitingRoomStatus >=
        WaitingRoomStatus.WaitingCitizen;

      return sessionInProgress;
    },
    MonitorExists() {
      return this.activeWaitingRoom.participants?.some(
        (u) => u.participantType === ParticipantType.Monitor,
      );
    },
    monitorParticipanteName() {
      if (!this.MonitorExists) return '';
      return this.activeWaitingRoom?.participants?.find(
        (u) => u.participantType === ParticipantType.Monitor,
      ).fullName;
    },
    IsParticipantActive() {
      const participantActive = this.activeWaitingRoom?.participants?.some(
        (u) =>
          u.participantType === ParticipantType.Active &&
          u.userId === this.profile?.id,
      );
      return participantActive;
    },
    secondPassiveActive() {
      const participant = this.activeWaitingRoom?.participants?.find(
        (u) =>
          u.participantType === ParticipantType.Passive &&
          u.previousParticipantTypes.includes(ParticipantType.Active),
      );
      return participant;
    },
    IsActiveSupervisor() {
      const isActiveSupervisor = this.activeWaitingRoom?.participants?.some(
        (u) =>
          u.participantType === ParticipantType.Active &&
          u.roleIds?.some((r) => r === SystemRoles.client),
      );
      return isActiveSupervisor;
    },
    WaitingPassive() {
      return (
        this.activeWaitingRoom.waitingRoomStatus ===
        WaitingRoomStatus.WaitingPassive
      );
    },
    WaitingCitizen() {
      return (
        this.activeWaitingRoom.waitingRoomStatus ===
        WaitingRoomStatus.WaitingCitizen
      );
    },
    ConversationControlReadOnly() {
      return this.participantType == ParticipantType.Passive;
    },
    isAttendingTurn() {
      return this.activeTurn != null && !this.activeTurn.finalized;
    },
    isCall() {
      return (
        this.activeTurn != null &&
        this.activeTurn.isCall &&
        this.isAttendingTurn
      );
    },
    internalChatVisible() {
      return (
        this.activeTurn != null &&
        this.configuration.chatEnable &&
        this.activeTurn.internalChatEnabled &&
        this.isAttendingTurn &&
        this.internalChatRoom
      );
    },
    externalChatVisible() {
      return (
        this.activeTurn != null &&
        this.configuration.chatEnable &&
        this.activeTurn.isChat &&
        this.isAttendingTurn &&
        this.externalChatRoom
      );
    },
    isPassive() {
      return this.participantType == ParticipantType.Passive;
    },
    isHoldCall() {
      return this.holdCall;
    },
    isExternalChatDisabled() {
      return (
        this.participantType != ParticipantType.Active ||
        this.activeWaitingRoom.waitingRoomStatus ==
          WaitingRoomStatus.SessionCompleted
      );
    },
    activeParticipant() {
      return (
        this.activeWaitingRoom?.participants?.find(
          (x) => x.participantType === ParticipantType.Active,
        )?.userId || null
      );
    },
    hideUserExternalChatEntries() {
      if (!this.externalChatRoom) return [];
      return this.externalChatRoom.members
        .filter((x) => x.participantType !== ParticipantType.Active)
        .map((x) => x.username);
    },
    hideInternalChatMentions() {
      if (!this.internalChatRoom) return [];
      return this.internalChatRoom.members
        .filter((x) => x.participantType === ParticipantType.Citizen || !x.isActive)
        .map((x) => x.username);
    },
    mainUserNameExternalChat() {
      return (
        this.externalChatRoom.members.find(
          (x) => x.participantType === ParticipantType.Active && x.isActive,
        )?.username || null
      );
    },
  },
  methods: {
    showDropdown() {
      this.showDropdownOptions = true;
    },
    hideDropdown() {
      this.showDropdownOptions = false;
    },
    ...mapActions(modulesName.turnManagerModuleName, [
      'setActiveWaitingRoom',
      'callNextTurn',
      'initCalls',
    ]),
    ...mapActions(modulesName.userModuleName, ['setUserActiveWaitingRoom']),
    loadInternalChatMembers() {
      if (this.internalChatVisible) {
        this.rocketChat.loadRoomMembers(this.internalChatRoom.id);
      }
    },
    loadWaitingRoom() {
      if (!this.activeWaitingRoom) return;
      this.loadInternalChatMembers();
      _getWaitingRoom(this.activeWaitingRoom.waitingRoomId).then(
        async ({ data }) => {
          this.$nextTick(async () => {
            this.setActiveWaitingRoom(data);

            var userActiveWaitingRoom = {
              waitingRoomId: data.waitingRoomId,
              locationConfigurationId: data.locationConfigurationId,
            };

            this.setUserActiveWaitingRoom(userActiveWaitingRoom);
          });
        },
      );

    },
    async onPassiveUserFound(waitingRoomId) {
      // Clear interval RequestPassiveUserWaitingRoom
      clearInterval(this.requestPassiveUserWaitingRoomIntervalId);
      this.requestPassiveUserWaitingRoomIntervalId = null;

      this.loading = true;

      await _getWaitingRoom(waitingRoomId)
        .then(async ({ data }) => {
          this.$nextTick(async () => {
            this.setActiveWaitingRoom(data);
          });

          this.$emit('passive-loaded');
          this.ShowInformationToast(this.$t('companionFound'));

          // If its a chat, we start at once
          // For calls, there must be 2 participants and both of them
          // must accept the calls
          if (data.isChat && this.IsParticipantActive) {
            this.callNextTurn();
          }

          if (data.isCall && this.IsParticipantActive) {
            this.initCalls();
          }
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onSpecialistUserFound(waitingRoomId) {
      if (this.IsParticipantActive) return;
      await _getWaitingRoom(waitingRoomId)
        .then(async ({ data }) => {
          this.$nextTick(async () => {
            this.setActiveWaitingRoom(data);
          });

          this.waitingSpecialist = false;
          this.ShowInformationToast(this.$t('specialistFoundTurnTransferred'));
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
        });
    },
    async onSupervisorUserFound(waitingRoomId) {
      clearTimeout(this.approvalSupervisorTimeoutTimer);

      await _getWaitingRoom(waitingRoomId)
        .then(async ({ data }) => {
          this.$nextTick(async () => {
            this.setActiveWaitingRoom(data);
          });

          this.waitingSupervisor = false;
          this.ShowInformationToast(this.$t('supervisorUserFound'));
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
        });
    },
    async onMonitorUserFound(waitingRoomId) {
      clearTimeout(this.approvalMonitorTimeoutTimer);

      await _getWaitingRoom(waitingRoomId)
        .then(async ({ data }) => {
          this.$nextTick(async () => {
            this.setActiveWaitingRoom(data);
          });

          this.waitingMonitor = false;
          this.ShowInformationToast(this.$t('monitorUserFound'));
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
        });
    },
    onPassiveUserNotFound() {
      this.ShowInformationToast(this.$t('lookingForAnotherCompanion'));
    },
    onSupervisorUserNotFound() {
      clearTimeout(this.approvalSupervisorTimeoutTimer);

      this.waitingSupervisor = false;
      this.ShowInformationToast(this.$t('supervisorUserNotFound'));
    },
    onSpecialistUserNotFound() {
      if (this.IsParticipantActive) return;
      this.waitingSpecialist = false;
      this.ShowInformationToast(this.$t('specialistNotFound'));
    },
    onMonitorUserNotFound() {
      clearTimeout(this.approvalMonitorTimeoutTimer);

      this.waitingMonitor = false;
      this.ShowInformationToast(this.$t('monitorUserNotFound'));
    },
    invokeRequestPassiveUserWaitingRoom() {
      if (!this.activeWaitingRoom) return;
      const userId = this.profile?.id;
      this.$turnHub.$emit(
        'requestUserWaitingRoom',
        userId,
        this.activeWaitingRoom.waitingRoomId,
        ParticipantType.Passive,
      );
    },
    async putCallOnHold() {
      if (!this.activeWaitingRoom) return;

      this.loading = true;

      await _putCallOnHold(this.activeWaitingRoom.waitingRoomId)
        .then(async ({ data }) => {
          this.holdCall = data.onHold;

          this.activeWaitingRoom.channels.forEach((channel) => {
            if (channel.channelType == 'PhoneCall')
              channel.onHold = data.onHold;
          });

          this.ShowSuccessToast(this.$t('operationCompleted'));
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async reCallPassiveParticipant() {
      if (!this.isPassiveInCall) {
        //this.loading = true;
        if (!this.activeWaitingRoom) return;
        await _callParticipantToJoinWaitingRoom(
          this.activeWaitingRoom?.waitingRoomId,
          true,
        );
      }
    },
    async reCallActiveParticipant() {
      if (!this.isActiveInCall) {
        //this.loading = true;
        if (!this.activeWaitingRoom) return;
        await _callParticipantToJoinWaitingRoom(
          this.activeWaitingRoom?.waitingRoomId,
          false,
        );
      }
    },
    async loadChatRooms(waitingRoom) {
      const isValid =
        waitingRoom &&
        ![
          WaitingRoomStatus.SessionFinished,
          WaitingRoomStatus.SessionCancelled,
        ].includes(waitingRoom.waitingRoomStatus);
      if (
        isValid &&
        waitingRoom.waitingRoomStatus > WaitingRoomStatus.WaitingCitizen
      ) {
        if (
          this.externalChatRoom == undefined ||
          this.externalChatRoom == null
        ) {
          const externalChannel = this.activeWaitingRoom?.channels?.find(
            (c) => c.isExternalChat,
          );

          if (externalChannel) {
            await this.rocketChat.loadRoom(
              externalChannel.communicationRoomId,
              { waitingRoomId: waitingRoom.waitingRoomId },
            );

            const foundExternalChatRoom = this.rocketChat.rooms.find(
              (x) => x.id == externalChannel.communicationRoomId,
            );

            if (foundExternalChatRoom != undefined) {
              this.externalChatRoom = foundExternalChatRoom;
            }
          }
        }
      }
      if (
        isValid &&
        waitingRoom.waitingRoomStatus >= WaitingRoomStatus.WaitingCitizen
      ) {
        if (
          this.internalChatRoom == undefined ||
          this.internalChatRoom == null
        ) {
          const internalChannel = this.activeWaitingRoom?.channels?.find(
            (c) => c.isInternalChat,
          );

          if (internalChannel) {
            await this.rocketChat.loadRoom(
              internalChannel.communicationRoomId,
              { waitingRoomId: waitingRoom.waitingRoomId },
            );

            const foundInternalChatRoom = this.rocketChat.rooms.find(
              (x) => x.id == internalChannel.communicationRoomId,
            );

            if (foundInternalChatRoom != undefined) {
              this.internalChatRoom = foundInternalChatRoom;
            }
          }
        }
      }
    },
    handleSearchSupervisor() {
      if (this.SupervisorExists || this.waitingSupervisor) return;
      this.waitingSupervisor = true;

      const userId = this.profile?.id;
      this.$turnHub.$emit(
        'requestUserWaitingRoom',
        userId,
        this.activeWaitingRoom.waitingRoomId,
        ParticipantType.Supervisor,
      );

      this.approvalSupervisorTimeoutTimer = setTimeout(() => {
        this.onSupervisorUserNotFound();
      }, COMMUNICATION.SPECILISTEUSER_REQUEST_TIMEOUT);
    },
    openSearchSpecialistModal() {
      if (!this.IsParticipantActive) return;
      this.showSearchSpecialistModal = true;
    },
    handleSearchMonitor() {
      if (this.MonitorExists || this.waitingMonitor) return;
      this.waitingMonitor = true;

      const userId = this.profile?.id;
      this.$turnHub.$emit(
        'requestUserWaitingRoom',
        userId,
        this.activeWaitingRoom.waitingRoomId,
        ParticipantType.Monitor,
      );

      this.approvalMonitorTimeoutTimer = setTimeout(() => {
        this.onMonitorUserNotFound();
      }, COMMUNICATION.SPECILISTEUSER_REQUEST_TIMEOUT);
    },
    giveAccessSupervisor() {
      const supervisor = this.activeWaitingRoom?.participants?.find(
        (u) => u.participantType === ParticipantType.Supervisor,
      );

      this.$turnHub.$emit(
        'userActionRequest',
        this.activeWaitingRoom.waitingRoomId,
        supervisor.userId,
        UserActionRequestType.GrantControl,
      );
    },
    subscribeToEvent(connected) {
      if (!connected) {
        if (this.$turnHub) {
          this.$turnHub.$off('userFound');
          this.$turnHub.$off('userNotFound');
          this.$turnHub.$off('turnActionPerformed');
          this.$turnHub.$off('onWaitingRoomStatusChanged');
          this.$turnHub.$off('onWaitingRoomEntryChanged');
          this.$turnHub.$off('onUserActionAccepted');
          this.$turnHub.$off('onUserActionRejected');
          this.$turnHub.$off('onChangeParticipantCallState');

          if (!this.IsParticipantActive) {
            this.$turnHub.$off('searchingSpecialist');
            this.$turnHub.$off('specialistFound');
            this.$turnHub.$off('specialistNotFound');
          }
        }
        return;
      }

      this.$turnHub.$off('userFound');
      this.$turnHub.$on(
        'userFound',
        (waitingRoomId, userId, participantType) => {
          if (participantType == ParticipantType.Passive) {
            this.onPassiveUserFound(waitingRoomId, userId);
          } else if (participantType == ParticipantType.Supervisor) {
            this.onSupervisorUserFound(waitingRoomId, userId);
          } else if (participantType == ParticipantType.Monitor) {
            this.onMonitorUserFound(waitingRoomId, userId);
          }
          this.loadInternalChatMembers();
        },
      );

      this.$turnHub.$off('userNotFound');
      this.$turnHub.$on('userNotFound', (waitingRoomId, participantType) => {
        if (this.activeWaitingRoom?.waitingRoomId != waitingRoomId) return;
        if (participantType == ParticipantType.Passive) {
          this.onPassiveUserNotFound(waitingRoomId);
        } else if (participantType == ParticipantType.Supervisor) {
          this.onSupervisorUserNotFound(waitingRoomId);
        } else if (participantType == ParticipantType.Monitor) {
          this.onMonitorUserNotFound(waitingRoomId);
        }
      });

      if (!this.IsParticipantActive) {
        this.$turnHub.$off('searchingSpecialist');
        this.$turnHub.$on('searchingSpecialist', () => {
          this.waitingSpecialist = true;
          this.ShowInformationToast(this.$t('lookingForSpecialist'));
        });

        this.$turnHub.$off('specialistFound');
        this.$turnHub.$on('specialistFound', (waitingRoomId) => {
          this.onSpecialistUserFound(waitingRoomId);
        });

        this.$turnHub.$off('specialistNotFound');
        this.$turnHub.$on('specialistNotFound', () => {
          this.onSpecialistUserNotFound();
        });
      }

      this.$turnHub.$off('turnActionPerformed');
      this.$turnHub.$on('turnActionPerformed', (_, __, waitingRoom) => {
        if (waitingRoom) {
          this.setActiveWaitingRoom(waitingRoom);
        }
      });

      this.$turnHub.$off('onWaitingRoomStatusChanged');
      this.$turnHub.$on('onWaitingRoomStatusChanged', (status) => {
        if (this.activeWaitingRoom)
          this.setActiveWaitingRoom({
            ...this.activeWaitingRoom,
            waitingRoomStatus: status,
          });
      });

      this.$turnHub.$off('onWaitingRoomEntryChanged');
      this.$turnHub.$on('onWaitingRoomEntryChanged', (status) => {
        if (status == WaitingRoomEntry.MonitorOut)
          this.ShowSuccessToast(this.$t('monitorHasLeftTheRoom'));

        if (status == WaitingRoomEntry.SupervisorOut)
          this.ShowSuccessToast(this.$t('supervisorHasLeftTheRoom'));

        if (status == WaitingRoomEntry.AddedMonitor)
          this.ShowSuccessToast(this.$t('monitorHasEnteredTheRoom'));

        if (status == WaitingRoomEntry.AddedSupervisor)
          this.ShowSuccessToast(this.$t('supervisorHasEnteredTheRoom'));

        this.loadWaitingRoom();
      });

      this.$turnHub.$off('onUserActionAccepted');
      this.$turnHub.$on(
        'onUserActionAccepted',
        async (_, __, userActionRequestType) => {
          if (userActionRequestType == UserActionRequestType.GrantControl) {
            this.ShowInformationToast(this.$t('grantControlToSupervisor'));
            if (!this.isExternalChatDisabled && this.$refs.externalChat) {
              this.$refs.externalChat.onSubmit({
                force: true,
                message: `info-noti-citizen-${this.$t(
                  'supervisorWillAssistingYou',
                  this.activeTurn?.languageToAttendCode || 'es',
                )}`,
              });
            }

            if (this.$route.name === 'turnsManager') {
              this.$store.dispatch(
                `${modulesName.turnManagerModuleName}/refreshTurnManager`,
                null,
                { root: true },
              );
            } else {
              this.$router.push({ name: 'turnsManager' });
            }
          }
        },
      );

      this.$turnHub.$off('onUserActionRejected');
      this.$turnHub.$on(
        'onUserActionRejected',
        (_, __, userActionRequestType) => {
          if (userActionRequestType == UserActionRequestType.GrantControl) {
            this.ShowInformationToast(
              this.$t('rejectControlRequestForSupervisor'),
            );
          }
        },
      );

      this.$turnHub.$off('onChangeParticipantCallState');
      this.$turnHub.$on('onChangeParticipantCallState', (isPassive, inCall) => {
        if (isPassive) {
          this.isPassiveInCall = inCall;
        } else {
          this.isActiveInCall = inCall;
        }
      });
    },
  },
  watch: {
    activeWaitingRoom: {
      immediate: true,
      handler: async function (waitingRoom) {
        if (
          !waitingRoom ||
          (waitingRoom &&
            waitingRoom.waitingRoomStatus >= WaitingRoomStatus.WaitingCitizen)
        ) {
          clearInterval(this.requestPassiveUserWaitingRoomIntervalId);
          this.requestPassiveUserWaitingRoomIntervalId = null;
        }
        if (!waitingRoom) {
          this.externalChatRoom = null;
          this.internalChatRoom = null;
          return;
        }

        this.$turnHub.$emit('joinWaitingRoomGroup', waitingRoom.waitingRoomId);

        if (waitingRoom.waitingRoomStatus == WaitingRoomStatus.Created) {
          var payload = {
            waitingRoomId: Number(waitingRoom.waitingRoomId),
            waitingRoomStatus: WaitingRoomStatus.WaitingPassive,
          };

          this.loading = true;

          await _updateWaitingRoomStatus(payload)
            .then(() => {
              this.setActiveWaitingRoom({
                ...waitingRoom,
                waitingRoomStatus: WaitingRoomStatus.WaitingPassive,
              });
            })
            .catch((error) => {
              this.ShowInformationToast(error.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }

        if (waitingRoom.waitingRoomStatus == WaitingRoomStatus.WaitingPassive) {
          if (this.requestPassiveUserWaitingRoomIntervalId != null) return;

          this.invokeRequestPassiveUserWaitingRoom();

          this.requestPassiveUserWaitingRoomIntervalId = setInterval(
            this.invokeRequestPassiveUserWaitingRoom.bind(this),
            COMMUNICATION.PASSIVEUSER_REQUEST_TIMEOUT,
          );
        }
        this.loadChatRooms(waitingRoom);
      },
    },
    hubLoaded: {
      immediate: true,
      handler: async function (value) {
        this.subscribeToEvent(value);

        if (this.activeWaitingRoom)
          this.$turnHub.$emit(
            'joinWaitingRoomGroup',
            this.activeWaitingRoom.waitingRoomId,
          );
      },
    },
  },
  async created() {
    try {
      await this.rocketChat.login();
    } catch (_) {
      this.ShowErrorToast(this.$t('errorLogginInToChat'));
    }
  },
  async mounted() {
    if (this.activeWaitingRoom && this.isCall) {
      this.loading = true;

      await _getCallStatus(this.activeWaitingRoom.waitingRoomId)
        .then(async ({ data }) => {
          this.holdCall = data.onHold;

          if (this.holdCall) {
            _checkIfUserHasActiveCall();
          }
        })
        .catch((error) => {
          this.ShowInformationToast(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    await this.rocketChat.login().then(() => {
      this.loadChatRooms(this.activeWaitingRoom);
    });
  },
  beforeDestroy() {
    this.$turnHub.$off('userFound');
    this.$turnHub.$off('userNotFound');
    this.$turnHub.$off('turnActionPerformed');
  },
};
</script>
<style lang="scss" scoped>
.header {
  font-size: 16px;
  color: #1c1d21;
  font-weight: bold;
}

.comm-section {
  margin-bottom: 5px;
}

.conversation-control {
  .chip {
    padding: 5px 12px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #093843;
    color: #fff;
  }

  /* Style for span with disabled attribute */
  .chip[disabled] {
    opacity: 0.5;
    background-color: #dddddd !important;
    color: #414141 !important;
    cursor: not-allowed;
  }

  &.chat {
    .header {
      padding-left: 15px;
    }
    .card,
    .conversation {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
    }
  }
}

.participant-status {
  .chip {
    padding: 5px 12px;
    margin: 5px;
    border-radius: 20px;
    opacity: 1;
    background-color: #e2e2e2;
    color: #707070;
    font-weight: bold;
    &.green {
      background-color: #d9f5d7;
      color: #219218;
    }
    &.yellow {
      background-color: #fff7e2;
      color: #c87522 !important;
      cursor: pointer;
    }
    &.purple {
      background-color: #e3e2ff;
      color: #5e8ac9 !important;
    }
    &.blue {
      background-color: #e5f3ff;
      color: #4b788d !important;
    }
  }

  /* Style for span with disabled attribute */
  .chip[disabled] {
    opacity: 0.5;
    background-color: #dddddd !important;
    color: #414141 !important;
    cursor: not-allowed;
  }

  .col-md-12 .chip[waiting] {
    position: relative;
  }

  .col-md-12 .chip[waiting]::after {
    content: '';
    display: inline-flex;
    width: 15px; /* Adjust the width of the loader */
    height: 15px; /* Adjust the height of the loader */
    border: 2px solid #ccc; /* Loader border color */
    border-top: 2px solid #9c9c9c; /* Loader top border color */
    border-radius: 50%;
    margin-left: 5px; /* Adjust the space between the text and the loader */
    animation: spin 1s linear infinite; /* Loader animation */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

::v-deep .manager-chat {
  margin-top: 5px;

  overflow-y: hidden;

  .start-conversation-date {
    margin-bottom: 5px;
  }

  .chat-content {
    min-height: 290px;
    max-height: 290px;
    display: block;
  }

  .chat-content::-webkit-scrollbar {
    width: 10px;
  }

  .chat-content::-webkit-scrollbar-track {
    background: #cccccc;
    border-radius: 0px;
    border: 1px solid lightgray;
  }

  .chat-content::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 0px;
  }

  margin-left: 0px !important;
  &.chat {
    width: 100%;
  }

  &.ext {
    max-height: 410px;
    .chat-content {
      background-color: #fff2ef;
      margin-top: 10px;
    }
    .chat-form {
      background-color: #fff2ef;
      // height: 100px;
    }
  }

  &.int {
    max-height: 350px;
    .chat-content {
      background-color: #ebebeb;
      margin-top: 10px;
    }
  }

  .chat-form {
    bottom: 0;
    height: 70px;
    position: -webkit-sticky;
    position: sticky;
    border-top: 2px #b4b4b4 solid;
  }

  &.ext .chat-typing-members {
    background-color: #fff2ef;
  }

  &.int .chat-form {
    background-color: #ebebeb;
  }
  &.int .chat-typing-members {
    background-color: #ebebeb;
  }

  @media (min-width: 576px) {
    .col-chat-input {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-chat-submit {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  @media (max-width: 576px) {
    .col-chat-input {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-chat-submit {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  @media (min-width: 768px) {
    .col-chat-input {
      flex: 0 0 80%;
      max-width: 80%;
    }

    .col-chat-submit {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  @media (min-width: 992px) {
    .col-chat-input {
      flex: 0 0 80%;
      max-width: 80%;
    }

    .col-chat-submit {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  @media (min-width: 1923px) {
    .col-chat-input {
      flex: 0 0 90%;
      max-width: 90%;
    }

    .col-chat-submit {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }
  @media (min-width: 2250px) {
    .col-chat-input {
      flex: 0 0 90%;
      max-width: 90%;
    }

    .col-chat-submit {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }
}

#cdSupervisor .dropdown-options a {
  display: block;
  padding: 5px 12px;
  color: #3a8134; /* Color de texto similar al del chip */
  font-weight: bold;
  font-size: 12px;
  transition: background-color 0.3s ease;
}
#cdSupervisor .dropdown-options a:hover {
  background-color: #c5ecc2; /* Color de fondo en hover similar al chip verde */
  color: #3a8134; /* Color de texto en hover similar al chip verde */
}

#cdSupervisor .custom-dropdown:hover .dropdown-options {
  display: block;
  opacity: 1;
  transform: scaleY(1);
}
</style>
