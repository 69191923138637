<template>
  <div class="row">
    <div class="col-md-12">
      <small v-if="turnId">{{ `${$t('turn')} ${turnId}` }}</small>
      <div class="mt-2 mb-4 text-center text-uppercase text-primary">
        <h4 class="font-weight-bolder">
          {{ $t('survey') }}
        </h4>
      </div>
    </div>

    <loading
      :active="loading"
      color="#F37E00"
      :is-full-page="false"
      :z-index="50"
    />
    <div class="col-md-12">
      <b-table
        v-if="surveyDetail.length > 0"
        :fields="fields"
        :items="surveyDetail"
        responsive
      ></b-table>

      <div v-if="surveyDetail.length <= 0">
        <span> {{ $t('noSurveyData') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getTurnSurveyDetail as _getTurnSurveyDetail } from '@/services/SurveyService';

export default {
  name: 'SurveyDetail',
  props: {
    turnId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'questionName',
          label: this.$t('surveyQuestion'),
        },
        {
          key: 'value',
          label: this.$t('surveyAnswer'),
        },
      ],
      loading: false,
      surveyDetail: {},
    };
  },
  async mounted() {
    await this.getTurnSurveyDetail();
  },
  methods: {
    async getTurnSurveyDetail() {
      this.loading = true;

      await _getTurnSurveyDetail(this.turnId)
        .then((result) => {
          this.surveyDetail = result.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
