<template>
  <div class="main-content maintenance-container">
    <b-row>
      <b-col>
        <b-form-group>
          <button
            :class="
              'maintenance-actions ' +
                [isListMaintenance ? 'maintenance-actions-selected' : '']
            "
            @click="setListMaintenanceAction()"
          >
            {{ $t('listMaintenance') }}
          </button>
          <button
            :class="
              'maintenance-actions ml-4 ' +
                [isCustomerMaintenance ? 'maintenance-actions-selected' : '']
            "
            @click="setCustomerMaintenanceAction()"
          >
            {{ $t('customerMaintenance') }}
          </button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="isCustomerMaintenance">
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('name')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('simpleLastName')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('email')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('service')">
          <select
            id="status"
            class="form-control"
            name="status"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('row')">
          <select
            id="Row"
            class="form-control"
            name="Row"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('comment')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('visitTo')">
          <select
            id="Row"
            class="form-control"
            name="Row"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('installationNumber')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('caseNumber')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('appointmentNumber')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('cited')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('blind')">
          <select
            id="Row"
            class="form-control"
            name="Row"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('attendByPhone')">
          <select
            id="Row"
            class="form-control"
            name="Row"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('phoneProvider')">
          <select
            id="Row"
            class="form-control"
            name="Row"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        sm="6"
      >
        <b-form-group :label="$t('phoneNotification')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="isListMaintenance"
      class="mt-4"
    >
      <div v-if="!isStartNewList && !isContinueList">
        <b-col cols="12">
          <b-form-group class="maintenance-option-button">
            <img
              class="maintenance-icon-img"
              src="@/assets/edit_button.svg"
              @click="setStartNewList"
            />
            <label
              class="maintenance-option-label"
              @click="setStartNewList"
            >{{ $t('startAList') }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group class="maintenance-option-button">
            <img
              class="maintenance-icon-img"
              src="@/assets/startList_button.svg"
              @click="setContinueList"
            />
            <label
              class="maintenance-option-label"
              @click="setContinueList"
            >{{ $t('startNumberOrRowContinuation') }}</label>
          </b-form-group>
        </b-col>
      </div>
      <div v-if="isStartNewList">
        <b-col
          class="mb-5"
          cols="12"
        >
          <label class="start-list-header">
            {{ $t('unattendedTurnsQuestion') }}
          </label>
        </b-col>
        <b-col
          class="px-0"
          cols="12"
          lg="8"
          offset-lg="2"
        >
          <b-row class="mb-4">
            <b-col
              class="px-0"
              cols="12"
              lg="9"
            >
              <label class="custom-label">
                {{ $t('keepOnlyUnattended') }}</label>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <b-form-group class="text-right">
                <button
                  class="maintenance-actions start-list-option-button primary"
                >
                  OK
                </button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="px-0"
              cols="12"
              lg="9"
            >
              <label class="custom-label">{{
                $t('moveAllUnattendedTurns')
              }}</label>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <b-form-group class="text-right">
                <button
                  class="maintenance-actions start-list-option-button primary"
                >
                  OK
                </button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col
              class="px-0"
              cols="12"
              lg="8"
            >
              <select
                id="status"
                class="form-control"
                name="status"
              >
                <option value="0" />
              </select>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="px-0"
              cols="12"
              lg="6"
            >
              <label class="custom-label">{{ $t('clearTheEntireList') }}</label>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <b-form-group class="text-right">
                <button
                  class="maintenance-actions start-list-option-button primary bg-black"
                >
                  {{ $t('cancel') }}
                </button>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <b-form-group class="text-right">
                <button
                  class="maintenance-actions start-list-option-button primary"
                >
                  OK
                </button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </div>
      <div
        v-if="isContinueList"
        class="container fluid"
      >
        <b-col
          class="mb-5"
          cols="12"
        >
          <label class="start-list-header">
            {{ $t('startNumberOrRowContinuation') }}
          </label>
        </b-col>
        <b-col
          class="px-0"
          cols="12"
          lg="8"
          offset-lg="2"
        >
          <b-row class="mb-4">
            <b-col
              class="px-0"
              cols="12"
              lg="4"
            >
              <label class="custom-label"> {{ $t('row') }}</label>
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <label class="custom-label bold">{{ $t('startOfTheList') }}</label>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col
              class="px-0"
              cols="12"
              lg="4"
            >
              <label class="custom-label">{{ $t('auto') }}</label>
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <input
                class="form-control"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col
              class="px-0"
              cols="12"
              lg="4"
            >
              <label class="custom-label">{{ $t('technicalSupport') }}</label>
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <input
                class="form-control"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col
              class="px-0"
              cols="12"
              lg="4"
            >
              <label class="custom-label">{{ $t('service') }}</label>
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <input
                class="form-control"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group class="text-right">
                <button
                  class="maintenance-actions start-list-option-button primary"
                >
                  {{ $t('continue') }}
                </button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </div>
    </b-row>
    <b-row
      v-if="!isStartNewList && !isContinueList"
      class="maintenance-actions-container"
    >
      <b-col>
        <b-form-group class="text-right">
          <button class="maintenance-actions primary font-medium">
            <i class="fa fa-save mr-2" /> {{ $t('save') }}
          </button>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showLoader: false,
      isCustomerMaintenance: false,
      isListMaintenance: true,
      isStartNewList: false,
      isContinueList: false
    };
  },
  methods: {
    setCustomerMaintenanceAction () {
      this.isListMaintenance = false;
      this.isCustomerMaintenance = true;

      this.isStartNewList = false;
      this.isContinueList = false;
    },
    setListMaintenanceAction () {
      this.isCustomerMaintenance = false;
      this.isListMaintenance = true;

      this.isStartNewList = false;
      this.isContinueList = false;
    },
    setStartNewList () {
      this.isContinueList = false;
      this.isStartNewList = true;
    },
    setContinueList () {
      this.isStartNewList = false;
      this.isContinueList = true;
    }
  }
};
</script>
