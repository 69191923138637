<template>
  <div
    class="card p-4 pb-0"
    style="border: white; display: block; border-radius: 10px"
  >
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li
            v-if="showTurns"
            class="nav-item"
            :class="{ active: currentStep == steps.list }"
          >
            <h4 class="nav-link" @click="currentStep = steps.list">
              {{ $t('turnsList') }}
            </h4>
            <div />
          </li>

          <div v-if="showTurns && showMaintenance" class="vertical" />
          <li
            v-if="showMaintenance"
            class="nav-item"
            :class="{ active: currentStep == steps.clients }"
          >
            <h4 class="nav-link" @click="currentStep = steps.clients">
              {{ $t('clients') }}
            </h4>
            <div />
          </li>

          <div v-if="showBroadcast" class="vertical" />
          <li
            v-if="showBroadcast"
            class="nav-item"
            :class="{ active: currentStep == steps.broadcast }"
          >
            <h4 class="nav-link" @click="currentStep = steps.broadcast">
              {{ $t('broadcast') }}
            </h4>
            <div />
          </li>
          <div v-if="showCommunications" class="vertical" />
          <li
            v-if="showCommunications"
            class="nav-item"
            :class="{ active: currentStep == steps.communications }"
          >
            <h4 class="nav-link" @click="currentStep = steps.communications">
              {{ $t('communications') }}
            </h4>
            <div />
          </li>
        </ul>
      </div>
      <div
        v-if="showTurns"
        v-show="currentStep == steps.list"
        class="col-md-12"
      >
        <ManagerList />
      </div>
      <div
        v-if="showMaintenance"
        v-show="currentStep == steps.clients"
        class="col-md-12"
      >
        <ManagerMaintenance
          :client-profile="clientProfile"
          @on-list-created="$emit('on-list-created')"
        />
      </div>

      <div
        v-if="showBroadcast"
        v-show="currentStep == steps.broadcast"
        class="col-md-12"
      >
        <Broadcast />
      </div>
      <div
        v-if="showCommunications"
        ref="manager-communications-ref"
        v-show="currentStep == steps.communications"
        class="col-md-12"
      >
        <ManagerCommunications
          class="mt-3"
          :participantType="participantType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ManagerList from './ManagerList';
import ManagerMaintenance from './ManagerMaintenance';
import Broadcast from './Broadcast';
import IdentityPermission from '@/constants/IdentityPermission';
import DisplayTurnType from '@/constants/DisplayTurnType';
import ManagerCommunications from './ManagerCommunications.vue';
import ParticipantType from '@/constants/ParticipantType';
import { modulesName } from '@/store';
import { mapState } from 'vuex';

export default {
  name: 'ManagerAdministrator',
  components: {
    ManagerList,
    ManagerMaintenance,
    Broadcast,
    ManagerCommunications,
  },
  props: {
    managerPermissions: {
      type: Object,
      default: () => {},
    },
    clientProfile: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 'LIST',
      steps: {
        list: 'LIST',
        clients: 'CLIENTS',
        communications: 'COMMUNICATIONS',
      },
    };
  },
  mounted() {
    if (this.showCommunications)
      this.currentStep = this.steps.communications;
    else if (this.showTurns)
      this.currentStep = this.steps.list;
    else
      this.currentStep = this.steps.clients
  },
  computed: {
    ...mapState(modulesName.turnManagerModuleName, ['configuration']),
    showMaintenance() {
      return (
        this.userHasPermissions(
          IdentityPermission.turns.access.client.manager,
        ) || this.userHasPermissions(IdentityPermission.turns.access.record)
      );
    },
    showTurns() {
      return (
        this.userHasPermissions(IdentityPermission.turns.access.list.default) &&
        this.managerPermissions &&
        this.managerPermissions.turnDisplayPermission !== DisplayTurnType.None
      );
    },
    showBroadcast() {
      return this.userHasPermissions(IdentityPermission.turns.access.broadcast) && this.configuration && this.configuration.entranceModuleEnabled;
    },
    showCommunications() {
      return this.configuration.communicationModuleEnabled;
    },
    participantType() {
      return ParticipantType.Active;
    },
  },
};
</script>
<style lang="scss" scoped>
.vertical {
  border-right: 1px solid #cccccc;
  height: 30px;
  margin: 10px 20px 0;
}
.nav-item {
  .nav-link {
    font-weight: bold;
    color: #cccccc;
    padding-bottom: 20px;
    font-size: 22px;
    cursor: pointer;
    &:hover {
      border: 0px !important;
    }
  }
  &.active {
    > .nav-link {
      color: #1c1d21;
    }
    > div {
      border: 1px solid #fb8125;
      width: 85%;
      margin: 0 auto;
    }
  }
}
</style>
