<template>
  <b-container
    id="turns-administration-container"
    fluid
  >
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <div class="header-title py-3">
          <h1 class="title">
            {{ $t('turnsManager') }}
          </h1>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col
        class="mb-4"
        cols="12"
        xl="6"
      >
        <turnsMaintenance />
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <turnsManager />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <turnsSearch />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import turnsSearch from './turns/TurnsSearch';
import turnsMaintenance from './turns/TurnsMaintenance';
import turnsManager from './turns/TurnsManager';
export default {
  components: {
    turnsSearch,
    turnsMaintenance,
    turnsManager
  },
  data () {
    return {};
  },
  computed: {},
  mounted () {},
  methods: {}
};
</script>

<style lang="scss">
#turns-administration-container {
  background-color: $color-app-background;

  body {
    font-size: 14px;
    font: normal normal normal 14px/29px Lato;
  }

  .header-title h1.title {
    text-align: left;
    letter-spacing: 0px;
    color: $color-font-primary;
    opacity: 1;
    font: normal normal bold 24px/32px Lato;
  }

  .col-form-label {
    font: normal normal normal 14px/21px Lato Medium !important;
  }

  .main-content {
    background: $color-white 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 23px !important;
  }
  .main-content-title {
    text-align: left;
    font: normal normal bold 20px/32px Lato;
    letter-spacing: 0px;
    color: $color-font-primary;
    opacity: 1;
    padding-bottom: 0.25rem;
  }

  .main-content-title.font-medium {
    font: normal normal normal 20px/32px Lato Medium;
  }

  .main-content-title.font-medium.jumbo {
    font: normal normal normal 72px/32px Lato Medium;
  }

  .main-content-title.font-medium.medium {
    font: normal normal normal 42px/32px Lato Medium;
  }

  .hr-fluid {
    margin-left: -23px;
    margin-right: -23px;
  }
  .title-description {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .filters label {
    font: normal normal normal 14px/21px Lato;
    letter-spacing: 0px;
    color: $color-font-secondary;
    opacity: 1;
  }
  .select-all {
    text-decoration: underline;
  }
  select {
    padding: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  select option {
    color: $color-font-primary;
    opacity: 1;
    margin: 0px;
    padding: 5px;
  }

  select option:selected {
    background-color: $color-primary !important;
    background: $color-primary 0% 0% no-repeat padding-box;
    font: normal normal normal 14px/28px Lato;
    letter-spacing: 0px;
    color: $color-font-primary;
    opacity: 1;
    margin: 0px;
    padding: 5px;
  }

  .custom-table-container {
    // margin-right: -25px;
    // margin-left: -25px;

    .custom-table {
      position: relative;
      font: normal normal bold 14px/17px Lato;

      border-collapse: separate;

      tr:not(.separator) td {
        background: $color-white 0% 0% no-repeat padding-box;
        border-top: 1px solid #edeeef;
        border-bottom: 1px solid #edeeef;
        opacity: 1;
        height: 93px;
      }
      tr:not(.separator) td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-left: 1px solid #edeeef;
        padding-left: 33px;
      }
      tr:not(.separator) td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-right: 1px solid #edeeef;
      }

      tr:not(.separator) th:first-child {
        padding-left: 33px;
      }
      thead {
        background: transparent;

        // background: #fff2e59d 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        height: 45px;
        position: -webkit-sticky;
        position: sticky;
        top: 0px;

        tr {
          th {
            vertical-align: top !important;
            border-top: 0px;
            border-bottom: 0px;
            font: inherit;

            text-align: left;
            letter-spacing: 0px;
            color: $color-font-secondary;
            opacity: 1;

            // margin-left: 59px;
            // padding-right: 80px;
          }
        }
      }

      tbody {
        tr {
          td {
            vertical-align: middle;
            text-align: left;
            letter-spacing: 0px;
            color: $color-font-primary;
            opacity: 1;

            border-top: 0px;

            // margin-left: 59px;
            // padding-right: 80px;
          }
        }

        tr.separator td {
          height: 13px;
        }
      }
    }
  }

  .turns-actions {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: $color-white 0% 0% no-repeat padding-box;
    border: 1px solid #edeeef;
    border-radius: 8px;
    opacity: 1;

    i {
      font-size: 20px;
    }
  }

  .custom-thead-background {
    background: #fff2e59d 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 45px;
    position: absolute;
    top: 0px;
    margin-left: -15px;
  }

  .text-underline {
    text-decoration: underline;
  }

  .primary-color {
    color: #bf5f00;
  }

  .main-color {
    color: $color-primary;
  }

  .waitTimeLabel {
    border-radius: 8px;
    opacity: 1;
    width: 65px;
    height: 38px;
    border: none;

    span {
      font: normal normal bold 14px/17px Lato;
      text-align: left;
      letter-spacing: 0px;
      opacity: 1;
    }
  }

  .font-regular {
    font: normal normal normal 14px/17px Lato;
  }

  .waitTimeLabel-success {
    background: #eefef4 0% 0% no-repeat padding-box;

    span {
      color: #198e0f;
    }
  }

  .waitTimeLabel-danger {
    background: #feeeee 0% 0% no-repeat padding-box;
    span {
      color: #ff2929;
    }
  }

  .maintenance-actions {
    background: $color-app-background 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    height: 40px;
    padding: 8px 27px 8px 27px;

    letter-spacing: 0px;
    color: $color-font-secondary;
    opacity: 1;
    border: 0px;

    font: normal normal bold 12px/17px Lato;
  }

  .maintenance-actions.font-medium {
    font: normal normal normal 14px/29px Lato Medium;

    i {
      font-size: 18px;
    }
  }

  .start-list-option-button {
    font: normal normal normal 14px/17px Lato Medium;
  }

  .maintenance-actions.maintenance-actions-selected {
    background: $color-primary 0% 0% no-repeat padding-box;
    color: $color-white;
  }

  select {
    option {
      font: normal normal normal 14px/17px Lato Medium;
    }
  }
  .primary {
    background: $color-primary 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: $color-white;
    font-size: 14px;
    font-weight: normal;
  }
  .form-control {
    border: 1px solid #eaeaea;
    border-radius: 5px;
  }

  .main-content.maintenance-container {
    min-height: 901px;
  }

  .maintenance-icon-img {
    height: 95px;
    width: 95px;

    cursor: pointer;
  }

  .maintenance-option-label {
    text-align: left;
    font: normal normal bold 20px/32px Lato;
    letter-spacing: 0px;
    color: $color-font-primary;
    opacity: 1;
    margin-left: 20px;

    cursor: pointer;
  }

  .maintenance-actions-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: 27px;
  }

  .start-list-header {
    text-align: center;
    background: $color-app-background 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 28px 86px 34px 86px;
    font: normal normal bold 20px/32px Lato;
    width: 100%;
  }

  .turns-manager-called-row-header {
    text-align: center;
    background: $color-app-background 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 16px 39px 20px 42px;
    font: normal normal bold 20px/32px Lato;
  }

  .start-list-option-button {
    background: $color-primary 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    min-width: 119px;
  }

  .turns-manager-actions {
    text-align: center;
    a.btn {
      img {
        height: 177px;
        width: 177px;
      }
    }
  }

  .turns-manager-actions.medium {
    a.btn {
      img {
        height: 103px;
        width: 103px;
      }
    }
  }
  .custom-label {
    text-align: left;
    font: normal normal normal 14px/17px Lato medium;
    letter-spacing: 0px;
    color: $color-font-secondary !important;
    opacity: 1;
  }

  .custom-label.bold {
    font: normal normal bold 14px/17px Lato;
  }

  .bg-black {
    background: $color-font-primary 0% 0% no-repeat padding-box;
  }

  textarea {
    resize: none;
  }

  .called-turn-header {
    margin-top: -60px;
  }
  @media only screen and (max-width: 414px) {
    .turns-manager-actions.medium a.btn img {
      height: 50px;
      width: 50px;
    }
    .main-content {
      padding: 11px !important;
    }

    .called-turn-header {
      margin-top: inherit;
    }

    .main-content.maintenance-container {
      min-height: inherit;
    }
  }
}
</style>
