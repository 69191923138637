<template>
  <validation-observer
    tag="div"
    class="mt-3"
    ref="askForDocumentIdRef"
    novalidate
  >
    <div class="row">
      <b-col cols="12">
      <div class="mt-2 mb-4 text-center text-uppercase text-primary">
        <h4 class="font-weight-bolder">
          {{ $t('digitalCollection') }}
        </h4>
      </div>
    </b-col>

    <b-col cols="12">
      <div class="text-center text-uppercase"
      >
        {{ $t('wantToCancelTheStamp') }}
      </div>
    </b-col>

    <b-col cols="12">
      <div class="font-weight-bolder text-center text-uppercase"
      >
        {{ documentName }}
      </div>
    </b-col>

    <div class="splitter ml-3 mr-3" />

    <b-col cols="12">
      <div class="text-center text-uppercase"
      >
        {{ $t('documentId') }}
      </div>
    </b-col>

    <b-col cols="12" class="mt-2">
      <validation-provider rules="required">
        <base-input
          v-model="documentId"
          :max-length="100"
        />
        </validation-provider>
      </b-col>
    </div>
    <div class="row">
      <b-col col-sm="6">
        <b-button
          class="float-left text-capitalize w-100"
          variant="secondary"
          @click="$emit('close')"
        >
        {{ $t('no') }}
        </b-button>
      </b-col>
      <b-col col-sm="6">
        <b-button
          class="float-right text-capitalize ml-2 w-100"
          variant="primary"
          @click="onAction"
        >
          {{ $t('yes') }}
        </b-button>
      </b-col>
    </div>
  </validation-observer>
</template>

<script>

import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'AskForDocumentId',
  components: {
    BaseInput,
  },
  props: {
    stampsValidationInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      documentId: null,
      documentName: this.stampsValidationInfo ? this.stampsValidationInfo.documentName : null,
    };
  },
  methods: {
    async onAction() {
      if(await this.$refs.askForDocumentIdRef.validate()) {
        this.$emit('on-action', { userDocumentId:  this.documentId });
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.splitter {
    border: 1px solid lightgray;
    width: 100%;
    margin: 10px 0;
  }

::v-deep input[type="text"] {
  text-align: center;
  font-weight: bold;
}

</style>