<template>
  <div :id="id">
    <div class="row">
      <template v-if="!showCommunications">
        <div class="col-md-12">
          <div class="main-page-container">
            <div class="row">
              <sub-header :title="$t('passiveMode')" />
              <action-circle-button
                v-if="canTakeBreak && !userHasPendingBreak"
                id="btnBreak"
                class="mr-2"
                :disabled="
                  userHasPendingBreakToStart ||
                  (activeBreak && activeBreak.hasAuthorizationPending)
                "
                icon="fal fa-pause"
                size="small"
                :title="$t('takeABreak')"
                @on-click="onTakeABreak()"
              />
            </div>
            <div class="row">
              <div v-if="!showSelectedPassiveUser" class="col-sm-12">
                <div
                  class="alert alert-warning d-flex align-items-center pl-3 pr-3"
                  role="alert"
                >
                  <span class="circle circle-warning">
                    <i class="fal fa-hourglass-end"></i>
                  </span>
                  <div>{{ $t('waitingRequestedForSession') }}</div>
                </div>
              </div>
              <div v-else class="col-sm-12">
                <div
                  class="alert alert-success d-flex flex-column flex-md-row align-items-md-center justify-content-between pl-3 pr-3"
                  role="alert"
                >
                  <div class="d-flex align-items-center">
                    <span class="circle circle-success">
                      <i class="fas fa-check"></i>
                    </span>
                    <div class="ms-2">
                      {{ $t('youSelectedToParticipateSession') }}
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-md-row">
                    <button
                      class="btn btn-success m-1"
                      type="button"
                      @click="onPassiveUserAccepted"
                    >
                      <i class="fal fa-check"></i> {{ $t('confirm') }}
                    </button>
                    <button
                      class="btn btn-danger m-1"
                      type="button"
                      @click="onPassiveUserCanceled"
                    >
                      <i class="fal fa-times-circle"></i> {{ $t('decline') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="showCommunications">
        <div class="col-lg-8">
          <div class="main-page-container">
            <div class="row">
              <sub-header :title="$t('communications')" />
              <action-circle-button
                v-if="canTakeBreak && !userHasPendingBreak"
                id="btnBreak"
                class="mr-2"
                :disabled="
                  userHasPendingBreakToStart ||
                  (activeBreak && activeBreak.hasAuthorizationPending)
                "
                icon="fal fa-pause"
                size="small"
                :title="$t('takeABreak')"
                @on-click="onTakeABreak()"
              />

              <action-circle-button
                v-if="canTakeBreak && userHasPendingBreak"
                id="btnBreakNotification"
                class="mr-2"
                icon="fal fa-walking"
                outline="red"
                size="small"
                @on-click="
                  showBreakNotificationModal = !showBreakNotificationModal
                "
              />
            </div>
            <div class="row">
              <div class="col-md-12">
                <ManagerCommunications
                  :participant-type="participantType"
                  @passive-loaded="onPassiveLoaded()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 turn-manager-row">
          <div v-if="activeTurn && !activeTurn.finalized" class="card pb-4">
            <div class="body pl-2 pr-2">
              <div class="row title mt-3">
                <div id="active-turn-header" class="col-sm-12 mb-3">
                  <h1 id="hTurnNumber">
                    {{ activeTurn.serviceQueueStartingLetter || ''
                    }}{{ activeTurn.turnNumber }}
                  </h1>
                  <h2 id="hClientName">
                    {{ activeTurn.clientName }}
                  </h2>
                </div>
              </div>
              <div id="active-turn-header-metadata" class="row mx-1">
                <div class="col-sm-12 queue-called">
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ $t('rowCall') }}:
                      {{ activeTurn.serviceQueueDescription }}
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 queue-called mt-2">
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ $t('service') }}:
                      {{ this.selectedServiceTypeName() || '-' }}
                    </span>
                  </div>
                </div>

                <div class="col-sm-12 queue-called mt-2">
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ $t('email') }}: {{ activeTurn.clientEmail }}
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 queue-called mt-2">
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ $t('phone') }}: {{ activeTurn.clientCel | telephone }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="getTurnLanguage()"
                  class="col-sm-12 queue-called mt-2"
                >
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ $t('language') }}:
                      {{ $t(this.getTurnLanguage()) || '-' }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    configuration.specialFields.specialField1Enabled &&
                    configuration.specialFields.specialField1ShowMT
                  "
                  class="col-sm-12 col-md-6 special-field"
                >
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ configuration.specialFields.specialField1Name }}:
                      {{ activeTurn.specialField1 || '-' }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    configuration.specialFields.specialField2Enabled &&
                    configuration.specialFields.specialField2ShowMT
                  "
                  class="col-sm-12 col-md-6 special-field"
                >
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ configuration.specialFields.specialField2Name }}:
                      {{ activeTurn.specialField2 || '-' }}
                    </span>
                  </div>
                </div>

                <div
                  v-if="
                    configuration.specialFields.specialField3Enabled &&
                    configuration.specialFields.specialField3ShowMT
                  "
                  class="col-sm-12 col-md-6 special-field"
                >
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ configuration.specialFields.specialField3Name }}:
                      {{ activeTurn.miscDataDescription || '-' }}
                    </span>
                  </div>
                </div>

                <div
                  v-if="
                    configuration.specialFields.specialField5Enabled &&
                    configuration.specialFields.specialField5ShowMT
                  "
                  class="col-sm-12 col-md-6 special-field"
                >
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ configuration.specialFields.specialField5Name }}:
                      {{ activeTurn.specialField5 || '-' }}
                    </span>
                  </div>
                </div>

                <div
                  v-if="
                    configuration.specialFields.specialField6Enabled &&
                    configuration.specialFields.specialField6ShowMT
                  "
                  class="col-sm-12 col-md-6 special-field"
                >
                  <div class="special-field-container">
                    <span class="m-0">
                      {{ configuration.specialFields.specialField6Name }}:
                      {{ activeTurn.specialField6 || '-' }}
                    </span>
                  </div>
                </div>

                <div
                  v-if="
                    configuration.specialFields.specialField7Enabled &&
                    configuration.specialFields.specialField7ShowMT
                  "
                  class="col-sm-12 col-md-6 special-field"
                >
                  <div class="special-field-container">
                    <span v-if="activeTurn.specialField7" class="m-0">
                      {{ configuration.specialFields.specialField7Name }}:
                      {{ activeTurn.specialField7 | date }}
                    </span>
                    <span v-else class="m-0">
                      {{ configuration.specialFields.specialField7Name }}:
                      {{ '-' }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row" v-if="isMonitor">
                <loading
                  :active="loading"
                  color="#F37E00"
                  :is-full-page="false"
                />
                <div class="col-sm-12 mt-4 pl-4 pr-4">
                  <base-input
                    v-model="model.notes"
                    :label="$t('notes')"
                    :max-length="2048"
                    :textarea="true"
                  />
                  <button
                    class="btn btn-sm btn-primary"
                    type="button"
                    :disabled="isContentSaved"
                    @click="onSaveNotes"
                  >
                    <i class="fal fa-save"></i> {{ $t('save') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <b-overlay
            v-if="canLeave"
            :show="leaving"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
          >
            <div
              class="btn btn-outline-danger btn-block mt-3"
              @click="leave()"
              :disabled="leaving"
            >
              {{ $t('leaveTheRoom') }}
            </div>
          </b-overlay>
        </div>

        <custom-modal
          id="popupBreakNotification"
          size="md"
          :title="$t('notification')"
          :value="showBreakNotificationModal"
          @input="showBreakNotificationModal = !showBreakNotificationModal"
        >
          <div class="row">
            <div class="col-sm-12 text-center">
              <label v-html="breakNotificationMessageHtml" />
            </div>
          </div>
        </custom-modal>
      </template>
    </div>

    <break-form ref="break-form" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { modulesName } from '@/store';
import SubHeader from '@/components/SubHeader.vue';
import ManagerCommunications from './ManagerCommunications.vue';
import ParticipantType from '@/constants/ParticipantType';
import SignalTurnActions from '@/constants/SignalTurnActions';
import SystemRoles from '@/constants/SystemRoles';
import {
  exitWaitingRoom as _exitWaitingRoom,
  saveNotes as _saveNotes,
} from '@/services/WaitingRoomService';
import RocketChatService from '@/services/RocketChatService';
import BaseInput from '@/components/BaseInput.vue';

import { muteOrUnmuteCall as _muteOrUnmuteCall } from '@/services/CommunicationService';
import ActionCircleButton from '@/components/ActionCircleButton';
import BreakForm from './BreakForm';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import IdentityPermission from '@/constants/IdentityPermission';
import { COMMUNICATION } from '@/config';

export default {
  name: 'ManagerWaitingRoom',
  components: {
    SubHeader,
    ManagerCommunications,
    BaseInput,
    ActionCircleButton,
    BreakForm,
    CustomModal,
  },
  props: {
    id: {
      type: String,
      default: () => 'manager-waiting-room',
    },
  },
  data() {
    return {
      loading: false,
      showSelectedPassiveUser: false,
      attendingTimeInterval: null,
      leaving: false,
      rocketChat: RocketChatService,
      model: {
        waitingRoomId: 0,
        waitingRoomIdTemp: 0,
        userId: null,
        participantId: null,
        notes: null,
        notesSaved: null,
      },
      onWaitingRoomCallDeclinedUnsubscribe: null,
      onWaitingRoomCallAcceptedUnsubscribe: null,
      showBreakNotificationModal: false,
    };
  },
  computed: {
    ...mapState(modulesName.userModuleName, ['activeBreakAndTurn']),
    ...mapState(modulesName.turnManagerModuleName, [
      'activeTurn',
      'activeBreak',
      'activeWaitingRoom',
      'configuration',
    ]),
    ...mapState(modulesName.appModuleName, ['hubLoaded']),
    ...mapGetters(modulesName.userModuleName, ['profile', 'fullProfile']),
    ...mapGetters(modulesName.turnManagerModuleName, [
      'userHasPendingBreak',
      'userHasPendingBreakToStart',
    ]),
    showCommunications() {
      return this.model.waitingRoomId > 0;
    },
    participantType() {
      return ParticipantType.Passive;
    },
    canLeave() {
      if (!this.activeWaitingRoom) return false;

      const canLeave = !this.activeWaitingRoom?.participants?.some(
        (x) =>
          x.participantType === ParticipantType.Passive &&
          x.userId == this.profile.id,
      );

      return canLeave;
    },
    isMonitor() {
      if (!this.activeWaitingRoom) return false;

      const user = this.activeWaitingRoom?.participants?.find(
        (x) => x.userId == this.profile.id,
      );

      return user?.participantType === ParticipantType.Monitor;
    },
    isContentSaved() {
      return this.model.notes === this.model.notesSaved;
    },
    canTakeBreak() {
      const permission = this.userHasPermissions(
        IdentityPermission.break.check.enabled,
      );

      return (
        permission &&
        (this.configuration.breakModuleEnabled ||
          this.configuration.automaticPullEnabled)
      );
    },
    breakNotificationMessage() {
      if (this.activeBreak)
        return this.$t('breakNotification').replace(
          '{0}',
          this.activeBreak.breakDescription,
        );

      return '';
    },
    breakNotificationMessageHtml() {
      if (this.activeBreak)
        return this.$t('breakNotificationHtml').replace(
          '{0}',
          this.activeBreak.breakDescription,
        );

      return '';
    },
  },
  async mounted() {
    this.onWaitingRoomCallAcceptedUnsubscribe = this.$store.subscribeAction(
      (action) => {
        if (
          action.type ===
          `${modulesName.turnManagerModuleName}/onWaitingRoomCallAccepted`
        ) {
          this.onCallAccepted();
        }

      },
    );

    this.onWaitingRoomCallDeclinedUnsubscribe = this.$store.subscribeAction(
      (action) => {
        if (
          action.type ===
          `${modulesName.turnManagerModuleName}/onWaitingRoomCallDeclined`
        ) {
          this.onCallDeclined();
        }
      },
    );

    if (!this.activeWaitingRoom) {
      await this.checkUserBreak({ turnFinished: true });
    }
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, [
      'setActiveWaitingRoom',
      'setAttendingTime',
      'setSimpleActiveTurn',
      'updateParticipantNotes',
      'checkUserBreak',
    ]),
    ...mapActions(modulesName.userModuleName, ['setUserActiveWaitingRoom']),
    async onCallAccepted() {
      if (this.participantType == ParticipantType.Passive) {
        await _muteOrUnmuteCall(this.activeWaitingRoom.waitingRoomId, {
          mute: true,
          userId: this.profile.id,
        });
      }
    },
    async onCallDeclined() {
      if (this.participantType == ParticipantType.Passive) {
        this.onPassiveUserCanceled();
      }
    },
    async onPassiveUserAccepted() {
      const userId = this.profile.id;
      this.model.waitingRoomId = this.model.waitingRoomIdTemp;
      this.showSelectedPassiveUser = false;

      await this.$turnHub.$emit(
        'joinWaitingRoomGroup',
        this.model.waitingRoomId,
      );
      await this.$turnHub.$emit(
        'userAcceptedWaitingRoom',
        this.model.waitingRoomId,
        userId,
        ParticipantType.Passive,
      );

      this.startTurnActionPerformedHub();
      this.setActiveWaitingRoom({ waitingRoomId: this.model.waitingRoomId });
      this.setUserActiveWaitingRoom({
        waitingRoomId: this.model.waitingRoomId,
      });
    },
    startTurnActionPerformedHub() {
      this.$turnHub.$off('turnActionPerformed');
      this.$turnHub.$on(
        'turnActionPerformed',
        (turnAction, turn, waitingRoom) => {
          if (turn && turnAction == SignalTurnActions.NextTurnCalled) {
            this.setSimpleActiveTurn(turn);
          }
          if (turn && turnAction == SignalTurnActions.TurnUpdated) {
            this.ShowInformationToast(this.$t('turnInformationWasUpdated'));
            this.setSimpleActiveTurn({
              ...this.activeTurn,
              clientEmail: turn.clientEmail,
              clientName: turn.clientName,
              clientCel: turn.clientCel,
              carrierId: turn.carrierId,
              languageToAttendCode: turn.languageToAttendCode,
              miscDataId: turn.miscDataId,
              specialField1: turn.specialField1,
              specialField2: turn.specialField2,
              specialField3: turn.specialField3,
              specialField4: turn.specialField4,
              specialField5: turn.specialField5,
              specialField6: turn.specialField6,
              specialField7: turn.specialField7,
            });
          }

          if (waitingRoom) {
            this.setActiveWaitingRoom(waitingRoom);
            this.setUserActiveWaitingRoom(waitingRoom);
          }
        },
      );
    },
    // Subscribe
    onPassiveUserCanceled() {
      this.$turnHub.$off('waitingRoomFinished');
      this.resetWaitingRoom();
    },
    onSelectedPassiveUser(waitingRoomId) {
      if (this.activeWaitingRoom) return;

      this.model.waitingRoomIdTemp = waitingRoomId;
      this.showSelectedPassiveUser = true;
    },
    async onWaitingRoomFinished() {
      if (this.activeWaitingRoom) {
        const monitor = this.activeWaitingRoom.participants.find(
          (x) => x.participantType === ParticipantType.Monitor,
        );

        // const isAPassiveActive = this.activeWaitingRoom?.participants?.some(
        //   (u) =>
        //     u.participantType === ParticipantType.Passive &&
        //     u.userId === this.profile?.id &&
        //     u.previousParticipantTypes.includes(ParticipantType.Active),
        // );

        if (monitor && monitor.userId == this.profile.id)
          this.$router.push({ name: 'Monitor' });
        else {
          this.$store.dispatch(
            `${modulesName.turnManagerModuleName}/refreshTurnManager`,
            null,
            { root: true },
          );
        }
      }

      await this.resetWaitingRoom();
    },
    async resetWaitingRoom() {
      this.setActiveWaitingRoom(null);
      this.setUserActiveWaitingRoom(null);
      this.setSimpleActiveTurn(null);

      this.model.waitingRoomId = 0;
      this.model.waitingRoomIdTemp = 0;
      this.model.userId = null;
      this.model.participantId = null;
      this.model.notes = null;
      this.model.notesSaved = null;

      if (!this.showSelectedPassiveUser)
        this.ShowInformationToast(this.$t('activeSessionHasEnded'));

      this.showSelectedPassiveUser = false;

      if (await this.checkUserBreak({ turnFinished: true })) {
        this.loading = false;
        return;
      }
    },
    onPassiveLoaded() {
      this.loading = false;
    },
    initAttendingTimer() {
      clearInterval(this.attendingTimeInterval);
      if (this.activeTurn != null && this.activeTurn.finalized == false) {
        this.attendingTimeInterval = setInterval(() => {
          this.setAttendingTime(this.activeTurn.attendingTime + 1);
        }, 1000);
        return;
      }
    },
    async startWaitingRoomFinished() {
      this.$turnHub.$off('waitingRoomFinished');
      this.$turnHub.$on('waitingRoomFinished', async () => {
        await this.onWaitingRoomFinished();
      });
    },
    selectedServiceTypeName() {
      if (this.activeTurn && this.activeTurn.serviceTypes.length) {
        const singleService = this.activeTurn.serviceTypes.find(
          (s) => !s.isServiceFinished,
        );
        return singleService ? singleService.name : null;
      } else {
        return this.selectedServiceTypes && this.selectedServiceTypes.length
          ? this.selectedServiceTypes[0].name
          : null;
      }
    },
    getTurnLanguage() {
      if (this.activeTurn) {
        return this.activeTurn.languageToAttendCode;
      } else {
        return null;
      }
    },
    async leave() {
      this.leaving = true;

      await _exitWaitingRoom(this.model.waitingRoomId, this.profile.id)
        .then(async () => {
          this.setSimpleActiveTurn(null);
          if (this.fullProfile.roleId == SystemRoles.monitor)
            this.$router.push({ name: 'Monitor' });

          if (this.fullProfile.roleId == SystemRoles.client) {
            this.$store.dispatch(
              `${modulesName.turnManagerModuleName}/refreshTurnManager`,
              null,
              { root: true },
            );
          }

          if (this.activeWaitingRoom) {
            const channels = this.activeWaitingRoom.channels;
            const internalChannel = channels.find((c) => c.isInternalChat);
            if (internalChannel?.communicationRoomId)
              this.rocketChat.closeRoomById(
                internalChannel?.communicationRoomId,
              );

            const externalChannel = channels.find((c) => c.isExternalChat);
            if (externalChannel?.communicationRoomId)
              this.rocketChat.closeRoomById(
                externalChannel?.communicationRoomId,
              );
          }

          this.setActiveWaitingRoom(null);
          this.setUserActiveWaitingRoom(null);
        })
        .catch(() => (this.leaving = false));

      if (await this.checkUserBreak({ turnFinished: true })) {
        this.loading = false;
        return;
      }
    },
    async subscribeToEvent(connected) {
      if (this.activeTurn && !this.activeTurn.finalized) return;
      if (!connected) {
        if (this.$turnHub) {
          this.$turnHub.$off('selectedUser');
        }
        return;
      }

      this.$turnHub.$off('selectedUser');
      this.$turnHub.$on(
        'selectedUser',
        async (waitingRoomId, participantType) => {
          if (participantType == ParticipantType.Passive) {
            this.onSelectedPassiveUser(waitingRoomId);
            await this.$turnHub.$emit('joinWaitingRoomGroup', waitingRoomId);
            await this.startWaitingRoomFinished();
          }
        },
      );
    },
    async onSaveNotes() {
      this.loading = true;

      var payload = {
        participantId: this.model.participantId,
        notes: this.model.notes,
      };

      await _saveNotes(payload)
        .then(() => {
          this.model.notesSaved = this.model.notes;

          this.updateParticipantNotes({
            userId: this.profile.id,
            notes: this.model.notes,
          });

          this.ShowSuccessSaveToast();
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        })
        .finally(() => (this.loading = false));
    },
    onTakeABreak() {
      this.$refs['break-form'].show();
    },
  },
  watch: {
    activeWaitingRoom: {
      immediate: true,
      handler: async function (value) {
        const waitingRoom = { ...value };
        this.model.waitingRoomId = waitingRoom.waitingRoomId ?? 0;

        const user = waitingRoom?.participants?.find(
          (x) => x.userId == this.profile.id,
        );

        if (user) {
          this.model.userId = user.userId;
          this.model.participantId = user.participantId;
          this.model.notes = user.notes;
          this.model.notesSaved = user.notes;
        }
        if (this.model.waitingRoomId) {
          await this.$turnHub.$emit(
            'joinWaitingRoomGroup',
            this.model.waitingRoomId,
          );

          await this.startWaitingRoomFinished();
          this.startTurnActionPerformedHub();
        }
      },
    },
    activeTurn: {
      immediate: true,
      handler() {
        this.initAttendingTimer();
      },
    },
    showSelectedPassiveUser: {
      immediate: true,
      handler: async function (value) {
        if (value) {
          setTimeout(() => {
            this.showSelectedPassiveUser = false;
          }, COMMUNICATION.PASSIVEUSER_ALERT_TIMEOUT);
        }
      },
    },
    hubLoaded: {
      immediate: true,
      handler(value) {
        this.subscribeToEvent(value);
      },
    },
    userHasPendingBreak: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue && newValue) {
          this.ShowToast('', this.breakNotificationMessage, 'warning');
        }
      },
    },
  },
  beforeDestroy() {
    this.$turnHub.$off('waitingRoomFinished');
    clearInterval(this.attendingTimeInterval);

    if (this.onWaitingRoomCallAcceptedUnsubscribe)
      this.onWaitingRoomCallAcceptedUnsubscribe();
    if (this.onWaitingRoomCallDeclinedUnsubscribe)
      this.onWaitingRoomCallDeclinedUnsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.circle {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle i {
  font-size: 25px; /* Ajusta el tamaño del icono según sea necesario */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Color blanco */
}

.circle::before,
.circle::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 25px solid transparent;
}

.circle.circle-warning {
  background: #f5da82 0% 0% no-repeat padding-box;
  border: 2px solid #f6c01c;
}
.circle.circle-success {
  background: #79ca74 0% 0% no-repeat padding-box;
  border: 2px solid #25941c;
}

.card {
  border-radius: 10px;
  border: 0;
}
.header i {
  float: right;
}

#manager-waiting-room .container {
  margin-bottom: 2.4rem;
}

#manager-waiting-room h4 {
  font-weight: bold;
  font-size: 20px;
}

#manager-waiting-room .card > hr {
  margin-bottom: 0;
}

#manager-waiting-room .small-actions {
  min-height: 65px;
}

#manager-waiting-room .small-action {
  margin-top: 15px;
  z-index: 2;
}

#manager-waiting-room .body .title {
  text-align: center;
}

#manager-waiting-room .action-button {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#manager-waiting-room .footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  min-height: 309px;
}

#manager-waiting-room .actions {
  margin-top: 5%;
}

#manager-waiting-room .footer.active {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  min-height: 110px;
}

#manager-waiting-room .footer.active .actions {
  margin-top: 21px;
}

#manager-waiting-room .action {
  margin-top: 5%;
}

#active-turn-header-metadata > div span {
  font-size: 14px;
  font-weight: bolder;
  margin: 0 39px;
}

#hTurnNumber {
  font-size: 54px;
  margin: 0;
}

#hClientName,
::v-deep #hClientCel {
  font-size: 32px;
  margin: 0;
}

::v-deep #hClientCel.orange {
  color: $color-primary;
}

::v-deep #hClientCel > div > label {
  font-size: 32px;
}

.special-field {
  margin-top: 10px;
  padding-top: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.queue-called {
  padding-top: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.queue-called .special-field-container {
  margin: 0;
  width: 100%;
}

.special-field.queue-called > div {
  width: 100%;
}

.special-field-container {
  background: $color-app-background 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  height: 100%;
  display: table;
  text-align: center;
  width: 100%;
  padding: 5px 15px;
}

.special-field-container span {
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}

.special-field:nth-child(odd) {
  margin-top: 10px;
}

.special-field:nth-child(even) {
  margin-top: 10px;
}

.special-field-container:nth-child(odd) {
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 769px) {
  #manager-waiting-room {
    margin-top: 15px;
  }

  .metrics-container {
    padding-right: 0;
  }

  .special-field-container {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .manager-waiting-room-row {
    min-height: 634px;
  }

  .manager-waiting-room-row .body {
    min-height: 304px;
  }

  #manager-waiting-room {
    height: 100%;
  }
}
</style>
