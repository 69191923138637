var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mt-2",staticStyle:{"border":"white","display":"block"}},[(_vm.showListMaintenance)?_c('button',{staticClass:"btn mr-3",class:{
          'btn-primary': _vm.option === 'list',
          'btn-secondary': _vm.option !== 'list',
        },on:{"click":function($event){_vm.option = 'list'}}},[_vm._v(" "+_vm._s(_vm.$t('listMaintenance'))+" ")]):_vm._e(),(_vm.showClientMaintenance)?_c('button',{staticClass:"btn",class:{
          'btn-primary': _vm.option === 'client',
          'btn-secondary': _vm.option !== 'client',
        },on:{"click":function($event){_vm.option = 'client'}}},[_vm._v(" "+_vm._s(_vm.$t('customerMaintenance'))+" ")]):_vm._e(),(_vm.showListMaintenance && _vm.option === 'list')?_c('div',[_c('ManagerListEditor')],1):_vm._e(),(_vm.showClientMaintenance)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.option === 'client'),expression:"option === 'client'"}]},[_c('ManagerSearch',{on:{"profile":function($event){_vm.profile = $event}}}),_c('ManagerClient',{attrs:{"value":_vm.profile},on:{"submitted":function($event){return _vm.$emit('submitted')}}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }