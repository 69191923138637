<template>
  <validation-observer ref="break-form" novalidate>
    <custom-modal
      id="break-form-modal"
      ref="break-form-modal"
      :no-close-on-back-drop="true"
      size="md"
      :title="$t('takeABreak')"
    >
      <div class="row">
        <div class="col-md-12">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="model.selectedBreak"
              :error="errors[0]"
              :error-msg="$t('selectMotive')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('breakReason')"
              :options="breaks"
              :return-object="true"
              @input="onBreakChanged($event)"
            />
          </validation-provider>
        </div>
      </div>
      <div
        v-if="model.selectedBreak && model.selectedBreak.commentRequired"
        class="row"
      >
        <div class="col-md-12">
          <validation-provider
            v-slot="{ errors }"
            :rules="model.selectedBreak && rules.comment"
          >
            <base-input
              v-model="model.comment"
              :disabled="!model.selectedBreak"
              :error="errors[0]"
              :error-msg="commentErrorMessage"
              :label="$t('comment')"
              :max-length="rules.comment.max"
              name="comment"
              :textarea="true"
            />
          </validation-provider>
        </div>
      </div>
      <template v-slot:footer>
        <div class="row footer">
          <div class="col-md-12">
            <base-filled-button
              bg-color="#707070"
              class="float-left"
              icon-class="fas fa-ban"
              :on-click="onCancel"
              :text="$t('cancel')"
            />
            <base-filled-button
              class="float-right"
              icon-class="far fa-save"
              :on-click="onSubmit"
              :text="$t('confirm')"
            />
          </div>
        </div>
      </template>
    </custom-modal>
  </validation-observer>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import BaseInput from '@/components/BaseInput.vue';

import { mapActions, mapGetters, mapState } from 'vuex';
import { modulesName } from '@/store';
import IdentityPermission from '@/constants/IdentityPermission';

import { logoutWithRedirect } from '@/core/auth';
import { clearApiCache } from '@/modules/user/api';
import { createBreak as _createBreak } from '@/services/BreakManagerService';

export default {
  name: 'BreakForm',
  components: {
    BaseInput,
    CustomModal,
  },
  props: {},
  data() {
    return {
      model: {
        selectedBreak: null,
        comment: null,
      },
      rules: {
        comment: {
          required: true,
          min: { length: 5 },
          max: { length: 1024 },
        },
      },
    };
  },
  async mounted() {},
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'currentActiveLocation',
      'user',
    ]),
    ...mapState(modulesName.userModuleName, ['activeBreakAndTurn']),
    ...mapState(modulesName.turnManagerModuleName, [
      'activeTurn',
      'breaks',
      'configuration',
    ]),
    commentErrorMessage() {
      if (this.rules.comment.required && !this.model.comment) {
        return this.$t('commentRequired');
      } else if (this.model.comment.length < this.rules.comment.min.length) {
        return this.$t('commentMinLength').replace(
          '{0}',
          this.rules.comment.min.length,
        );
      } else if (this.model.comment.length >= this.rules.comment.max.length) {
        return this.$t('commentMaxLength').replace(
          '{0}',
          this.rules.comment.max.length,
        );
      }

      return null;
    },
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, [
      'checkUserBreak',
      'toggleFullPageLoader',
    ]),
    onBreakChanged(value) {
      this.model.selectedBreak = value;
    },
    async show() {
      this.$refs['break-form-modal'].show();
    },
    async hide() {
      this.model = {
        breakId: null,
        comment: null,
      };

      this.$refs['break-form-modal'].hide();
    },
    async onCancel() {
      this.hide();
    },
    async onSubmit() {
      if (!(await this.$refs['break-form'].validate())) return;

      this.toggleFullPageLoader();

      const isAttendingCustomer =
        (this.activeTurn != null && !this.activeTurn.finalized) ||
        this.activeBreakAndTurn.activeWaitingRoom != null;

      const payload = {
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        breakId: this.model.selectedBreak.id,
        userId: this.user.userId,
        isAttendingCustomer: isAttendingCustomer,
        isAuthorization: this.configuration.breakModuleEnabled
          ? this.configuration.breakAuthorizationEnabled
          : false,
        isPushBreak: this.userHasPermissions(
          IdentityPermission.break.admin.push.enabled,
        ),
        reasonComment: this.model.selectedBreak.commentRequired
          ? this.model.comment
          : null,
      };

      await _createBreak(payload).then(async () => {
        const noActiveTurnOrFinalized =
          !this.activeTurn || this.activeTurn.finalized;
        const breakAuthorizationEnabled =
          this.configuration.breakAuthorizationEnabled;
        const selectedBreakAutoApproved = this.model.selectedBreak.autoApproved;
        const noActiveWaitingRoom =
          this.activeBreakAndTurn.activeWaitingRoom == null;

        if (
          noActiveTurnOrFinalized &&
          noActiveWaitingRoom &&
          ((breakAuthorizationEnabled && selectedBreakAutoApproved) ||
            !breakAuthorizationEnabled)
        ) {
          await clearApiCache();
          logoutWithRedirect();

          return;
        }

        this.hide();

        await this.checkUserBreak({ turnFinished: false });
        this.$emit('on-confirm', this.model);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  & > div {
    padding: 0;
  }

  & button {
    margin: 0;
  }
}
</style>
