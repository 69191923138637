<template>
  <div class="row">
    <div class="col-md-12 px-4 mt-3 mx-1">
      <loading
        :active="loadings.general"
        color="#F37E00"
        :is-full-page="false"
        :z-index="50"
      />
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <base-input v-model="filters.turnNumber" :label="$t('number')" />
            </div>
            <div class="col-md-3">
              <base-input v-model="filters.clientName" :label="$t('name')" />
            </div>
            <div class="col-md-6">
              <filter-select
                v-model="filters.serviceQueueIds"
                :allow-select-all="false"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('row')"
                :options="collections.serviceQueues"
              />
            </div>
            <div class="col-md-3">
              <filter-select
                v-model="filters.status"
                fieldtext="text"
                fieldvalue="value"
                :label="$t('status')"
                :options="status"
              />
            </div>
            <div class="col-md-3">
              <filter-select
                v-model="filters.attenderId"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('visitTo')"
                :options="collections.attenders"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="filters.appointmentNumber"
                :label="$t('appointmentNumber')"
                mask-type="AppointmentId"
                add-mask
              />
            </div>
            <div class="col-md-2 pt-1">
              <button
                class="btn btn-primary mt-4 float-right"
                variant="outline-primary"
                @click="search()"
              >
                <i class="far fa-search" />
              </button>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 d-flex justify-content-center text-center text-capitalize"
        >
          <span
            class="chip"
            :class="{ selected: chipSelected(2) }"
            @click="searchByChip(!chipSelected(2) ? 2 : null)"
          >
            {{ $t('turnUnCalled') }}
          </span>
          <span
            class="chip red"
            :class="{ selected: chipSelected(3) }"
            @click="searchByChip(!chipSelected(3) ? 3 : null)"
          >
            {{ $t('turnMarkedNotPresent') }}
          </span>
          <span
            v-if="isStampsHandlingEnabled"
            class="chip yellow"
            :class="{ selected: chipSelected(4) }"
            @click="searchByChip(!chipSelected(4) ? 4 : null)"
          >
            {{ $t('turnWithStamp') }}
          </span>
          <span
            class="chip grey"
            :class="{ selected: chipSelected(1) }"
            @click="searchByChip(!chipSelected(1) ? 1 : null)"
          >
            {{ $t('turnCalled') }}
          </span>
          <span
            class="chip lightgreen"
            :class="{ selected: chipSelected(5) }"
            @click="searchByChip(!chipSelected(5) ? 5 : null)"
          >
            {{ $t('turnAttended') }}
          </span>
          <span
            v-if="preferentialTurn"
            class="chip purple"
            :class="{ selected: chipSelected(6) }"
            @click="searchByChip(!chipSelected(6) ? 6 : null)"
          >
            {{ $t('preferentialTurn') }}
          </span>
          <span
            v-if="canUploadDocuments"
            class="chip blue"
            :class="{ selected: chipSelected(7) }"
            @click="searchByChip(!chipSelected(7) ? 7 : null)"
          >
            {{ $t('turnWithDocuments') }}
          </span>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              v-for="(item, i) in result.data"
              :key="i"
              class="col-md-12 mt-3"
            >
              <search-result
                :special-fields-input="specialFieldsInput"
                :value="item"
                :waiting-time="waitingTime"
                :index="i"
                :index-to-expand="indexToExpand"
                @call-individual-turn="showTurnStationSelect(item)"
                @close-turn="showCloseTurnConfirmation(item)"
                @edit="openEditTurnModal(item)"
                @print="print(item.id)"
                @remove-attended="removeAttended(item)"
                @remove-turn="removeTurn(item)"
                @show-survey="openSurveyModal(item)"
                @show-documents="openDocumentsModal(item)"
                @expand="indexToExpand = $event"
                @request-documents="onRequestDocuments(item)"
              />
            </div>
            <div v-if="noResultsFound" class="col-md-12 mt-3">
              <CustomNotFound :text="$t('noResultsFound')" />
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3" style="height: 70px">
          <custom-pagination
            v-if="result.count > 0"
            class="my-4"
            :data="result"
            :page-size="filters.pageSize"
            @pagination-go-page="getTurns($event)"
            @pagination-rows-per-page="getTurns(1, $event)"
          />
        </div>
      </div>
    </div>
    <DeleteModal
      v-model="showRemoveTurnModal"
      error-message="turnNumberIsNotCorrect"
      :item="selectedTurn"
      :label="$t('number')"
      :loading="loadings.removeTurn"
      name="number"
      @on-confirm="onRemoveTurn()"
    />
    <DeleteModal
      title="areyousurecloseturn"
      subtitle="toCloseWriteTheNumber"
      v-model="showCloseTurnConfirmationModal"
      error-message="turnNumberIsNotCorrect"
      :item="selectedTurn"
      :label="$t('number')"
      :loading="loadings.removeTurn"
      name="number"
      @on-confirm="onCloseTurn()"
    />
    <custom-modal v-model="showSurveyModal" size="lg">
      <SurveyDetail
        v-if="selectedTurn"
        :turn-id="selectedTurn.id"
        @close="showSurveyModal = false"
      />
    </custom-modal>
    <custom-modal v-model="showDocumentsModal" size="md">
      <AppointmentDocuments
        v-if="selectedTurn"
        :appointment-id="selectedTurn.appointmentNumber"
        :no-action="selectedTurn.attended"
        :turno-id="selectedTurn.id"
        @close="showDocumentsModal = false"
      />
    </custom-modal>
    <custom-modal v-model="showEditTurnModal" :title="$t('editTurn')">
      <TurnForm
        v-if="selectedTurn"
        :attended="selectedTurn.attended"
        :turn-id="selectedTurn.id"
        :preferential-turn="preferentialTurn"
        @close="closeEditTurnModal()"
      />
    </custom-modal>
    <custom-modal
      v-model="showIndividualCallModal"
      :no-close-on-back-drop="true"
      size="md"
      :title="$t('callTurn')"
    >
      <validation-observer ref="individualCallObserver">
        <div class="row mb-3">
          <div class="col-12 text-center">
            {{ $t('advanceToStation') }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <filter-select
              v-model="selectedStationId"
              :allow-select-all="false"
              fieldtext="name"
              fieldvalue="id"
              label=""
              :options="stations"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-5">
            <base-filled-button
              class="btn-cancel"
              :on-click="() => (showIndividualCallModal = false)"
              :text="$t('cancel')"
            />
          </div>
          <div class="col-5">
            <base-filled-button
              :on-click="callIndividualTurn"
              :text="$t('confirm')"
            />
          </div>
        </div>
      </validation-observer>
    </custom-modal>

    <custom-modal
      id="appointment-stamps-modal"
      ref="appointment-stamps-ref"
      size="lg"
    >
      <appointment-stamps
        v-if="appointmentStampsInfo"
        :appointment-id="appointmentStampsInfo.clientAppointmentId"
        :stamps-info="appointmentStampsInfo"
        @close="$refs['appointment-stamps-ref'].hide()"
      />
    </custom-modal>
  </div>
</template>

<script>
import digitalCollectionMixin from '@/mixins/appointment/digitalCollectionMixin';
import sessionState from '@/mixins/sessionState';

import BaseInput from '@/components/BaseInput';
import { getWaitingTime as _getWaitingTime } from '@/services/LocationService';
import {
  initCalls as _initCalls,
} from '@/services/WaitingRoomService';
import CustomModal from '@/components/basics/modal/CustomModal';
import {
  removeTurn as _removeTurn,
  closeTurn as _closeTurn,
  isReactivateValid as _isReactivateValid,
  reactivateTurn as _reactivateTurn,
  callIndividualTurn as _callIndividualTurn,
  getTurns as _getTurns,
  requestTurnDocuments as _requestTurnDocuments,
} from '@/services/TurnService';
import { modulesName } from '@/store';
import { mapGetters, mapState, mapActions } from 'vuex';
import SearchResult from './SearchResult.vue';
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import DeleteModal from '@/components/DeleteModal';
import { print as _print } from '@/services/PrintService';
import TurnForm from './TurnForm';
import AppointmentDocuments from '@/components/AppointmentDocuments.vue';
import SurveyDetail from '@/components/SurveyDetail.vue';
import AppointmentStamps from '@/views/appointments/components/AppointmentStamps.vue';

import AudioCalling from '@/assets/sounds/TheCallingSoft.mp3';

export default {
  name: 'ManagerList',
  components: {
    BaseInput,
    SearchResult,
    CustomPagination,
    CustomNotFound,
    DeleteModal,
    TurnForm,
    CustomModal,
    SurveyDetail,
    AppointmentDocuments,
    AppointmentStamps,
  },

  data() {
    return {
      loadings: {
        general: false,
        removeTurn: false,
      },
      collections: {
        serviceQueues: [],
        attenders: [],
      },
      filters: {
        locationConfigurationId: null,
        turnNumber: null,
        clientName: null,
        appointmentNumber: null,
        status: null,
        attenderId: null,
        serviceQueueIds: [],
        pageSize: 10,
        pageIndex: 1,
      },
      noResultsFound: false,
      showEditTurnModal: false,
      showIndividualCallModal: false,
      showCloseTurnConfirmationModal: false,
      result: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      specialFieldsInput: null,
      searchInterval: null,
      autoSearchInterval: null,
      showRemoveTurnModal: false,
      showSurveyModal: false,
      showDocumentsModal: false,
      selectedTurn: null,
      individualCallTurn: null,
      selectedStationId: null,
      waitingTime: 0,
      actionUnsubscribe: null,
      breakAuthorizationEnabled: false,
      audio: null,
      volume: 1,
      indexToExpand: null,
      appointmentStampsInfo: null,
    };
  },
  mixins: [sessionState, digitalCollectionMixin],
  async mounted() {
    this.loadings.general = true;
    const turnState = this.$store.state[modulesName.turnManagerModuleName];

    this.collections.serviceQueues = turnState.serviceQueues;

    if (this.defaultServiceQueue && this.defaultServiceQueue.id > 0) {
      this.filters.serviceQueueIds = this.defaultServiceQueue
        ? [this.defaultServiceQueue.id]
        : null;
    }

    this.collections.attenders = turnState.attenders;
    this.specialFieldsInput = turnState.configuration.specialFields;
    this.filters.locationConfigurationId =
      turnState.turnListSearchFilters.locationConfigurationId;
    this.breakAuthorizationEnabled =
      turnState.configuration.breakAuthorizationEnabled;

    this.actionUnsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === `${modulesName.turnManagerModuleName}/searchTurns`)
        this.getTurns();
    });

    this.audio = new Audio(AudioCalling);

    const savedFilters = this.loadSessionData('managerListFilters');

    if(savedFilters && savedFilters.locationConfigurationId == this.filters.locationConfigurationId) {
      this.filters = savedFilters;
    }

    await this.loadWaitingTime();
    await this.search();
  },
  computed: {
    ...mapGetters('$_user', ['currentActiveLocation']),
    ...mapState(modulesName.turnManagerModuleName, [
      'defaultStation',
      'defaultServiceQueue',
      'turnListSearchFilters',
      'stations',
      'stationSelectionFixed',
      'preferentialTurn',
      'activeTurn',
      'activeWaitingRoom',
      'canUploadDocuments',
      'configuration'
    ]),
    chipSelected() {
      return (status) => this.filters.status === status;
    },
    status() {
      const value = [
        { value: 1, text: this.$t('statusCalled') },
        { value: 2, text: this.$t('statusUncalled') },
        { value: 3, text: this.$t('statusCalledNotPresent') },
        ...(() => {
          if (this.isStampsHandlingEnabled) {
            return [{ value: 4, text: this.$t('statusUncalledWithStamp') }];
          }
          return [];
        })(),
        { value: 5, text: this.$t('statusAttended') },
      ];
      if (this.preferentialTurn)
        value.push({ value: 6, text: this.$t('preferentialTurn') });
      if (this.canUploadDocuments)
        value.push({ value: 7, text: this.$t('turnWithDocuments') });
      return value;
    },
    isStampsHandlingEnabled() {
      return  this.configuration.stampsHandlingEnabled
    },
    waitingRoomEnabled() {
      return this.configuration.communicationModuleEnabled;
    },
  },
  watch: {
    'result.totalItemCount'(newValue, oldValue) {
      if (newValue > oldValue) this.audio.play();
    },
    activeTurn() {
      this.search(true);
    },
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, [
      'setActiveTurn',
      'setTurnSearchFilters',
      'setSelectedTurnManagerStation',
      'refreshMetrics',
      'checkUserBreak',
      'setActiveWaitingRoom',
    ]),
    ...mapActions(modulesName.userModuleName, [
      'setUserActiveWaitingRoom',
    ]),
    async loadWaitingTime() {
      await _getWaitingTime(
        this.currentActiveLocation.locationConfigurationId,
      ).then((response) => (this.waitingTime = response.data | 0));
    },
    openEditTurnModal(turn) {
      this.selectedTurn = turn;
      this.showEditTurnModal = true;
    },
    closeEditTurnModal() {
      this.showEditTurnModal = false;
      this.getTurns();
    },
    showTurnStationSelect(turn) {
      this.individualCallTurn = turn;

      if (!this.stationSelectionFixed) {
        this.showIndividualCallModal = true;
      } else {
        this.callIndividualTurn();
      }
    },
    showCloseTurnConfirmation(turn) {
      this.selectedTurn = turn;

      this.showCloseTurnConfirmationModal = true;
    },
    async onRequestDocuments(turn) {
      this.loadings.general = true;

      let payload = {
        locationConfigurationId: parseInt(
          this.currentActiveLocation.locationConfigurationId,
        ),
        turnId: turn.id,
      };

      await _requestTurnDocuments(payload)
        .then(() => {
          this.ShowSuccessSaveToast();
        })
        .catch((err) => {
          this.ShowErrorToast(err.response.data.message);
        })
        .finally(() => (this.loadings.general = false));
    },

    async callIndividualTurn() {
      if (this.$refs.individualCallObserver) {
        let isValid = await this.$refs.individualCallObserver.validate();
        if (!isValid) return;
      }

      this.showIndividualCallModal = false;
      this.loadings.general = true;

      let payload = {
        locationConfigurationId: parseInt(
          this.currentActiveLocation.locationConfigurationId,
        ),
        stationId: this.selectedStationId ?? (this.defaultStation ? this.defaultStation.id : null),
        serviceQueueId:
          this.individualCallTurn.serviceQueueId ?? this.defaultServiceQueue.id,
        turnId: this.individualCallTurn.id,
        waitingRoomId: this.activeWaitingRoom?.waitingRoomId,
        addCommunication:
          this.waitingRoomEnabled &&
          !this.configuration.representativeCompanionType,
      };

      await _callIndividualTurn(payload)
        .then(({data}) => {
          this.setActiveTurn(data.turn);

          if (data.waitingRoom) {
            this.setActiveWaitingRoom(data.waitingRoom);

            var userActiveWaitingRoom = {
              waitingRoomId: data.waitingRoom.waitingRoomId,
              locationConfigurationId:
                data.waitingRoom.locationConfigurationId,
            };

            this.setUserActiveWaitingRoom(userActiveWaitingRoom);

            if (this.waitingRoomEnabled && this.activeTurn.isCall) {
              this.initCalls();
            }
          }

          this.validateAppointmentStamps(data.turn.clientAppointmentId);

          this.setSelectedTurnManagerStation(
            this.stations.find((x) => x.id == data.turn.stationId),
          );

          this.refreshMetrics(
          Number(this.currentActiveLocation.locationConfigurationId),
        );
          this.getTurns(1);
          window.scrollTo(0, top);
        })
        .catch((err) => {
          this.ShowErrorToast(err.response.data.message);
          this.getTurns();
        });
    },
    async initCalls() {
      this.loading = true;
      await _initCalls(this.activeWaitingRoom?.waitingRoomId).finally(() => {
        this.loading = false;
      });
    },
    async getTurns(pageIndex = null, pageSize = null) {
      if (
        this.filters.appointmentNumber &&
        !Number(this.filters.appointmentNumber)
      )
        return this.ShowErrorToast(this.$t('invalidAppointmentNumber'));

      this.loadings.general = true;

      this.filters.pageSize = pageSize || this.filters.pageSize;
      this.filters.pageIndex = pageIndex || this.filters.pageIndex;

      this.saveSessionData('managerListFilters', {
        ...this.filters,
      });

      this.setTurnSearchFilters(this.filters);

      await _getTurns({
        ...this.turnListSearchFilters,
        appointmentNumber: this.turnListSearchFilters.appointmentNumber
          ? Number(this.turnListSearchFilters.appointmentNumber)
          : null,
      })
        .then((response) => {
          this.result = response.data;
          this.noResultsFound = !this.result.data.length;
        })
        .finally(() => (this.loadings.general = false));
    },
    async search(autoSearch = false) {
      if (!autoSearch) {
        clearTimeout(this.autoSearchInterval);
        await this.getTurns(1);
      }
      this.autoSearchInterval = setTimeout(async () => {
        await this.getTurns();
        this.search(true);
      }, 120000);
    },
    searchByChip(status) {
      this.filters.status = status;
      this.search();
    },
    removeTurn(turn) {
      this.showRemoveTurnModal = true;
      this.selectedTurn = turn;
    },
    async onRemoveTurn() {
      this.loadings.removeTurn = true;

      if (await this.checkCurrentUserBreak(true)) return;

      await _removeTurn(this.selectedTurn.id)
        .then(() => {
          this.refreshMetrics(
            Number(this.currentActiveLocation.locationConfigurationId),
          );
          this.ShowSuccessSaveToast();
          this.showRemoveTurnModal = false;
          this.search();
        })
        .finally(() => (this.loadings.removeTurn = false));
    },
    async onCloseTurn() {
      this.loadings.removeTurn = true;

      if (await this.checkCurrentUserBreak(true)) return;

      await _closeTurn(this.selectedTurn.id)
        .then(() => {
          this.refreshMetrics(
            Number(this.currentActiveLocation.locationConfigurationId),
          );
          this.ShowSuccessSaveToast();
          this.showCloseTurnConfirmationModal = false;
          this.search();
        })
        .finally(() => (this.loadings.removeTurn = false));
    },
    openSurveyModal(turn) {
      this.selectedTurn = turn;
      this.showSurveyModal = true;
    },
    openDocumentsModal(turn) {
      this.selectedTurn = turn;
      this.showDocumentsModal = true;
    },
    async print(turnId) {
      this.loadings.general = true;

      if (await this.checkCurrentUserBreak(true)) return;

      await _print(turnId, this.currentActiveLocation.locationConfigurationId);
      this.loadings.general = false;
    },
    async removeAttended(turn) {
      this.loadings.general = true;

      if (await this.checkCurrentUserBreak(true))
        return (this.loadings.general = false);
      const { data: isReactivatedValid } = await _isReactivateValid(
        turn.number,
        Number(this.currentActiveLocation.locationConfigurationId),
      );

      if (!isReactivatedValid) {
        this.ShowErrorToast(this.$t('cantreactivateturn'));
        return (this.loadings.general = false);
      }

      await _reactivateTurn(
        Number(this.currentActiveLocation.locationConfigurationId),
        turn.id,
      )
        .then(() => {
          this.ShowSuccessToast(
            this.$t('operationCompleted'),
            this.$t('turnReactivated'),
          );
          this.refreshMetrics(
            Number(this.currentActiveLocation.locationConfigurationId),
          );
          this.search();
        })
        .catch((err) => this.ShowErrorToast(err.response.data.message))
        .finally(() => (this.loadings.general = false));
    },
    async checkCurrentUserBreak(turnFinised) {
      await this.checkUserBreak({
        breakAuthorizationEnabled: this.breakAuthorizationEnabled,
        turnFinished: turnFinised,
      });
    },
    async validateAppointmentStamps(appointmentId) {
      this.appointmentStampsInfo = null;

      if (!appointmentId) return true;

      const stampsValidationResult = await this.validateAndGetStamps(
        appointmentId,
      );

      this.appointmentStampsInfo = stampsValidationResult.stampsData;

      let isValid = this.appointmentStampsInfo.isValid;

      if (!isValid) {
        this.$refs['appointment-stamps-ref'].show();
      }

      return isValid;
    },
  },
  beforeDestroy() {
    clearTimeout(this.autoSearchInterval);
    if (this.actionUnsubscribe) this.actionUnsubscribe();
  },
};
</script>
<style lang="scss" scoped>
.chip {
  padding: 10px;
  margin: 5px;
  border: 1px solid #d0cccc;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  &.red {
    background-color: #fdf5f5;
    border: 1px solid #fad4d4;
    color: #4e5254 !important;
  }
  &.yellow {
    background-color: #fff7e2;
    border: 1px solid #f8e1a2;
  }
  &.grey {
    background-color: #f8f8f8;
    border: 1px solid #d0cccc;
  }
  &.lightgreen {
    background-color: #f2fff2;
    border: 1px solid #a4afa5;
  }
  &.purple {
    background-color: #e9daf6;
    border: 1px solid #a17fc6;
  }
  &.selected {
    border-width: 2px;
    font-weight: bold;
  }
  &.blue {
    background-color: #F2F7FF;
    border: 1px solid #92BBF5;
  }
}

.btn-cancel {
  background-color: $color-font-secondary;
}
.box-list {
  max-height: 650px;
  overflow-y: scroll;
}
</style>
