<template>
  <validation-observer
    ref="transfer-tracker"
    novalidate
  >
    <custom-modal
      id="transfer-tracker-modal"
      ref="transfer-tracker-modal"
      :no-close-on-back-drop="true"
      size="lg"
      :title="$t('notesResult') + ' ' + extrafield"
    >
      <div class="row">
        <base-datetime-picker
          before
          class="col-sm-6 col-md-3 col-lg-3"
          format="dd/MMM/yyyy"
          set-initial-value
          :title="$t('fromDate')"
          type="date"
          :value="selectedFilters.dateFrom"
          @change="OnStartDateChanged($event)"
        />
        <base-datetime-picker
          before
          class="col-sm-6 col-md-3 col-lg-3"
          format="dd/MMM/yyyy"
          :min-datetime="selectedFilters.dateFrom"
          set-initial-value
          :title="$t('dateTo')"
          type="date"
          :value="selectedFilters.dateTo"
          @change="OnEndtDateChanged($event)"
        />
        <div class="col-md-4">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <filter-select
              v-model="selectedFilters.locationConfigurationId"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('locationName')"
              :options="filters.locations"
              :placeholder="$t('select')"
            />
          </validation-provider>
        </div>

        <div class="col text-right mt-4">
          <button 
            class="btn btn-primary" 
            @click="getTurnTransactions(1)"
          >
            <i class="fa fa-search" /> {{ $t('search') }}
          </button>
        </div>
      </div>
      

      <separator
        class="mt-4 mb-3 pl-0 pr-0"
        content-class="separator-custom-content"
        :text="$t('transactionList')"
      />
      <div 
        v-if="transactions.data.length" 
        class="row"
      >
        <div
          v-for="(transaction, index) in transactions.data"
          :key="index"
          class="col-md-12 mt-3"
        >
          <search-result
            :value="transaction"
          />
        </div>
      </div>

      <custom-pagination
        v-if="transactions.count > 0"
        id="pgPager"
        :data="transactions"
        :page-size="pageSize"
        @pagination-go-page="getTurnTransactions($event)"
        @pagination-rows-per-page="getTurnTransactions(pageIndex, $event)"
      />
      <CustomNotFound 
        v-if="noResultsFound" 
        :text="$t('noResultsFound')" 
      />
      <loading
        :active="loading"
        color="#F37E00"
        :is-full-page="false"
        :z-index="50"
      />
    </custom-modal>
  </validation-observer>
</template>

<script>
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult';
import CustomPagination from '@/components/CustomPagination.vue';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import { mapGetters } from 'vuex';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { modulesName } from '@/store';
import {
  getTurnTransactions as _getTurnTransactions,
} from '@/services/TurnService';
import moment from 'moment';

export default {
  name: 'TransferTracker',
  props: {
    extrafield: {
      type: String,
      default: () => null,
    },
  },
  components: {
    Separator,
    SearchResult,
    CustomPagination,
    CustomNotFound,
    CustomModal,
  },
  data() {
    return {
      filters: {
        locations: [],
        companies: []
      },
      selectedFilters: {
        extrafield1: this.extrafield,
        locationConfigurationId: null,
        dateFrom: null,
        dateTo: null
      },
      transactions: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      noResultsFound: false,
      pageSize: 10,
      pageIndex: 1,
      user: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'roleId',
      'currentLocations',
      'companies',
    ]),
    haveCompany() {
      return this.companies && Object.keys(this.companies).length;
    }
  },
  watch: {
    'selectedFilters.companyId'(value) {
      this.filters.locations = [];
      if (!value) return;
      this.getAvailableLocalizations(value);
    },
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    async loadInitialFilters() {
      if (this.haveCompany)
        this.filters.locations = this.currentLocations.map((x) => ({
          name: x.name,
          id: +x.locationConfigurationId,
        }));
      else {
        await _getCompanies()
          .then((response) => {
            this.filters.companies = response.data || [];
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getTurnTransactions(pageIndex = null, pageSize = null) {
      if(this.selectedFilters.locationConfigurationId) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      var cantFilter = 0;
      if (this.selectedFilters.dateFrom) cantFilter++;
      if (this.selectedFilters.dateTo) cantFilter++;
      if (this.selectedFilters.locationConfigurationId) cantFilter++;
      
      if (cantFilter < 2) {
        this.ShowToast(
          'Error',
          `${this.$t('userCompleteParameterInformation')}`,
          'error',
        );
        return;
      }
      this.loading = true;
      await _getTurnTransactions({
        ...this.selectedFilters,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      })
        .then(({ data }) => {
          this.transactions = data;
          this.filtered = true;
          this.noResultsFound = !this.transactions.data.length;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => this.loading = false);
      } else {        
        this.ShowToast(
          'Error',
          `${this.$t('mustselectlocalization')}`,
          'error',
        );
        return;
      }
    },
    async show() {
      this.transactions.data = [];
      this.transactions.totalItemCount = 0;
      this.transactions.count=0;
      this.transactions.pageCount=0;
      this.transactions.currentPage=1;
      this.$refs['transfer-tracker-modal'].show();
    },
    async hide() {
      this.model = {
        breakId: null,
        comment: null,
      };

      this.$refs['transfer-tracker-modal'].hide();
    },
    async onCancel() {
      this.hide();
    },
    today() {
      return moment().format();
    },
    OnStartDateChanged(value) {
      if(value) {
      let time = this.selectedFilters.dateFrom;

      this.selectedFilters.dateFrom = this.setDateTime(value, time);
      } else {
        this.selectedFilters.dateFrom = null;
      }
    },
    OnEndtDateChanged(value) {
      if(value) {
        this.selectedFilters.dateTo = this.setDateTime(
          value,
          this.selectedFilters.dateTo,
        );
      } else {
        this.selectedFilters.dateTo = null;
      }
    },
    setDateTime(date, time) {
      let _date = this.$moment(date);
      let _time = this.$moment(time);
      return _date.hours(_time.hours()).minutes(_time.minutes()).format();
    },
  },
};
</script>
<style lang="scss" scoped>
  .box-list {
    max-height: 500px;
    overflow-y: scroll;
  }
</style>
