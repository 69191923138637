const WaitingRoomEntry = Object.freeze({
  AddedSupervisor: 1,
  AddedMonitor: 2,
  MonitorOut: 3,
  SupervisorOut: 4
});

export default WaitingRoomEntry;





