<template>
  <div class="main-content">
    <b-row>
      <b-col>
        <div class="caption text-left main-content-title">
          {{ $t('turnsList') }}
        </div>
        <hr
          class="hr-fluid"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col
        cols="12"
        lg="3"
        sm="4"
      >
        <b-form-group :label="$t('number')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="3"
        sm="4"
      >
        <b-form-group :label="$t('name')">
          <input
            class="form-control"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="2"
        sm="4"
      >
        <b-form-group :label="$t('status')">
          <select
            id="status"
            class="form-control"
            name="status"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="2"
        sm="4"
      >
        <b-form-group :label="$t('visitTo')">
          <select
            id="VisitTo"
            class="form-control"
            name="VisitTo"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="2"
        sm="4"
      >
        <b-form-group :label="$t('row')">
          <select
            id="Row"
            class="form-control"
            name="Row"
          >
            <option value="0">
              {{ $t('select') }}
            </option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="custom-table-container">
      <b-col cols="12">
        <div class="table-responsive">
          <div class="custom-thead-background" />
          <table
            class="custom-table table"
            width="100%"
          >
            <thead>
              <tr>
                <th class="text-underline">
                  {{ $t('number') }}
                </th>
                <th>{{ $t('customer') }}</th>
                <th class="text-underline">
                  {{ $t('row') }}
                </th>
                <th class="text-underline">
                  {{ $t('service') }}
                </th>
                <th class="text-underline">
                  {{ $t('visitTo') }}
                </th>
                <th>{{ $t('comment') }}</th>
                <th class="text-underline">
                  {{ $t('attended') }}
                </th>
                <th class="text-underline">
                  {{ $t('arrivalTime') }}
                </th>
                <th class="text-underline">
                  {{ $t('waitTimeInMinutes') }}
                </th>
                <th>{{ $t('appointment') }}</th>
                <th>{{ $t('actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="separator">
                <td colspan="11" />
              </tr>

              <tr>
                <td>301</td>
                <td>Miguel Cruz</td>
                <td>
                  <span class="primary-color">3001</span>
                </td>
                <td class="font-regular">
                  Transacciones Cortas - Regular
                </td>
                <td class="font-regular">
                  TC-Certificaciones
                </td>
                <td />
                <td class="font-regular">
                  NO
                </td>
                <td>05:05:2020 <span class="primary-color">08:30 AM</span></td>
                <td>
                  <button
                    class="waitTimeLabel waitTimeLabel-success"
                    type="button"
                  >
                    <span>4</span>
                  </button>
                </td>
                <td />
                <td>
                  <action-dropdown>
                    <button
                      class="btn turns-actions"
                      type="button"
                    >
                      <i class="fal fa-ellipsis-h" />
                    </button>
                  </action-dropdown>
                </td>
              </tr>
              <tr class="separator">
                <td colspan="11" />
              </tr>
              <tr>
                <td>301</td>
                <td>Miguel Cruz</td>
                <td>
                  <span class="primary-color">3001</span>
                </td>
                <td class="font-regular">
                  Transacciones Cortas - Regular
                </td>
                <td class="font-regular">
                  TC-Certificaciones
                </td>
                <td />
                <td class="font-regular">
                  NO
                </td>
                <td>05:05:2020 <span class="primary-color">08:30 AM</span></td>
                <td>
                  <button
                    class="waitTimeLabel waitTimeLabel-danger"
                    type="button"
                  >
                    <span>25</span>
                  </button>
                </td>
                <td />
                <td>
                  <action-dropdown>
                    <button
                      class="btn turns-actions"
                      type="button"
                    >
                      <i class="fal fa-ellipsis-h" />
                    </button>
                  </action-dropdown>
                </td>
              </tr>
              <tr class="separator">
                <td colspan="11" />
              </tr>
              <tr>
                <td>301</td>
                <td>Miguel Cruz</td>
                <td class="">
                  <span class="primary-color font-regular">3001</span>
                </td>
                <td class="font-regular">
                  Transacciones Cortas - Regular
                </td>
                <td class="font-regular">
                  TC-Certificaciones
                </td>
                <td />
                <td class="font-regular">
                  NO
                </td>
                <td>05:05:2020 <span class="primary-color">08:30 AM</span></td>
                <td>
                  <button
                    class="waitTimeLabel waitTimeLabel-success"
                    type="button"
                  >
                    <span>4</span>
                  </button>
                </td>
                <td />
                <td>
                  <action-dropdown>
                    <button
                      class="btn turns-actions"
                      type="button"
                    >
                      <i class="fal fa-ellipsis-h" />
                    </button>
                  </action-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <pagination />
  </div>
</template>

<script>
import ActionDropdown from '@/components/basics/forms/BaseActionDropdown';
import Pagination from '@/components/Pagination';
export default {
  components: { ActionDropdown, Pagination },
  data () {
    return {
      showLoader: false,
      isCustomerMaintenance: false,
      isListMaintenance: true,
      isStartNewList: false,
      isContinueList: false
    };
  }
};
</script>
<style scoped lang="scss">

.table-responsive{
  overflow: visible;
}
</style>
