<template>
  <div class="ViewTurns">
    <TurnsAdministration />
  </div>
</template>

<script>
import TurnsAdministration from '@/components/TurnsAdministration.vue';

export default {
  title: 'Manejador de turnos',
  name: 'ViewTurns',
  components: {
    TurnsAdministration
  }
};
</script>
