<template>
  <div class="row">
    <loading :active="loading" color="#F37E00" :is-full-page="false" />
    <div class="col-md-12">
      <validation-observer ref="clientFormRef" novalidate>
        <div class="row mt-3">
          <div class="col-md-6">
            <validation-provider v-slot="{ errors }" rules="max:50">
              <base-input
                v-model="client.name"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('name')"
              />
            </validation-provider>
          </div>
          <div class="col-md-6">
            <validation-provider v-slot="{ errors }" rules="max:50">
              <base-input
                v-model="client.lastName"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('simpleLastName')"
              />
            </validation-provider>
          </div>
          <div class="col-md-12">
            <validation-provider v-slot="{ errors }" rules="email|max:256">
              <base-input
                v-model="client.email"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('email')"
              />
            </validation-provider>
          </div>
          <div class="col-md-12">
            <validation-provider v-slot="{ errors }" rules="max:300">
              <base-input
                v-model="client.appointmentId"
                :disabled="true"
                :error="errors[0]"
                :label="$t('appointmentNumber')"
              />
              <hr />
            </validation-provider>
          </div>
          <div class="col-md-6">
            <validation-provider v-slot="{ errors }" rules="required">
              <i
                class="fa-thumbtack pin"
                :class="{ fas: !serviceQueueFixed, fad: serviceQueueFixed }"
                @click="serviceQueueFixed = !serviceQueueFixed"
              />
              <filter-select
                v-model="client.queues"
                :allow-select-all="false"
                :disabled="collections.serviceQueues.length === 1"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('row')"
                :options="collections.serviceQueues"
              />
            </validation-provider>
          </div>
          <div class="col-md-6" v-if="hasConfiguredServices">
            <validation-provider v-slot="{ errors }" rules="required">
              <i
                class="fa-thumbtack pin"
                :class="{ fas: !serviceFixed, fad: serviceFixed }"
                @click="serviceFixed = !serviceFixed"
              />
              <filter-select
                :value="
                  individualServiceCounterEnabled
                    ? client.serviceId
                    : client.serviceIds
                "
                @input="
                  client[
                    individualServiceCounterEnabled ? 'serviceId' : 'serviceIds'
                  ] = $event
                "
                :disabled="
                  !client.queues.length || collections.services.length === 1
                "
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :allow-select-all="false"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('service')"
                :options="collections.services"
                :is-multiple="!individualServiceCounterEnabled"
              />
            </validation-provider>
          </div>

          <div class="col-md-12">
            <validation-provider v-slot="{ errors }" rules="max:1024">
              <base-input
                v-model="client.comment"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('comment')"
                :textarea="true"
                :max-length="1024"
              />
            </validation-provider>
            <hr />
          </div>
          <div class="col-md-6">
            <filter-select
              v-model="client.attenderId"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('visitTo')"
              :options="collections.attenders"
            />
          </div>
          <div
            v-if="specialFieldsInput && specialFieldsInput.specialField1Enabled"
            class="col-md-6"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: specialFieldsInput.specialField1Required,
                max: 250,
              }"
            >
              <base-input
                v-model="client.specialField1"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :label="
                  specialFieldsInput.specialField1Name ||
                  `${$t('specialField')} 1`
                "
                :mask="specialFieldsInput.specialField1Mask"
                :maskType="MaskType.Custom"
                @input="getExtraField2($event)"
              />
            </validation-provider>
          </div>
          <div
            v-if="specialFieldsInput && specialFieldsInput.specialField2Enabled"
            class="col-md-6"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: specialFieldsInput.specialField2Required,
                max: 250,
              }"
            >
              <base-input
                v-model="client.specialField2"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :label="
                  specialFieldsInput.specialField2Name ||
                  `${$t('specialField')} 2`
                "
                :mask="specialFieldsInput.specialField2Mask"
                :maskType="MaskType.Custom"
              />
            </validation-provider>
          </div>
          <div
            v-if="specialFieldsInput && specialFieldsInput.specialField3Enabled"
            class="col-md-6"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: specialFieldsInput.specialField3Required }"
            >
              <filter-select
                v-model="client.specialField3"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                fieldtext="name"
                fieldvalue="id"
                :label="
                  specialFieldsInput.specialField3Name ||
                  `${$t('specialField')} 3`
                "
                :options="collections.specialField3Options"
              />
            </validation-provider>
          </div>
          <div
            v-if="specialFieldsInput && specialFieldsInput.specialField4Enabled"
            class="col-md-6"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: specialFieldsInput.specialField4Required,
                max: 250,
              }"
            >
              <base-input
                v-model="client.specialField4"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :label="
                  specialFieldsInput.specialField4Name ||
                  `${$t('specialField')} 4`
                "
                :mask="specialFieldsInput.specialField4Mask"
                :maskType="MaskType.Custom"
              />
            </validation-provider>
          </div>
          <div
            v-if="specialFieldsInput && specialFieldsInput.specialField5Enabled"
            class="col-md-6"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: specialFieldsInput.specialField5Required,
                max: 250,
              }"
            >
              <base-input
                v-model="client.specialField5"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :label="
                  specialFieldsInput.specialField5Name ||
                  `${$t('specialField')} 5`
                "
                :mask="specialFieldsInput.specialField5Mask"
                :maskType="MaskType.Custom"
              />
            </validation-provider>
          </div>
          <div
            v-if="specialFieldsInput && specialFieldsInput.specialField6Enabled"
            class="col-md-6"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: specialFieldsInput.specialField6Required,
                max: 250,
              }"
            >
              <base-input
                v-model="client.specialField6"
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                :label="
                  specialFieldsInput.specialField6Name ||
                  `${$t('specialField')} 6`
                "
                :mask="specialFieldsInput.specialField6Mask"
                :maskType="MaskType.Custom"
              />
            </validation-provider>
          </div>
          <div
            v-if="specialFieldsInput && specialFieldsInput.specialField7Enabled"
            class="col-md-6"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: specialFieldsInput.specialField7Required }"
            >
              <base-datetime-picker
                :error="errors[0]"
                :error-msg="$t('fieldRequired')"
                format="dd/MMM/yyyy"
                :title="
                  specialFieldsInput.specialField7Name ||
                  `${$t('specialField')} 7`
                "
                :value="client.specialField7"
                @change="client.specialField7 = $event"
              />
            </validation-provider>
          </div>
          <div class="col-md-6">
            <base-datetime-picker
              :title="$t('appointmentHour')"
              type="time"
              :value="client.cited"
              @change="client.cited = $event"
            />
          </div>
          <div class="col-md-6">
            <filter-select
              v-model="client.attendedByPhone"
              :clearable="false"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('attendByPhone')"
              :options="collections.confirmations"
              translate
            />
          </div>
          <div class="col-md-6">
            <filter-select
              v-model="client.isBlind"
              :clearable="false"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('isBlind')"
              :options="collections.confirmations"
              translate
            />
          </div>
          <div v-if="collections.languages.length" class="col-md-6">
            <filter-select
              v-model="client.languageToAttendId"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('languageToAttend')"
              :options="collections.languages"
            />
          </div>
          <div v-if="preferentialTurn" class="col-md-6">
            <filter-select
              v-model="client.isPreferential"
              :clearable="false"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('preferentialTurn')"
              :options="collections.confirmations"
              translate
            />
          </div>
          <div class="col-md-12">
            <hr />
          </div>
          <div class="col-md-6">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: !!client.carrierId || clientPhoneRequired }"
            >
              <base-input
                v-model="client.phoneNumber"
                :error="errors[0]"
                :error-msg="$t('phoneRequired')"
                :label="$t('phone')"
                mask-type="Phone"
              />
            </validation-provider>
          </div>
          <div class="col-md-6">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: !!client.phoneNumber || clientPhoneRequired }"
            >
              <filter-select
                v-model="client.carrierId"
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
                fieldtext="text"
                fieldvalue="value"
                :label="$t('provider')"
                :options="collections.carriers"
              />
            </validation-provider>
          </div>
          <div class="col-md-12 text-right">
            <base-filled-button
              class="mr-0"
              icon-class="far fa-save"
              :on-click="onSubmit"
              :text="$t('save')"
            />
          </div>
        </div>
      </validation-observer>
    </div>
    <custom-modal
      id="appointment-stamps-modal"
      ref="appointment-stamps-ref"
      size="lg"
    >
      <appointment-stamps
        :appointment-id="Number(client?.appointmentId)"
        :stamps-info="appointmentStampsInfo"
        @close="$refs['appointment-stamps-ref'].hide()"
      />
    </custom-modal>
  </div>
</template>

<script>
import digitalCollectionMixin from '@/mixins/appointment/digitalCollectionMixin';

import ConfirmationOptions from '@/constants/ConfirmationOptions';
import BaseDatetimePicker from '@/components/BaseDatetimePicker';
import BaseInput from '@/components/BaseInput.vue';
import { modulesName } from '@/store';
import { mapGetters, mapState } from 'vuex';
import localState from '@/mixins/localState';
import StorageKeys from '@/constants/StorageKeys';
import AppointmentStamps from '@/views/appointments/components/AppointmentStamps.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import { getLanguages as _getLanguages } from '@/services/CompanyService';
import { getExtraField2ByExtraField1 as _getExtraField2ByExtraField1 } from '@/services/LocationService';
import { getCompanyConfig } from '@/services/CompanyService';
import {
  validateServiceQueue as _validateServiceQueue,
  validateServiceType as _validateServiceType,
  getTypesConfiguredForServiceQueues as _getTypesConfiguredForServiceQueues,
} from '@/services/ServicesService';
import { createByManager as _createByManager } from '@/services/TurnService';
import { print as _print } from '@/services/PrintService';
import MaskType from '@/constants/MaskType';

export default {
  name: 'ManagerClient',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BaseInput,
    BaseDatetimePicker,
    AppointmentStamps,
    CustomModal,
  },
  mixins: [localState, digitalCollectionMixin],
  data() {
    return {
      loading: false,
      client: {
        name: null,
        lastName: null,
        email: null,
        queues: [],
        serviceId: null,
        serviceIds: [],
        comment: null,
        carrierId: null,
        phoneNumber: null,
        specialistId: null,
        appointmentId: null,
        cited: null,
        attendedByPhone: false,
        isBlind: false,
        specialField1: null,
        specialField2: null,
        specialField3: null,
        specialField4: null,
        specialField5: null,
        specialField6: null,
        specialField7: null,
        attenderId: null,
        isPreferential: false,
        specialistUserId: null,
        languageToAttendId: null,
      },
      specialFieldsInput: null,
      serviceQueueFixed: false,
      serviceFixed: false,
      individualServiceCounterEnabled: false,
      clientPhoneRequired: false,
      collections: {
        confirmations: ConfirmationOptions,
        specialField3Options: [],
        serviceQueues: [],
        attenders: [],
        services: [],
        carriers: [],
        languages: [],
      },
      appointmentStampsInfo: null,
      companyConfig: {
        fetchExtraField2ByExtraField1: false,
      },
    };
  },
  async mounted() {
    _getLanguages(this.fullProfile.companyId).then(
      ({ data }) => (this.collections.languages = data),
    );
    const turnState = this.$store.state[modulesName.turnManagerModuleName];

    this.collections.serviceQueues = turnState.serviceQueues;
    this.specialFieldsInput = turnState.configuration.specialFields;
    this.collections.specialField3Options = turnState.miscData;
    this.collections.carriers = turnState.carriers;
    this.collections.attenders = turnState.attenders;
    this.individualServiceCounterEnabled =
      turnState.configuration.individualServiceCounterEnabled;
    this.clientPhoneRequired = turnState.configuration.clientPhoneRequired;

    if (this.collections.serviceQueues.length == 1)
      this.client.queues = [turnState.serviceQueues[0].id];
    this.$refs.clientFormRef.reset();

    await Promise.all([
      getCompanyConfig(this.fullProfile.companyId)
        .then((resp) => {
          this.companyConfig = resp.data;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        }),
    ]);
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'currentActiveLocation',
      'fullProfile',
    ]),
    ...mapState(modulesName.turnManagerModuleName, ['preferentialTurn']),
    hasConfiguredServices() {
      return this.$store.state[modulesName.turnManagerModuleName].configuration
        .hasConfiguredServices;
    },
    MaskType() {
      return MaskType;
    },
  },
  watch: {
    value() {
      this.client.name = this.value.firstName;
      this.client.lastName = this.value.lastName;
      this.client.email = this.value.email;
      this.client.phoneNumber = this.value.phoneNumber;
      this.client.carrierId = this.value.carrierId;
      this.client.appointmentId = this.value.clientAppointmentId;
      this.client.cited = this.value.cited;
      if (this.value.queues && this.value.queues.length)
        this.client.queues = this.value.queues.map((x) => x.id);

      this.client.specialistId = this.value.specialistId;
      this.client.specialField1 = this.value.specialField1;
      this.client.specialField2 = this.value.specialField2;
      this.client.comment = this.value.comment;

      let selectVisitTo = false;
      if (
        this.collections.serviceQueues &&
        this.collections.serviceQueues.length > 0 &&
        this.client.queues &&
        this.client.queues.length > 0
      ) {
        this.client.queues.forEach((q) => {
          const queue = this.collections.serviceQueues.find((x) => x.id === q);
          if (queue && queue.autoSelectSpecialist == true) {
            selectVisitTo = true;
          }
        });
      }

      if (this.value.specialistUserId && selectVisitTo == true) {
        this.client.attenderId = this.value.specialistUserId
          .toString()
          .toLowerCase();
      }

      if (this.value.specialField3) {
        const specialField = this.collections.specialField3Options.find(
          (x) => x.name === this.value.specialField3,
        );
        if (specialField) this.client.specialField3 = specialField.id;
      }
    },
    'client.queues'(curr, prev) {
      this.client.serviceId = null;
      this.client.serviceIds = [];
      if (this.client.queues.length == 0) return;

      if (curr.length < prev.length) return this.loadServiceTypes();
      else {
        const newQueueId = curr.filter((x) => !prev.includes(x))[0];

        if (!newQueueId) return;
        this.loading = true;
        _validateServiceQueue(newQueueId)
          .then((response) => {
            if (response.data && response.data.length) {
              this.ShowToast('Error', response.data, 'error');
              this.client.queues = prev;
            } else this.loadServiceTypes();
          })
          .finally(() => (this.loading = false));
      }
    },
    'client.serviceId'(value) {
      if (!value) return;
      this.loading = true;
      _validateServiceType(value)
        .then((response) => {
          if (response.data && response.data.length) {
            this.ShowToast('Error', response.data, 'error');
            this.client.serviceId = null;
          }
        })
        .finally(() => (this.loading = false));
    },
    'client.serviceIds'(curr, prev) {
      if (this.client.serviceIds.length == 0 || curr.length < prev.length)
        return;

      const newServiceId = curr.filter((x) => !prev.includes(x))[0];

      if (!newServiceId) return;
      this.loading = true;
      _validateServiceType(newServiceId)
        .then((response) => {
          if (response.data && response.data.length) {
            this.ShowToast('Error', response.data, 'error');
            this.client.serviceIds = prev;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  methods: {
    async loadServiceTypes() {
      if (!this.hasConfiguredServices) return;

      this.loading = true;
      const payload = {
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        serviceQueueIds: this.client.queues,
      };
      _getTypesConfiguredForServiceQueues(payload)
        .then((response) => {
          this.collections.services = response.data;
          if (
            !this.collections.services.find(
              (x) => x.id == this.client.serviceId,
            )
          )
            this.client.serviceId = null;
          if (this.collections.services.length === 1) {
            if (this.individualServiceCounterEnabled)
              this.client.serviceId = this.collections.services[0].id;
            else this.client.serviceIds = [this.collections.services[0].id];
          }
        })
        .finally(() => (this.loading = false));
    },
    async onSubmit() {
      if (!(await this.validateAppointmentStamps(this.client.appointmentId)))
        return;

      if (!(await this.$refs.clientFormRef.validate())) return;

      this.loading = true;

      let payload = {
        ...this.client,
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        firstName: this.client.name,
        serviceQueueIds: this.client.queues,
        clientEmail: this.client.email?.trim(),
        clientCel: this.client.phoneNumber,
        specialField3: this.client.specialField3
          ? this.client.specialField3.toString()
          : null,
        specialField7: this.client.specialField7 || null,
        cited: this.client.cited || null,
        visitingId: this.client.attenderId,
        serviceTypeIds: !this.hasConfiguredServices
          ? []
          : this.individualServiceCounterEnabled
          ? [this.client.serviceId]
          : this.client.serviceIds,
        clientAppointmentId: Number(this.client.appointmentId) || null,
      };

      const queueStartNumbers = this.loadLocalData(StorageKeys.turnStartNumber);
      if (queueStartNumbers) {
        payload.queueStartNumbers = queueStartNumbers;
      }

      await _createByManager(payload)
        .then((response) => {
          this.ShowSuccessSaveToast();
          this.$store.dispatch(
            `${modulesName.turnManagerModuleName}/searchTurns`,
          );
          this.$store.dispatch(
            `${modulesName.turnManagerModuleName}/cleanTopFilters`,
          );
          this.$store.dispatch(
            `${modulesName.turnManagerModuleName}/refreshMetrics`,
            Number(this.currentActiveLocation.locationConfigurationId),
          );
          this.$refs.clientFormRef.reset();
          this.client.name = null;
          this.client.lastName = null;
          this.client.email = null;
          if (
            !this.serviceQueueFixed &&
            this.collections.serviceQueues.length > 1
          )
            this.client.queues = [];
          if (!this.serviceFixed && this.collections.services.length > 1) {
            this.client.serviceId = null;
            this.client.serviceIds = [];
          }
          this.client.comment = null;
          this.client.carrierId = null;
          this.client.phoneNumber = null;
          this.client.specialistId = null;
          this.client.appointmentId = null;
          this.client.cited = null;
          this.client.attendedByPhone = false;
          this.client.isBlind = false;
          this.client.isPreferential = false;
          this.client.specialField1 = null;
          this.client.specialField2 = null;
          this.client.specialField3 = null;
          this.client.specialField4 = null;
          this.client.specialField5 = null;
          this.client.specialField6 = null;
          this.client.specialField7 = null;
          this.client.attenderId = null;
          this.client.languageToAttendId = null;

          // Removing the used start number
          if (queueStartNumbers && payload.serviceQueueIds) {
            payload.serviceQueueIds.forEach((s) => {
              delete queueStartNumbers[s];
            });
            this.saveLocalData(StorageKeys.turnStartNumber, queueStartNumbers);
          }

          response.data.forEach(async (turn) => {
            if (turn.printTicket)
              await _print(turn.id, turn.locationConfigurationId);
          });
        })
        .catch((error) => {
          this.ShowAlert('Error', error.response.data.message, 'error');
        })
        .finally(() => (this.loading = false));
    },
    async validateAppointmentStamps(appointmentId) {
      this.appointmentStampsInfo = null;

      if (!appointmentId) return true;

      const stampsValidationResult = await this.validateAndGetStamps(
        appointmentId,
      );

      this.appointmentStampsInfo = stampsValidationResult.stampsData;

      let isValid = this.appointmentStampsInfo.isValid;

      if (!isValid) {
        this.$refs['appointment-stamps-ref'].show();
      }

      return isValid;
    },
    async getExtraField2(value) {
      if (
        this.companyConfig?.fetchExtraField2ByExtraField1 &&
        value !== undefined &&
        value !== null &&
        value !== ''
      ) {
        await _getExtraField2ByExtraField1(value)
          .then((resp) => {
            this.client.specialField2 = resp.data;
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.ShowToast('Error', error.response.data.message, 'error');
            } else {
              this.ShowToast('Error', error.message, 'error');
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.pin {
  position: absolute;
  z-index: 10;
  right: 25px;
  font-size: 20px;
  color: #ff8105;
  cursor: pointer;
  transform: rotate(90deg);
}
.fad.pin {
  transform: rotate(0);
}
</style>
