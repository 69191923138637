<template>
  <div class="row p2">
    <div class="col-md-12 px-4 mt-3 mx-1">
      <loading
        :active="loading"
        color="#F37E00"
        :is-full-page="false"
        :z-index="50"
      />
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <sub-header :title="$t('broadcastSettings')" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <base-switch
                v-model="broadcast.dayTurns"
                :label="$t('dayTurns')"
              />
              <base-switch
                v-model="broadcast.unattendedTurns"
                :label="$t('unattendedTurns')"
              />
            </div>
            <div class="col-md-6">
              <base-switch
                v-model="broadcast.sendEmail"
                :label="$t('sendEmail')"
              />
              <base-switch v-model="broadcast.sendSMS" :label="$t('sendSMS')" />
              <base-switch
                v-model="broadcast.sendPushNotification"
                :label="$t('sendPushNotification')"
              />
            </div>
            <div class="col-md-12">
              <validation-provider
                v-slot="{ errors }"
                :rules="`max:${broadcastMessageLimit}`"
              >
                <base-input
                  v-model="broadcast.message"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('message')"
                  :textarea="true"
                  :max-length="broadcastMessageLimit"
                />
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2 d-flex justify-content-md-end">
          <button
            class="btn btn-primary"
            :disabled="
              !broadcast.message ||
              (!broadcast.dayTurns && !broadcast.unattendedTurns)
            "
            @click="sendBroadcast()"
          >
            <i class="fa fa-shipment" /> {{ $t('sendMessage') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { modulesName } from '@/store';
import BaseInput from '@/components/BaseInput';
import BaseSwitch from '@/components/BaseSwitch';
import SubHeader from '@/components/SubHeader';
import SetupParameters from '@/constants/SetupParameter';
import { getParameterValue as _getParameterValue } from '@/services/SetupParameterService';
import { sendBroadcast as _sendBroadcast } from '@/services/TurnService';

export default {
  name: 'Broadcast',
  components: {
    BaseInput,
    BaseSwitch,
    SubHeader,
  },
  data() {
    return {
      loading: false,
      broadcastMessageLimit: 120,
      broadcast: {
        dayTurns: false,
        unattendedTurns: false,
        sendEmail: false,
        sendSMS: false,
        sendPushNotification: false,
        message: null,
      },
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
  },
  methods: {
    async sendBroadcast() {
      this.loading = true;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
        dayTurns: this.broadcast.dayTurns,
        unattendedTurns: this.broadcast.unattendedTurns,
        sendEmail: this.broadcast.sendEmail,
        sendSMS: this.broadcast.sendSMS,
        sendPushNotification: this.broadcast.sendPushNotification,
        message: this.broadcast.message,
      };

      await _sendBroadcast(payload)
        .then(async () => {
          this.broadcast.message = null;
          this.ShowSuccessToast(this.$t('messageSentSuccessfully'));
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    'broadcast.dayTurns': {
      immediate: true,
      handler(value) {
        this.broadcast.unattendedTurns = value
          ? !value
          : this.broadcast.unattendedTurns;
      },
    },
    'broadcast.unattendedTurns': {
      immediate: true,
      handler(value) {
        this.broadcast.dayTurns = value ? !value : this.broadcast.dayTurns;
      },
    },
    'broadcast.sendSMS': {
      immediate: true,
      handler(value) {
        this.broadcast.sendPushNotification = value
          ? !value
          : this.broadcast.sendPushNotification;
      },
    },
    'broadcast.sendPushNotification': {
      immediate: true,
      handler(value) {
        this.broadcast.sendSMS = value ? !value : this.broadcast.sendSMS;
      },
    },
  },
  async mounted() {
    var parameter = SetupParameters.broadcastMessageLimit;
    this.loading = true;

    await _getParameterValue(parameter)
      .then(async (resp) => {
        this.broadcastMessageLimit = Number(resp.data);
      })
      .catch((error) => {
        this.ShowErrorToast(error.response.data.message);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style lang="scss" scoped></style>
