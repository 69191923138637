<template>
  <div :id="id" class="custom-dropdown">
    <slot></slot>
    <!-- Este espacio se llenará con el contenido del componente -->
    <ul class="dropdown-options" v-show="showOptions">
      <!-- Aquí coloca las opciones del dropdown -->
      <slot name="options"></slot>
      <!-- Este espacio se llenará con las opciones del dropdown -->
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    showOptions: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
};
</script>

<style scoped>
.custom-dropdown {
  position: relative;
  display: flex;
  z-index: 1;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  background-color: #fff; /* Fondo blanco */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra ligera */
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55),
    opacity 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  color: #333; /* Color de texto */
}

.dropdown-options a {
  display: block;
  padding: 6px; /* Espaciado uniforme */
  text-decoration: none;
  color: #333; /* Color de texto */
  transition: background-color 0.3s ease;
}

.dropdown-options a:hover {
  background-color: #f0f0f0; /* Color de fondo en hover */
  color: #333; /* Color de texto en hover */
}

.custom-dropdown:hover .dropdown-options {
  display: block;
  opacity: 1;
  transform: scaleY(1);
}
</style>
