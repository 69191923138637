<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="card mt-2"
        style="border: white; display: block"
      >
        <button
          v-if="showListMaintenance"
          class="btn mr-3"
          :class="{
            'btn-primary': option === 'list',
            'btn-secondary': option !== 'list',
          }"
          @click="option = 'list'"
        >
          {{ $t('listMaintenance') }}
        </button>
        <button
          v-if="showClientMaintenance"
          class="btn"
          :class="{
            'btn-primary': option === 'client',
            'btn-secondary': option !== 'client',
          }"
          @click="option = 'client'"
        >
          {{ $t('customerMaintenance') }}
        </button>
        <div v-if="showListMaintenance && option === 'list'">
          <ManagerListEditor />
        </div>
        <div v-if="showClientMaintenance" v-show="option === 'client'">
          <ManagerSearch @profile="profile = $event" />
          <ManagerClient
            :value="profile"
            @submitted="$emit('submitted')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IdentityPermission from '@/constants/IdentityPermission';
import ManagerListEditor from './ManagerListEditor';
import ManagerClient from './ManagerClient';
import ManagerSearch from './ManagerSearch';

export default {
  name: 'TurnsManagerMaintenance',
  components: {
    ManagerListEditor,
    ManagerClient,
    ManagerSearch
  },
  props: {
    clientProfile: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      option: null,
      showSearchClient: false,
      profile: null,
    };
  },
  mounted() {
    this.option = this.showClientMaintenance ? 'client' : 'list';
  },
  computed: {
    showClientMaintenance() {
      return this.userHasPermissions(
        IdentityPermission.turns.access.client.manager,
      );
    },
    showListMaintenance() {
      return this.userHasPermissions(IdentityPermission.turns.access.record);
    },
  }
};
</script>

